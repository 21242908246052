// import { birthdayReminder } from "../../actions/PayrollActions";

export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_LOADING: "hrms/set_loading",
  DISPLAY_HOLIDAYS: "national-holidays-list/",
  LEAVE_APPLICATION: "hrms/LEAVE_APPLICATION",
  ATTENDANCE_MASTER: "hrms/attendance-master",
  REGULARIZATION: "hrms/REGULARIZATION",
  BIRTHDAY_REMINDER: "hrms/BIRTHDAY_REMINDER",
  EMPLOYEE_SALARY: "hrms/employeeSalary",
  GET_EMPLOYEE_SALARY: "hrms/getEmployeeSalry",
  GET_LEAVE_APPLICATION: 'hrms/leaveApplication',
  GET_PENDING_REGULARIZATION: 'hrms/pendingRegularization',
  LEAVE_APPLICATION_REVIEW: 'hrms/LeaveReview',
  GET_EMPLOYEE_DATA: "hrms/employye_data",
  CREATE_LEAVE_REQUESTS: "hrms/create_leave_requests",
  GET_LEAVE_REQUESTS: "hrms/get_leave_requests",
  UPDATE_REGULARIZE: "hrms/upadte_regularize",
  POST_UPDATE_IMAGE: "post-upload-image",
  GET_PROFILE_IMAGE: "get-profile-image",
  GET_LOADING_REGULARIZATION:"loader-pending-regularization",
  ADD_EMPLOYMENT_DETAILS:"hrms/add-employment-details",
  EDIT_EMPLOYMENT_DETAILS:"hrms/edit-employment-details",
  GET_EMPLOYMENT_DETAILS:"hrms/get-employment-details",
  DELETE_EMPLOYMENT_DETAIL:"hrms/delete-employment-detail",
  ADD_CERTIFICATE:"hrms/add-certificate",
  EDIT_CERTIFICATE:"hrms/edit-certificate",
  GET_CERTIFICATE:"hrms/get-certificate",
  DELETE_CERTIFICATE:"hrms/delete-certificate",
  GET_MEDICAL_INFO:"hrms/medical-details",
  ADD_MEDICAL_INFO:"hrms/add-medical-info",
  EDIT_MEDICAL_INFO:"hrms/edit-medical-info",
  ADD_EDUCATION:"hrms/add-education",
  EDIT_EDUCATION:"hrms/edit-education",
  GET_EDUCATION:"hrms/get-education",
  DELETE_EDUCATION:"hrms/delete-education",
  CREATE_LMS_COURSE:"hrms/create-course",
  GET_LMS_COURSES:"hrms/get-lms-courses",
  EDIT_LMS_COURSE: "hrms/edit-lms-courses",
  POST_CREATE_MODULE: "hrms/post-create-module",
  UPDATE_LMS_MODULE:"hrms/update-lms-module",
  GET_LMS_MODULES:"hrms/get-lms-modules",
  DELETE_LMS_MODULE:"hrms.delete-lms-module",
  BULK_MODULE_ACTIONS:"hrms/bulk-module-actions",
  BULK_COURSE_ACTIONS:"hrms/bulk-course-actions",
  GET_EMPLOYEE_FILTER_LIST:"hrms/get-employee-filter-list",
  UPDATE_REGULARIZATION_COUNT:"hrms/update-regularization-count",
  MANAGER_BULK_UPLOAD:"hrms/manager-bulk-upload",
  POST_CREATE_BATCH:"hrms/post-create-batch",
  GET_LMS_BATCHES:"hrms/get-batch",
  UPDATE_LMS_BATCH:"HRMS/update-batch",
  ABSENCE_NOTIFICATION:"HRMS/absence-notification",
  GET_NOTIFICATION_LOGS:"HRMS/get-notification-logs",
   GET_MODULE_LOGS:"ccadmin/get-module-logs",
  GET_COURSE_LOGS:"ccadmin/get-course-logs"
};
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING
  payload: Array<string | number>
}

interface DisplayHolidays {
  type: typeof ACTIONS.DISPLAY_HOLIDAYS;
  payload: Array<any>

}

interface LeaveApplication {
  type: typeof ACTIONS.LEAVE_APPLICATION
  payload: Array<any>;
}
interface Regularization {
  type: typeof ACTIONS.REGULARIZATION
  payload: Array<any>;
}

interface BirthdayReminder {
  type: typeof ACTIONS.BIRTHDAY_REMINDER
  payload: Array<any>;
}
interface AttendanceMaster {
  type: typeof ACTIONS.ATTENDANCE_MASTER
  payload: Array<any>;
}


interface EmployeeSalary {
  type: typeof ACTIONS.EMPLOYEE_SALARY
  payload: Array<any>;
}


interface GetEmployeeSalary {
  type: typeof ACTIONS.GET_EMPLOYEE_SALARY
  payload: Array<any>;
}
interface GetLeaveApplication {
  type: typeof ACTIONS.GET_LEAVE_APPLICATION
  payload: Array<any>;
}
interface GetPendingRegularization {
  type: typeof ACTIONS.GET_PENDING_REGULARIZATION
  payload: Array<any>;
}

interface GetLoadingRegularization {
  type: typeof ACTIONS.GET_LOADING_REGULARIZATION
  payload: boolean;
}




interface leaveApplicationReview {
  type: typeof ACTIONS.LEAVE_APPLICATION_REVIEW
  payload: Array<any>;
}

interface GetEmployeeData {
  type: typeof ACTIONS.GET_EMPLOYEE_DATA
  payload: Array<any>;
}

interface CreateLeaveRequests {
  type: typeof ACTIONS.CREATE_LEAVE_REQUESTS
  payload: Array<any>;
}

interface GetLeaveRequests {
  type: typeof ACTIONS.GET_LEAVE_REQUESTS
  payload: Array<any>;
}
interface updateReg {
  type: typeof ACTIONS.UPDATE_REGULARIZE
  payload: Array<any>;
}
interface PostUpdateImage {
  type: typeof ACTIONS.POST_UPDATE_IMAGE
  payload: Array<any>;
}

interface GetProfileImage {
  type: typeof ACTIONS.GET_PROFILE_IMAGE
  payload: Array<any>;
}
interface addEmploymentDetails {
  type: typeof ACTIONS.ADD_EMPLOYMENT_DETAILS;
  payload: Object;
}
interface editEmploymentDetails {
  type: typeof ACTIONS.EDIT_EMPLOYMENT_DETAILS;
  payload: Object;
}
interface getEmploymentDetails {
  type: typeof ACTIONS.GET_EMPLOYMENT_DETAILS;
  payload: Array<any>;
}
interface deleteEmploymentDetail {
  type: typeof ACTIONS.DELETE_EMPLOYMENT_DETAIL;
  payload: boolean;
}

interface addCertificate {
  type: typeof ACTIONS.ADD_CERTIFICATE;
  payload: Object;
}
interface editCertificate {
  type: typeof ACTIONS.EDIT_CERTIFICATE;
  payload: Object;
}
interface getCertificate {
  type: typeof ACTIONS.GET_CERTIFICATE;
  payload: Array<any>;
}
interface deleteCertificate {
  type: typeof ACTIONS.DELETE_CERTIFICATE;
  payload: boolean;
}
interface GetMedicalData {
  type: typeof ACTIONS.GET_MEDICAL_INFO;
  payload: Array<any>;
}
interface AddMedicalInfo {
  type: typeof ACTIONS.ADD_MEDICAL_INFO;
  payload: object;
}
interface EditMedicalInfo {
  type: typeof ACTIONS.EDIT_MEDICAL_INFO;
  payload: object;
}

interface AddEducation {
  type: typeof ACTIONS.ADD_EDUCATION;
  payload: Object;
}
interface EditEducation {
  type: typeof ACTIONS.EDIT_EDUCATION;
  payload: Object;
}
interface getEducationDetails {
  type: typeof ACTIONS.GET_EDUCATION;
  payload: Array<any>;
}
interface deleteEducation {
  type: typeof ACTIONS.DELETE_EDUCATION;
  payload: boolean;
}

interface CreateCourseLMS {
  type: typeof ACTIONS.CREATE_LMS_COURSE;
  payload: object;
}

interface GetLmsCourses {
  type: typeof ACTIONS.GET_LMS_COURSES;
  payload: object;
}

interface EditLMSCourse {
  type: typeof ACTIONS.EDIT_LMS_COURSE;
  payload: Array<any>;
}

interface PostCreateModule {
  type: typeof ACTIONS.POST_CREATE_MODULE;
  payload: Array<any>;
}


interface UpdateLmsModule {
  type: typeof ACTIONS.UPDATE_LMS_MODULE;
  payload: Array<any>;
}

interface GetLmsModules {
  type: typeof ACTIONS.GET_LMS_MODULES;
  payload: object;
}

interface DeleteLmsModule {
  type: typeof ACTIONS.DELETE_LMS_MODULE;
  payload: object;
}

interface BulkModuleActions {
  type: typeof ACTIONS.BULK_MODULE_ACTIONS;
  payload: object;
}

interface BulkCourseActions {
  type: typeof ACTIONS.BULK_COURSE_ACTIONS;
  payload: object;
}

interface getemployeFilterList {
  type: typeof ACTIONS.GET_EMPLOYEE_FILTER_LIST;
  payload: Array<any>;
}
interface updateRegularizationCount {
  type: typeof ACTIONS.UPDATE_REGULARIZATION_COUNT;
  payload: object;
}
interface managerBulkUpload{
  type:typeof ACTIONS.MANAGER_BULK_UPLOAD;
  payload: object;
}

interface PostCreateBatch {
  type:typeof ACTIONS.POST_CREATE_BATCH;
  payload: object;
}

interface GetLmsBatch {
  type:typeof ACTIONS.GET_LMS_BATCHES;
  payload: object;
}

interface UpdateLmsBatch {
  type:typeof ACTIONS.GET_LMS_BATCHES;
  payload: object;
}

interface absenceNotification{
  type:typeof ACTIONS.ABSENCE_NOTIFICATION;
  payload: object;
}

interface notificationLogs{
  type:typeof ACTIONS.GET_NOTIFICATION_LOGS;
  payload: object;
}

interface GetModuleLogs {
  type: typeof ACTIONS.GET_MODULE_LOGS;
  payload: object;
}

interface GetCourseLogs {
  type: typeof ACTIONS.GET_COURSE_LOGS;
  payload: object;
}

export type HRMSTypes =
  | ChangePassword
  | ChangePassword
  | SetLoadng
  | DisplayHolidays
  | LeaveApplication
  | Regularization
  | AttendanceMaster
  | BirthdayReminder
  | EmployeeSalary
  | GetEmployeeSalary
  | GetLeaveApplication
  | GetPendingRegularization
  | leaveApplicationReview
  | GetEmployeeData
  | CreateLeaveRequests
  | GetLeaveRequests
  | updateReg
  | PostUpdateImage
  | GetProfileImage
  | GetLoadingRegularization
  | addEmploymentDetails
  | editEmploymentDetails
  | getEmploymentDetails
  | deleteEmploymentDetail
  | addCertificate
  | getCertificate
  | editCertificate
  | deleteCertificate
  | GetMedicalData
  | AddMedicalInfo
  | EditMedicalInfo
  | AddEducation
  | getEducationDetails
  | EditEducation
  | deleteEducation
  | CreateCourseLMS 
  | GetLmsCourses
  | EditLMSCourse
  | PostCreateModule
  | UpdateLmsModule
  | GetLmsModules
  | DeleteLmsModule
  | BulkModuleActions
  | BulkCourseActions
  | getemployeFilterList
  | updateRegularizationCount
  | managerBulkUpload
  | PostCreateBatch
  | GetLmsBatch
  | UpdateLmsBatch
  | absenceNotification
  | notificationLogs
  | GetModuleLogs
  | GetCourseLogs;
