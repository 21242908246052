import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  withStyles,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  Paper,
  MenuItem,
} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Theme, createStyles } from "@material-ui/core/styles";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import moment from "moment";
import "./style.css";
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getEmployeeLeaveMastersList } from "../../../actions/loginActions";
import { leaveApplication } from "../../actions/EmployeeActions";
import {
  getEmployeeList,
  getLeaveComponents,
  getEmployeeById,
  getLeaveLedger,
  halfDayLeave,
} from "../../../actions/loginActions";
import { Option, Select } from "@mui/joy";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #b435a3 ",
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      opacity: "0.8",
      width: "1400px",
      height: "auto%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getEmployeeLeaveMastersList: any;
  employeeLeaveMaster: any;
  leaveApplication: any;
  getEmployeeList: any;
  employeesList: any;
  leave_application: any;
  getLeaveComponents: any;
  leaveComponents: any;
  getEmployeeById: any;
  employee_by_id: any;
  getLeaveLedger: any;
  leaveLedger: any;
  halfDayLeave: any;
}

const LeaveBalance: React.FC<Props> = ({
  getEmployeeLeaveMastersList,
  employeeLeaveMaster,
  leaveApplication,
  leave_application,
  getEmployeeList,
  employeesList,
  getLeaveComponents,
  leaveComponents,
  getEmployeeById,
  employee_by_id,
  getLeaveLedger,
  leaveLedger,
  halfDayLeave,
}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const history = useHistory();
  const [inDate, setInDate] = useState("");
  const [reason, setReason] = useState("");
  const [leave, setleave] = useState(false);
  const [viewlogs, setviewlogs] = useState(false);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leaveType, setLeaveType] = useState();
  const [firstHalf, setFirstHalf] = useState("first_half");
  const [secondHalf, setSecondHalf] = useState("first_half");
  const [medicalCertificate, setMedicalCertificate] = useState(null);
  const [employeeUUID, setEmployeeUUID] = useState<any>("");
  const [LeaveTypeId, setLeaveTypeId] = useState<any>("");
  const diffDays = dayjs(toDate).diff(dayjs(fromDate), "days");
  const [employeeUUid, setEmployeeUuid] = useState("");
  const [seletedcEmployeeUUid, setSeletedcEmployeeUUid] = useState("");
  const [selectedEmp, setSelectedEmp] = useState<any>(false);
  const [selectLeaveType, setSelectLeaveType] = useState<any>("");
  const [leaveAmount, setLeaveAmount] = useState<any>([]);
  const [isManager, setIsManager] = useState<any>(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (firstHalf === "first_half") {
      setToDate(fromDate);
    }
  }, [fromDate, firstHalf]);


  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data: any = {
      reason_for_leave: reason,
      from_date: fromDate,
      to_date: toDate,
      leave_from_session: firstHalf,
      leave_to_session: firstHalf,
      medical_certificate: medicalCertificate,
      employee: selectedEmp ? seletedcEmployeeUUid : employeeUUID,
      component: LeaveTypeId,
      // component_id: LeaveTypeId,
    };

    if (medicalCertificate == null) {
      delete data.medical_certificate;
    }
    if (fromDate > toDate) {
      alert("The From Date cannot be later than the To Date.");
      return;
    }
    else if (firstHalf === "first_half"
    ) {
      await halfDayLeave(data);
      setleave(false);
      setFromDate("");
      setToDate("");
      setReason("");
    }
    else {
      await leaveApplication(data);
      setleave(false);
      setFirstHalf("first_half");
      setSecondHalf("first_half");
      setFromDate("");
      setToDate("");
      setReason("");
    }
  };
  const handleMedicalCertificateChange = (e: any) => {
    setMedicalCertificate(e.target.files[0]);
  };

  const [employeeType, setEMployeeType] = useState<any>();
  useEffect(() => {
    if (employee_by_id && employee_by_id?.results && employee_by_id.count > 0) {
      setEmployeeUUID(employee_by_id && employee_by_id?.results[0]?.uuid);
      setIsManager(employee_by_id && employee_by_id?.results[0]?.is_manager);
      setEMployeeType(
        employee_by_id && employee_by_id?.results[0]?.employee_mode
      );
    }
  }, [employee_by_id]);

  useEffect(() => {
    getEmployeeList("my_team=true");
    getLeaveComponents();
    getEmployeeLeaveMastersList();
    getEmployeeById("");
  }, []);

  const handleResetFunction = () => {
    setLeaveAmount([]);
    history.push("/dashboard/employee/leave");
  };
  const handleFilterFunction = () => {
    getEmployeeLeaveMastersList(`employee=${employeeUUid}`);
  };

  useEffect(() => {
    const leaveArr: any = [];
    setLeaveAmount([]);

    if (employeeLeaveMaster && employeeLeaveMaster.length > 0) {
      employeeLeaveMaster.forEach((item: any) => {
        if (item?.component_details && item?.component_details?.id) {
          leaveArr.push({
            id: item?.component_details?.id,
            leave_amount: item?.amount,
          });
        }
      });
    }

    setLeaveAmount(leaveArr);
  }, [employeeLeaveMaster]);
  const [totalEarned, setTotalEarned] = useState(0);
  const [totalDeducted, setTotalDeducted] = useState(0);
  const [totalEarnedList, setTotalEarnedList] = useState([]);
  const [totalDeductedList, setTotalDeductedList] = useState([]);

  useEffect(() => {
    if (leaveLedger) {
      const totalEarned = leaveLedger
        .filter((leave: any) => leave.earn_or_deduct === "Earn" && leave.leave_component === selectLeaveType)
        .reduce((sum: any, leave: any) => sum + parseFloat(leave.amount), 0);

      const totalEarnedList = leaveLedger
        .filter((leave: any) => leave.earn_or_deduct === "Earn" && leave.leave_component === selectLeaveType)

      const totalDeducted = leaveLedger
        .filter((leave: any) => leave.earn_or_deduct === "Deducted" && leave.leave_component === selectLeaveType)
        .reduce((sum: any, leave: any) => sum + parseFloat(leave.amount), 0);

      const totalDeductedList = leaveLedger
        .filter((leave: any) => leave.earn_or_deduct === "Deducted" && leave.leave_component === selectLeaveType)

      setTotalEarned(totalEarned);
      setTotalDeducted(totalDeducted);
      setTotalDeductedList(totalDeductedList);
      setTotalEarnedList(totalEarnedList)
    }
  }, [leaveLedger]);

  const getJune21st = () => {
    const today = new Date();
    const year = today.getFullYear();
    return new Date(year, 5, 22).toISOString().split("T")[0];
  };
  const june21st = getJune21st();

  const handleFromDateChange = (e: any) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);
    if (toDate && selectedFromDate > toDate) {
      setToDate("");
    }
  };

  const handleToDateChange = (e: any) => {
    const selectedToDate = e.target.value;
    if (fromDate && selectedToDate < fromDate) {
      alert("End date cannot be before start date");
      return;
    }
    setToDate(selectedToDate);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h4
        style={{
          fontWeight: "bold",
          backgroundColor: "rgb(28, 78, 128)",
          color: "#fff",
          textAlign: "center",
          padding: "10px",
          borderRadius: "15px",
        }}
      >
        Leave Balance
      </h4>

      {isManager && (
        <Grid
          container
          spacing={3}
          direction="row"
          style={{ alignItems: "left", marginTop: "1.5rem" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeUuid(obj.uuid);
                  setSeletedcEmployeeUUid(obj.uuid)
                  setSelectedEmp(true)
                }
              }}
              id="checkboxes-tags-demo"
              options={employeesList?.results || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getEmployeeList(`my_team=true&name= ${newInputValue}`);
                }, 1000);
              }}
              getOptionLabel={(option: any) => {
                return option?.employee_id + " " + option.name;
              }}
              renderOption={(option: any) => {
                return (
                  <p>
                    <b style={{ color: "blue" }}>Employee Name:</b>
                    <strong>
                      {option?.name.toString()}
                      <br />
                    </strong>
                    <b style={{ color: "red" }}>Employee Id:</b>
                    {option?.employee_id?.toUpperCase()}
                  </p>
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Search By Code Or Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleFilterFunction}
              style={{
                backgroundColor: "#1976d2",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#1565c0";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#1976d2";
                e.currentTarget.style.boxShadow = "none";
              }}
              startIcon={<SearchIcon />}
            >
              Filter
            </Button>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<RotateLeftIcon />}
              style={{
                backgroundColor: "#9c27b0",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#7b1fa2";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#9c27b0";
                e.currentTarget.style.boxShadow = "none";
              }}
              onClick={() => handleResetFunction()}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "12px",
            marginTop: "12px",
            border: "1px solid #ccc",
            borderRadius: "6px",
            backgroundColor: "#e3f2fd",
            display: "inline-block",
            fontSize: "15px",
            color: "#333",
          }}
        >
          <span style={{ color: "#c62828" }}>
            📅 If your leave is less than 1 day{" "}
            <strong> you cannot apply </strong>
            for that type of leave.
          </span>
          <br />
        </div>
      </div>

      <div className="cardsAppointment">
        {leaveComponents?.length > 0 &&
          leaveComponents?.map((item: any) => {
            const leaveData = leaveAmount.find(
              (data: any) => data.id === item.id
            );
            const leaveDays = leaveData ? leaveData.leave_amount : "N/A";

            return (
              <Grid container>
                <Grid item sm={3}>
                  <h5 id={item?.id} style={{ color: "#ffffff" }}>
                    {item.description}
                  </h5>
                  <span style={{ color: "#ffffff" }} id={item?.id}>
                    {leaveDays} days
                  </span>
                </Grid>
                <Grid
                  sm={6}
                  style={{
                    color: "#ffffff",
                    margin: "10px 0",
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <span
                    onClick={() => (
                      setOpen(true),
                      getLeaveLedger(item.id),
                      setSelectLeaveType(item.description)
                    )}
                  >
                    <FormatListBulletedIcon /> Ledger
                  </span>
                </Grid>

                <div>
                  <Box sx={{ display: "flex" }}>
                    <Drawer
                      anchor="right"
                      open={open}
                      onClose={() => setOpen(false)}
                      size="lg"
                    >
                      <Box p={2} textAlign={"center"} role="presentation">
                        <Grid
                          container
                          spacing={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "80px",
                          }}
                        >
                          <ModalClose
                            onClick={() => setOpen(false)}
                            style={{
                              marginTop: "80px",
                              margin: "70px",
                              fontSize: "1.5rem",
                              width: "40px",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              borderRadius: "50%",
                              backgroundColor: "#f5f5f5",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              transition: "background-color 0.3s ease",
                            }}
                            onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "#e0e0e0")
                            }
                            onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "#f5f5f5")
                            }
                          />
                          <div style={{ display: "flex" }}>
                            <div style={{ textAlign: "center" }}>
                              <h4
                                className="border"
                                style={{
                                  fontSize: "1.25rem",
                                  fontWeight: "600",
                                  color: "#333",
                                  padding: "10px 20px",
                                  backgroundColor: "#f5f5f5",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "4px",
                                  fontFamily:
                                    "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                                }}
                              >
                                {selectLeaveType} Ledger
                              </h4>
                            </div>
                            <div></div>
                          </div>
                        </Grid>
                        <Grid>
                          <Grid>
                            <div style={{ display: "flex", margin: "30px" }}>
                              <TableContainer style={{ marginRight: "30px" }}>
                                <Table>
                                  <TableHead>
                                    <StyledTableCell>
                                      Total Earn
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {totalEarned}
                                    </StyledTableCell>
                                  </TableHead>
                                  <TableBody>
                                    {totalEarnedList?.length > 0 ? (
                                      totalEarnedList?.map(
                                        (leave: any, index: any) => {
                                          return (
                                            <StyledTableRow key={index}>
                                              <StyledTableCell>
                                                {leave.date}
                                              </StyledTableCell>
                                              <StyledTableCell>
                                                {leave.amount}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )
                                    ) : (
                                      <StyledTableRow>
                                        <StyledTableCell
                                          colSpan={6}
                                          align="center"
                                        >
                                          No Earn Leave
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        Total Deducted
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {totalDeducted}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {totalDeductedList?.length > 0 ? (
                                      totalDeductedList?.map(
                                        (leave: any, index: any) => {
                                          return (
                                            <StyledTableRow key={index}>
                                              <StyledTableCell>
                                                {leave.date}
                                              </StyledTableCell>
                                              <StyledTableCell>
                                                {leave.amount}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )
                                    ) : (
                                      <StyledTableRow>
                                        <StyledTableCell
                                          colSpan={6}
                                          align="center"
                                        >
                                          No Deducted Leave.
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </Grid>
                          <div style={{ display: "flex", margin: "30px" }}>
                            <TableContainer>
                              <Table stickyHeader aria-label="customized table">
                                <TableHead>
                                  <StyledTableRow>
                                    <StyledTableCell>
                                      {" "}
                                      Earn Or Deduct
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      Amount
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                      Leave Type
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      Current Balance
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      Date
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                  {leaveLedger && leaveLedger?.length > 0 ? (
                                    leaveLedger?.map(
                                      (leave: any, index: any) => {
                                        return (
                                          <StyledTableRow key={index}>
                                            <StyledTableCell>
                                              {leave.earn_or_deduct}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              {leave.amount}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              {leave.leave_component}
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                              {leave.current_balance}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              {leave.date !== null ? leave.date : leave?.created_at?.substring(0, 10)}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      }
                                    )
                                  ) : (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        colSpan={6}
                                        align="center"
                                      >
                                        No leave available.
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </Grid>
                      </Box>
                    </Drawer>
                  </Box>
                </div>
                <Grid sm={9}>
                  <Button
                    disabled={
                      (item.discription === "Earcned Leave" &&
                        employeeType === "probation") ||
                      leaveDays < 1 ||
                      leaveDays === "N/A"
                    }
                    style={{
                      backgroundColor:
                        leaveDays < 1 || leaveDays === "N/A" ? "gray" : "white",
                      color: "21336a",
                      padding: "2px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setleave(true);
                      setSelectLeaveType(item.description);
                      setLeaveTypeId(item.id);
                    }}
                  >
                    Apply Leave
                  </Button>
                </Grid>
              </Grid>
            );
          })}
      </div>

      <Drawer
        anchor="right"
        open={viewlogs}
        onClose={() => setviewlogs(false)}
        size={"md"}
      >
        <Box
          p={2}
          width={300}
          textAlign={"left"}
          role="presentation"
          sx={{
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <form className="view">
              <h2
                className="mainHeading"
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgb(103, 37, 103)",
                  marginTop: "30px",
                }}
              ></h2>

              <label>
                Select shift date
                <br />
                <input
                  className="tex"
                  type="date"
                  value={inDate}
                  onChange={(event) => setInDate(event.target.value)}
                />
              </label>
            </form>
          </Grid>
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        size="md"
        open={leave}
        onClose={() => setleave(false)}
      >
        <Box
          p={2}
          width={300}
          textAlign={"left"}
          role="presentation"
          sx={{
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={4}
            style={{ display: "flex", justifyContent: "center", padding: 20 }}
          >
            <form
              style={{
                marginTop: "1.5rem",
                width: "100%",
              }}
            >
              <h2
                className="mainHeading"
                style={{
                  width: "100%",
                  color: "#0e3f6c",
                  margin: "auto",
                  marginBottom: "1rem",
                }}
              >
                Apply Leave
              </h2>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold", marginBottom: "0px" }}>
                  Leave Type
                </label>
                <Autocomplete
                  // value={selectLeaveType}
                  onChange={(event, obj) => {
                    if (obj) {
                      setLeaveTypeId(obj.id);
                      setSelectLeaveType(obj.name);
                    }
                  }}
                  id="checkboxes-tags-demo"
                  className="my-3"
                  options={leaveComponents || []}
                  inputValue={selectLeaveType}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => { }, 1000);
                  }}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option?.name.toString()}</p>;
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      // label="Leave Type"
                      variant="outlined"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div style={{ width: "100%" }}>
                <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                  Select Duration
                </label>
                <div style={{ width: "100%" }} >
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    size="medium"
                    value={firstHalf}
                    style={{ width: "100%" }}
                    onChange={(e: any) => setFirstHalf(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        height: 40,
                      },
                    }}
                  >
                    <MenuItem value="first_half">Half Day</MenuItem>
                    <MenuItem value="second_half">Full Day</MenuItem>
                  </TextField>
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label style={{ fontWeight: "bold" }}>Date*</label>
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  className="input"
                  name="start_date"
                  id="noTyping"
                  type="date"
                  label="Start Date"
                  variant="outlined"
                  onChange={handleFromDateChange}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {firstHalf === "second_half" && <TextField
                  className="input"
                  name="end_date"
                  id="noTyping"
                  type="date"
                  label="End Date"
                  variant="outlined"
                  value={toDate}
                  onChange={handleToDateChange}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />}
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label style={{ fontWeight: "bold" }}>Reason for Leave*</label>
              </div>
              <div>
                <TextField
                  className="input"
                  fullWidth
                  size="small"
                  variant="outlined"
                  multiline
                  required
                  rows={4}
                  placeholder="Reason for Leave"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>


              {/* <label className="my-2">
                Day
                <section style={{ display: "flex" }} >
                  <Select
                    defaultValue={firstHalf}
                    onChange={(e: any) => setFirstHalf(e.target.value)}
                    style={{
                      width: "100%",
                    }}
                    className="tex mx-2 px-2"
                  >
                    <Option value="first_half">Half Day</Option>
                    <Option value="second_half">Full Day</Option>
                  </Select>

                  <input
                    className="tex mx-2 px-2"
                    type="date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    min={june21st}
                    style={{
                      borderRadius: "0px 10px 10px 0px",
                      width: "50%",
                    }}
                  />
                </section>
              </label> */}

              {/* <label className="my-2">
                To*:
                <section style={{ display: "flex" }} className="my-2">
                  <select
                    className="tex"
                    value={secondHalf}
                    onChange={(e) => setSecondHalf(e.target.value)}
                    style={{
                      borderRadius: "10px 0px 0px 10px",
                      width: "50%",
                    }}
                  >
                    <option value="first_half">First Half</option>
                    <option value="second_half">Second Half</option>
                  </select>

                  <input
                    className="tex"
                    type="date"
                    value={toDate}
                    onChange={handleToDateChange}
                    min={fromDate || june21st}
                    style={{
                      borderRadius: "0px 10px 10px 0px",
                      width: "50%",
                    }}
                  />
                </section>
              </label> */}
              {/* <label className="my-3">
                Reason for Leave*:
                <textarea
                  className="tex"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </label> */}
              {leaveType === "Medical" && diffDays >= 3 && (
                <label>
                  Medical Certificate:
                  <br />
                  <input
                    className="medical"
                    type="file"
                    onChange={handleMedicalCertificateChange}
                  />
                </label>
              )}

              <Button
                style={{
                  backgroundColor:
                    fromDate === "" || (firstHalf === "second_half" && toDate === "") || reason === ""
                      ? "gray"
                      : "#1E3A8A",
                  color:
                    fromDate === "" || (firstHalf === "second_half" && toDate === "") || reason === ""
                      ? "black"
                      : "white",
                  borderRadius: "8px",
                  padding: "12px 24px",
                  border: "none",
                  cursor:
                    fromDate === "" || (firstHalf === "second_half" && toDate === "") || reason === ""
                      ? "not-allowed"
                      : "pointer",
                  transition:
                    "transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out",
                  boxShadow:
                    fromDate === "" || (firstHalf === "second_half" && toDate === "") || reason === ""
                      ? "none"
                      : "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                onClick={handleSubmit}
                disabled={fromDate === "" || reason === "" || (firstHalf === "second_half" && toDate === "")}
                onMouseEnter={(e) => {
                  if (!(fromDate === "" || (firstHalf === "second_half" && toDate === "") || reason === "")) {
                    e.currentTarget.style.transform = "scale(1.05)";
                    e.currentTarget.style.boxShadow =
                      "0px 6px 12px rgba(0, 0, 0, 0.3)";
                  }
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 8px rgba(0, 0, 0, 0.2)";
                }}
              >
                Apply
              </Button>
            </form>
          </Grid>
        </Box>
      </Drawer>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  employeeLeaveMaster: state.loginReducer.employeeLeaveMaster,
  leave_application: state.EmployeeReducer.leave_application,
  employeesList: state.loginReducer.employeesList,
  leaveComponents: state.loginReducer.leaveComponents,
  employee_by_id: state.loginReducer.employee_by_id,
  leaveLedger: state.loginReducer.leaveLedger,
});

export default connect(mapStateToProps, {
  getEmployeeLeaveMastersList,
  leaveApplication,
  getEmployeeList,
  getLeaveComponents,
  getEmployeeById,
  getLeaveLedger,
  halfDayLeave,
})(LeaveBalance);
