import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PhoneIcon from "@mui/icons-material/Phone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Drawer from "@mui/material/Drawer";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SendIcon from "@mui/icons-material/Send";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Divider } from "@material-ui/core";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// import SwipeableViews from 'react-swipeable-views';
import List from "@mui/material/List";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import ListItem from "@mui/material/ListItem";
import ModalClose from "@mui/joy/ModalClose";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import PolicyIcon from "@mui/icons-material/Policy";
import PaymentIcon from "@mui/icons-material/Payment";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import { getEmployeeList,  getReportingManager,
  getEmployeeDepartment, } from "../../../actions/loginActions";
import {
  getpendingregularization,
  leaveApplicationReview,
  getLeaveApplication,
  leaveApplication,
  updateRegularization,
  getLeaveRequests,
  createLeaveRequests,
} from "../../actions/EmployeeActions";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import "./request.css";
import { BorderRight } from "@material-ui/icons";
import TextField from "@mui/material/TextField";
import { generateUrlRegularze } from "../../../helper/generate";
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    item: {},
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  open: Boolean;
  setOpen: Function;
  getEmployeeList: any;
  employeesList: any;
  leaveApplication: any;
  leave_application: any;
  getLeaveApplication: any;
  requestCenterLeave: any;
  getpendingregularization: any;
  pendingreularization: any;
  leaveApplicationReview: any;
  leavereivew: any;
  updateRegularization: any;
  getLeaveRequests: any;
  leaveRequests: any;
  createLeaveRequests: any;
  getReportingManager:any,
  reporting_manager: any;
  getEmployeeDepartment:any,
  departmentNames: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  open,
  setOpen,
  getEmployeeList,
  employeesList,
  leaveApplication,
  leave_application,
  getLeaveApplication,
  requestCenterLeave,
  getpendingregularization,
  pendingreularization,
  leaveApplicationReview,
  leavereivew,
  updateRegularization,
  getLeaveRequests,
  leaveRequests,
  createLeaveRequests,
  getReportingManager,
  reporting_manager,
  getEmployeeDepartment,
  departmentNames,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [editModulesOpen, setEditModulesOpen] = useState<any>(false);
  const [attendanceOpen, setAttendanceOpen] = useState<any>(false);
  const [leaveOpen, setLeaveOpen] = useState<any>(false);
  const [resetEmployee, setEmployeeReset] = useState<any>(false);
  const [employeeUUid, setEmployeeUuid] = useState<any>("");
  const [isManager, setIsManager] = useState<any>(false);
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState("pending");
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [employeeUUID, setEmployeeUUID] = useState<any>("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [resetManager, setResetManager] = useState<any>(false);
  const [employeeDept, setEmployeeDept] = useState<any>("");
  const [departmentHead, setDepartmentHead] = useState<any>("");
  const [resetDept, setDeptReset] = useState<any>(false);
  const [fromDate, setFromDate] = useState<any>("");
  const [toDate, setToDate] = useState<any>("");
  const [reportingManager, setReportingManager] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [requestFor, setRequestFor] = useState<any>("");
  const [page, setPage] = useState(0);
  const [leavePage, setLeavePage] = useState(0)

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    if (employeesList && employeesList?.results?.length > 0) {
      setEmployeeUUID(employeesList?.results[0]?.uuid);
      setIsManager(employeesList?.results[0]?.is_manager);
    }
  }, [employeesList]);

  useEffect(() => {
    getEmployeeList();
    getLeaveApplication();
    getEmployeeDepartment();
    getReportingManager("is_manager=true");
  }, []);

  useEffect(() => {
    if (value === 0) {
      getpendingregularization("?self_request=true&status=pending&paze_size=true");
      getLeaveRequests("?self_request=true&status=pending&paze_size=true");
    } else {
      getpendingregularization("?status=pending&paze_size=true");
      getLeaveRequests("?status=pending&paze_size=true");
    }
  }, [value]);

  const toggleDrawer =
  (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const handleRequest = async (lead: any, request: string) => {
    const requestIds: [number, string][] = [];

    if (request === "Accept") {
      requestIds.push([lead, "approve"]);
    } else if (request === "Reject") {
      requestIds.push([lead, "reject"]);
    }
    const body: any = {
      status: requestIds,
    };
    await createLeaveRequests(body);
    getLeaveRequests("?status=pending");
  };
  const handleSubmit = async (id: any, data: any) => {
    const body: any = {
      status: [[id, data]],
    };

    await updateRegularization(body);
    getpendingregularization("?status=pending");
  };

  const handleBulkRequest = async (request: string, type: string) => {
    const requestIds: [number, string][] = [];

    if (request === "Accept") {
      selectedLeads.forEach((lead: any) => {
        requestIds.push([lead, "approved"]);
      });
    } else if (request === "Reject") {
      selectedLeads.forEach((lead: any) => {
        requestIds.push([lead, "reject"]);
      });
    }
    const body: any = {
      status: requestIds,
    };

    if (type === "attendance") {
      await updateRegularization(body);
      setSelectedLeads([]);
      getpendingregularization("?status=pending");
    } else if (type === "leave") {
      await createLeaveRequests(body);
      setSelectedLeads([]);
      getLeaveRequests("?status=pending");
    }

  };

  const checkedLead = (event: any, leadId: number) => {
    if (selectedLeads?.length < 10) {
      !event.target.checked
        ? setSelectedLeads(selectedLeads.filter((lead: any) => lead !== leadId))
        : setSelectedLeads((prev: any) => [...prev, leadId]);
    } else {
      return false;
    }
  };
  const handleType = (event: SelectChangeEvent) => {
    const typev = event.target.value as string;
    setType(typev);
    if (value === 0) {
      const query = `?self_request=true&status=${typev}&paze_size=true`;
      getpendingregularization(query);
      getLeaveRequests(query);
    }
    else if (value === 1) {
      const query = `?status=${typev}&paze_size=true`;
      getpendingregularization(query);
      getLeaveRequests(query);
    }
  };

  const handleRegularizeFilter = async () => {
    const body: any = {
      employeeUUid,
      reportingManager,
      employeeDept,
      fromDate,
      toDate,
    };

    const url = generateUrlRegularze(body);
    if (requestFor === "attendance-request") {
      await getpendingregularization(url ? `?paze_size=true&status=${type}${url}` : "");
    } else if (requestFor === "leave-request") {
      await getLeaveRequests(url ? `?paze_size=true&status=${type}${url}` : "");
    }
    setIsDrawerOpen(false);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> ,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = pendingreularization.links.next;
      getpendingregularization(url ? url?.substring(url?.indexOf("?")) : "");
    } else if (newPage < page) {
      let url = pendingreularization.links.previous;
      getpendingregularization(
        url ? url?.indexOf("?") === -1 ? "" : url.substring(url?.indexOf("?")) : ""
      );
    }
    setPage(newPage);
  };

  const handleLeaveChangePage =  (
    event: React.ChangeEvent<unknown> ,
    newPage: number
  ) => {
    if (newPage > leavePage) {
      let url = leaveRequests.links.next;
      getLeaveRequests(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = leaveRequests.links.previous;
      getLeaveRequests(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setLeavePage(newPage);
  };



  return (
    <main style={{ width: "100%" }}>
      <div className={classes.toolbar} />

      <h2
        className="mainHeading"
        style={{
          display: "block",
          marginTop: "30px",
          width: "auto",
          textAlign: "center",
          color: "rgb(14, 63, 108)",
          fontSize: "2.5rem",
          fontWeight: "700",
          fontFamily: "Roboto, sans-serif",
          letterSpacing: "1px",
          textTransform: "uppercase",
          background: "linear-gradient(to right, #0e3f6c, #0e7fb8)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
          padding: "10px 20px",
          borderRadius: "8px",
        }}
      >
        Request Center
      </h2>

      {(["right"] as const).map((anchor) => (
        <>
          <Drawer
            anchor={anchor}
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
          >
            <Box sx={{ width: 450, margin: "70px 40px", padding: "0" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                  padding: "12px 20px",
                  borderRadius: "8px",
                  backgroundColor: "#f0f4f8",
                  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
                  fontFamily: "'Poppins', sans-serif",
                  border: "1px solid #dcdcdc",
                  transition: "transform 0.2s ease, box-shadow 0.2s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-3px)";
                  e.currentTarget.style.boxShadow =
                    "0 6px 12px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow =
                    "0 3px 10px rgba(0, 0, 0, 0.1)";
                }}
              >
                <h6
                  style={{
                    margin: 0,
                    fontSize: "16px",
                    color: "#2c3e50",
                    fontWeight: 500,
                    letterSpacing: "0.3px",
                    textAlign: "center",
                    lineHeight: 1.4,
                  }}
                >
                  Apply Filters
                </h6>
              </div>

              <div>
                <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                  Employee
                </label>
                <Autocomplete
                  onChange={(event, obj) => {
                    if (obj) {
                      setEmployeeUuid(obj.uuid);
                    }
                  }}
                  id="employee"
                  options={employeesList?.results || []}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      newInputValue &&
                        getEmployeeList(
                          `get_all=true&employee_id=${newInputValue}`
                        );
                    }, 1000);
                  }}
                  getOptionLabel={(option: any) => {
                    return option?.employee_id + " " + option.name;
                  }}
                  key={resetEmployee}
                  renderOption={(option: any) => {
                    return (
                      <p>
                        <strong>
                          {option?.name.toString()}
                          <br />
                        </strong>
                        ({option?.employee_id?.toUpperCase()})
                      </p>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      placeholder="Select Employee"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label
                  htmlFor="reporting-manager"
                  style={{ fontWeight: "bold" }}
                >
                  Reporting Manager
                </label>
                <Autocomplete
                  onChange={(event, obj) => {
                    if (obj) {
                      setReportingManager(obj.uuid);
                    }
                  }}
                  key={resetManager}
                  id="reporting-manager"
                  options={reporting_manager?.results || []}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                    newInputValue && getReportingManager(`is_manager=true`);
                    }, 1000);
                  }}
                  getOptionLabel={(option: any) => {
                    return option?.employee_id + " " + option.name;
                  }}
                  renderOption={(option: any) => {
                    return (
                      <p>
                        <strong>
                          {option?.name.toString()}
                          <br />
                        </strong>
                        ({option?.employee_id?.toUpperCase()})
                      </p>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      placeholder="Select Reporting Manager"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Department
                </label>
                <Autocomplete
                  onChange={(event, obj) => {
                    if (obj) {
                      setEmployeeDept(obj.id);
                      setDepartmentHead(obj.department_head_name);
                    }
                  }}
                  id="department-name"
                  options={departmentNames || []}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      newInputValue &&
                        getEmployeeDepartment(`name=${newInputValue}`);
                    }, 1000);
                  }}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  renderOption={(option: any) => {
                    return option?.name;
                  }}
                  key={resetDept}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Select Department"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                <label htmlFor="from-date" style={{ fontWeight: "bold" }}>
                  From Date
                </label>
                <TextField
                  id="from-date"
                  variant="outlined"
                  value={fromDate}
                  onChange={(e: any) => setFromDate(e.target.value)}
                  type="date"
                  size="medium"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                <label htmlFor="To-date" style={{ fontWeight: "bold" }}>
                  To Date
                </label>
                <TextField
                  id="To-date"
                  variant="outlined"
                  value={toDate}
                  onChange={(e: any) => setToDate(e.target.value)}
                  type="date"
                  size="medium"
                />
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRegularizeFilter}
                  sx={{
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    fontWeight: 600,
                    padding: "18px 36px",
                    fontSize: "16px",
                    boxShadow: "0 5px 8px rgba(0, 0, 0, 0.15)",
                    height: "50px",
                    borderRadius: "8px",
                    transition:
                      "background-color 0.3s ease, transform 0.2s ease",
                    "&:hover": {
                      backgroundColor: "#0d47a1",
                      transform: "translateY(-2px)",
                      boxShadow: "0 8px 12px rgba(0, 0, 0, 0.2)",
                    },
                    "&:active": {
                      backgroundColor: "#0b549b",
                      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.15)",
                      transform: "translateY(0)",
                    },
                  }}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setToDate("");
                    setFromDate("");
                    setEmployeeUuid("");
                    setReportingManager("");
                    setEmployeeDept("");
                    setFromDate("");
                    setToDate("");
                    setEmployeeReset((prev: any) => !prev);
                    setDeptReset((prev: any) => !prev);
                    setResetManager((prev: any) => !prev);
                  }}
                  sx={{
                    color: "#1565c0",
                    fontWeight: 600,
                    padding: "18px 36px",
                    fontSize: "16px",
                    height: "50px",
                    borderRadius: "8px",
                    borderColor: "#1565c0",
                    boxShadow: "0 5px 8px rgba(0, 0, 0, 0.15)",
                    transition:
                      "background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#e3f2fd",
                      borderColor: "#0d47a1",
                      boxShadow: "0 8px 12px rgba(0, 0, 0, 0.2)",
                      transform: "translateY(-2px)",
                    },
                    "&:active": {
                      backgroundColor: "#bbdefb",
                      borderColor: "#0b549b",
                      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.15)",
                      transform: "translateY(0)",
                    },
                  }}
                >
                  Reset
                </Button>
              </div>
            </Box>
          </Drawer>
        </>
      ))}

      {
        <div
          style={{
            display: "flex",
            position: "sticky",
            top: "0px",
          }}
        >
          <div className="req-inner-cont-left">
            <Box
              sx={{
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: 128,
                  height: 128,
                },
              }}
              style={{}}
            >
              <Paper
                elevation={3}
                style={{
                  width: "600px",
                  borderRadius: "4px",
                  overflow: "hidden",
                  height: "148px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="icon label tabs example"
                >
                  <Tab
                    icon={<SendIcon />}
                    label="Sent Requests"
                    style={{
                      minWidth: "150px",
                      fontWeight: 500,
                      fontSize: "14px",
                      textTransform: "none",
                      letterSpacing: "normal",
                      color: value === 0 ? "#007BFF" : "#333",
                    }}
                  />
                  {isManager &&
                    <Tab
                      icon={<MoveToInboxIcon />}
                      label="Received Requests"
                      style={{
                        minWidth: "150px",
                        fontWeight: 500,
                        fontSize: "14px",
                        textTransform: "none",
                        letterSpacing: "normal",
                        color: value === 1 ? "#007BFF" : "#333",
                      }}
                    />
                  }
                </Tabs>

                <FormControl
                  fullWidth
                  style={{
                    marginTop: "20px",
                    // backgroundColor: "#f9f9f9",
                    padding: "15px",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    marginBottom: "20px"
                  }}
                >
                  <Select
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#333",
                    }}
                    value={type}
                    onChange={handleType}
                  >
                    <MenuItem
                      value="pending"
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Pending
                    </MenuItem>
                    <MenuItem
                      value="approved"
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                      }}

                    >
                      Approved
                    </MenuItem>
                    <MenuItem
                      value="revoked"
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                      }}

                    >
                      Revoked
                    </MenuItem>
                    <MenuItem
                      value="rejected"
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                      }}

                    >
                      Rejected
                    </MenuItem>
                    <MenuItem
                      value="cancelld"
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                      }}

                    >
                      Cancelled
                    </MenuItem>
                  </Select>
                </FormControl>

              </Paper>

              {(value === 0 || value === 1) && (
                <div
                  style={{
                    height: "auto",
                    width: "600px",
                    padding: " 16px 16px",
                    border: "1px solid #d9dadb",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    color: "blue",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <Accordion
                    expanded={expanded}
                    onChange={handleExpansion}
                    slots={{
                      transition: Fade as AccordionSlots["transition"],
                    }}
                    slotProps={{ transition: { timeout: 400 } }}
                    sx={{
                      "& .MuiAccordion-region": {
                        height: expanded ? "auto" : 0,
                      },
                      "& .MuiAccordionDetails-root": {
                        display: expanded ? "block" : "none",
                      },
                    }}
                  >
                    <AccordionDetails>
                      <Typography
                        sx={{ ":hover": { backgroundColor: "#e0e0e0" } }}
                        style={{ cursor: "pointer" }}
                      >
                        Attendance
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1976d2",
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 500,
                          textTransform: "uppercase",
                          letterSpacing: "0.5px",
                        }}
                      >
                        <HowToRegRoundedIcon
                          style={{ fontSize: 30, marginRight: 8 }}
                        />
                        Attendance
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "8px 0",
                          width: "100%",
                        }}
                        onClick={() => {
                          setAttendanceOpen(true);
                          setLeaveOpen(false);
                        }}
                      >
                        <Typography
                          style={{
                            cursor: "pointer",
                            fontFamily: "Roboto, sans-serif",
                            fontWeight: 500,
                            color: "#333",
                            padding: "8px 0",
                            transition: "background-color 0.3s ease",
                            width: "calc(100% - 50px)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = "#f5f5f5")
                          }
                          onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            "transparent")
                          }
                        >
                          Regularize
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: 500,
                            color: "#333",
                            padding: "8px 0",
                            transition: "background-color 0.3s ease",
                            cursor: "pointer",
                            width: "50px",
                            textAlign: "right",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = "#f5f5f5")
                          }
                          onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            "transparent")
                          }
                        >
                          {pendingreularization?.count}
                        </Typography>
                      </Grid>
                      {/* <Typography
                        style={{
                          cursor: "pointer",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 500,
                          color: "#333",
                          padding: "8px 0",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f5f5f5")
                        }
                        onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          "transparent")
                        }
                      >
                        Clock In
                      </Typography> */}
                      {/* <Typography
                        style={{
                          cursor: "pointer",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 500,
                          color: "#333",
                          padding: "8px 0",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f5f5f5")
                        }
                        onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          "transparent")
                        }
                      >
                        Punch logs
                      </Typography> */}
                      {/* <Typography
                        style={{
                          cursor: "pointer",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 500,
                          color: "#333",
                          padding: "8px 0",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f5f5f5")
                        }
                        onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          "transparent")
                        }
                      >
                        WFH / OD Request
                      </Typography> */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: "#1976d2",
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 500,
                          textTransform: "uppercase",
                          letterSpacing: "0.5px",
                        }}
                      >
                        {" "}
                        <TimeToLeaveIcon
                          style={{ fontSize: 30, marginRight: 8 }}
                        />
                        Leave
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Accordion>
                        <AccordionDetails>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "8px 0",
                              width: "100%",
                            }}
                            onClick={() => {
                              setLeaveOpen(true);
                              setAttendanceOpen(false);
                              // getLeaveRequests("?status=pending");
                            }}
                          >
                            <Typography
                              style={{
                                cursor: "pointer",
                                fontFamily: "Roboto, sans-serif",
                                fontWeight: 500,
                                color: "#333",
                                padding: "8px 0",
                                transition: "background-color 0.3s ease",
                                width: "calc(100% - 50px)",
                              }}
                              onMouseOver={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "#f5f5f5")
                              }
                              onMouseOut={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "transparent")
                              }
                            >
                              Leave Request
                            </Typography>
                            <Typography
                              style={{
                                fontWeight: 500,
                                color: "#333",
                                padding: "8px 0",
                                transition: "background-color 0.3s ease",
                                cursor: "pointer",
                                width: "50px",
                                textAlign: "right",
                              }}
                              onMouseOver={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "#f5f5f5")
                              }
                              onMouseOut={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "transparent")
                              }
                            >
                              {leaveRequests?.count}
                            </Typography>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </Box>
          </div>
          <div>
            {attendanceOpen === true ? (
              <Paper
                elevation={3}
                style={{
                  padding: "0 17px",
                  border: "1px solid #d9dadb",
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  overflowY: "auto",
                  width: "100%",
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
              >
                 <div
                 style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap:"5px",
                  marginTop: "10px",
                }}
                >
                 <Pagination count={Math.ceil(pendingreularization.count/pendingreularization.page_size)} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />

                </div>
                {isManager&&<div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "5px",
                    marginTop: "10px",
                  }}
                >
                  <div style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: "10px",
                    }}>
                  <h6
                    style={{
                      marginTop: "6px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#007BFF",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      padding: "5px 10px",
                      backgroundColor: "#f0f8ff", // Light background color
                      borderRadius: "5px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      display: "inline-block",
                    }}
                  >
                    Bulk Regularize :
                  </h6>
                  <input
                    disabled={type === "approved" || type === "cancelld" || type === "rejected" || type === "revoked"}
                    type="checkbox"
                    className="input"
                    name="selectall"
                    id="selectall"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    onChange={(e: any) =>
                      e.target.checked
                        ? setSelectedLeads(
                          pendingreularization && pendingreularization?.results?.length && 
                          pendingreularization?.results?.map(
                            (lead: any, index: number) => {
                              if (index < 100) {
                                return lead.id;
                              }
                            }
                          )
                        )
                        : setSelectedLeads([])
                    }
                  />
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        setRequestFor("attendance-request");
                        toggleDrawer(true)(event);
                      }}
                      sx={{
                        backgroundColor: "#1565c0",
                        color: "#fff",
                        fontWeight: 600,
                        padding: "14px 28px",
                        marginLeft: "12px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        height: "50px",
                        fontSize: "16px",
                        transition:
                          "background-color 0.3s ease, transform 0.2s ease",
                        "&:hover": {
                          backgroundColor: "#0d47a1",
                          transform: "translateY(-2px)",
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                        },
                        "&:active": {
                          backgroundColor: "#0b549b",
                          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.15)",
                          transform: "translateY(0)",
                        },
                      }}
                    >
                      Filter
                    </Button>
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        getpendingregularization(`?status=${type}&paze_size=true`);
                      }}
                      sx={{
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontWeight: 600,
                        padding: "12px 24px",
                        marginLeft: "12px",
                        borderRadius: "8px",
                        boxShadow: "0 5px 8px rgba(0, 0, 0, 0.1)",
                        height: "50px",
                        fontSize: "16px",
                        transition:
                          "background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#0d47a1",
                          color: "#ff7961",
                          transform: "translateY(-2px)",
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                        },
                        "&:active": {
                          backgroundColor: "#0b549b",
                          color: "#ff7961",
                          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.15)",
                          transform: "translateY(0)",
                        },
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>}

                <div>
                  {selectedLeads.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          color="success"
                          // disabled={type === "approved" || type === "cancelld" || type === "rejected" || type === "revoked"}
                          sx={{
                            display: (type === "approved" || type === "cancelld" || type === "rejected" || type === "revoked") ? "none" : "block",
                            backgroundColor: "#4CAF50",
                            color: "#fff",
                            fontWeight: "bold",
                            padding: "0 10px 20px 10px",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            height: "25px",
                            transition:
                              "background-color 0.3s ease, transform 0.2s ease",
                            "&:hover": {
                              backgroundColor: "#45A049",
                            },
                          }}
                          onClick={() =>
                            handleBulkRequest("Accept", "attendance")
                          }
                        >
                          Accept
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{
                            display: (type === "approved" || type === "cancelld" || type === "rejected" || type === "revoked") ? "none" : "block",
                            backgroundColor: "#F44336",
                            color: "#fff",
                            fontWeight: "bold",
                            height: "25px",
                            padding: "0 10px 20px 10px",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition:
                              "background-color 0.3s ease, transform 0.2s ease",
                            "&:hover": {
                              backgroundColor: "#D32F2F",
                            },
                          }}
                          onClick={() =>
                            handleBulkRequest("Reject", "attendance")
                          }
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ModalClose
                    onClick={() => setAttendanceOpen(false)}
                    style={{
                      margin: "-4px -25px",
                      border: "1.5px solid black",
                      borderRadius: "50%",
                    }}
                  />
                  {pendingreularization && pendingreularization?.results?.length > 0
                    ? (
                      pendingreularization?.results?.map((data: any, index: number) => {
                        const name = data.employee ? data.employee?.charAt(0).toUpperCase() : '';

                        return (
                          <ListItem
                            alignItems="flex-start"
                            style={{
                              marginTop: "20px",
                              borderRadius: "38px",
                              boxShadow: "1px 1px 7px lightgrey",
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                style={{ boxShadow: ".5px .5px 5px lightgrey" }}
                              >
                                {name}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <p
                                  style={{
                                    fontSize: "1.2rem",
                                    color: "blue",
                                    fontWeight: "500",
                                    background: "#ebebf7",
                                    borderRadius: "15px",
                                    padding: "2px 8px",
                                    boxShadow: "1px 1px 7px lightgrey",
                                  }}
                                >
                                  {data.employee}
                                </p>
                              }
                              secondary={
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <span>
                                    <b>Requested on :</b>{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {data.created_at?.slice(0, 10) || "N/A"}
                                  </span>
                                  <br />
                                  <span>
                                    <b>Regularize Date:</b>
                                    &nbsp;&nbsp;{" "}
                                    {data.date?.slice(0, 10) || "N/A"}
                                  </span>
                                  <br />
                                  <span>
                                    <b>New Clock In:</b>{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {data.check_in?.slice(11, 16) || "N/A"}
                                  </span>
                                  <br />
                                  <span>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <b> New Clock Out:</b>{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {data.check_out?.slice(11, 16) || "N/A"}
                                      </div>
                                    </div>
                                  </span>
                                  <span>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <b>Status :</b> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                        {data.status
                                          ? data.status.charAt(0).toUpperCase() +
                                          data.status.slice(1)
                                          : "N/A"}
                                      </div>
                                    </div>
                                  </span>
                                  <span>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      <b>Regularization Type :</b>
                                      {data.regularization_category}
                                    </div>
                                  </div>
                                </span>
                                  <span>
                                    <b>Approved by :</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data?.approved_by?.name || "N/A"}
                                  </span>
                                  <br />
                                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {data?.approved_by?.employee_id || "N/A"}
                                  </span>
                                  <br />
                                  {data.department_head_approval!==null&&
                                  <span>
                                    <b>Approved by (HOD) :</b>{data?.department_head_approval?.name || "N/A"}({data?.department_head_approval?.employee_id || "N/A"})
                                  </span>}
                                 
                                  <span>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <b>Comment :</b> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                        {data.comments
                                          ? data.comments.charAt(0).toUpperCase() +
                                          data.comments.slice(1)
                                          : "N/A"}
                                      </div>
                                    </div>
                                  </span>


                                  {isManager &&
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        gap: "10px",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div style={{ marginLeft: "25px" }}>
                                        <Button
                                          variant="contained"
                                          color="success"
                                          sx={{
                                            backgroundColor: "#4CAF50",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            padding: "10px",
                                            borderRadius: "8px",
                                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                            height: "25px",
                                            transition:
                                              "background-color 0.3s ease, transform 0.2s ease",
                                            "&:hover": {
                                              backgroundColor: "#45A049",
                                            },
                                          }}
                                          disabled={data.status === "approved"}
                                          onClick={(e) =>
                                            handleSubmit(data?.id, "approved")
                                          }
                                        >
                                          Accept
                                        </Button>
                                      </div>
                                      <div style={{ marginLeft: "25px" }}>
                                        <Button
                                          variant="contained"
                                          color="error"
                                          sx={{
                                            backgroundColor: "#F44336",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            height: "25px",
                                            padding: "10px",
                                            borderRadius: "8px",
                                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                            transition:
                                              "background-color 0.3s ease, transform 0.2s ease",
                                            "&:hover": {
                                              backgroundColor: "#D32F2F",
                                            },
                                          }}
                                          disabled={data.status === "approved"}
                                          onClick={(e) =>
                                            handleSubmit(data?.id, "rejected")
                                          }
                                        >
                                          Reject
                                        </Button>
                                      </div>
                                    </div>
                                  }
                                  {isManager&&<div style={{ marginRight: "0px", width: "fit-content", marginLeft: "90%" }}>
                                    <input
                                      type="checkbox"
                                      className="input"
                                      name="selectall"
                                      id="selectall"
                                      checked={selectedLeads.some(
                                        (lead: any) => lead === data.id
                                      )}
                                      onChange={(e: any) =>
                                        checkedLead(e, data.id)
                                      }
                                    />
                                  </div>}
                                </Typography>
                              }
                            />
                          </ListItem>

                        )
                      }))
                    :
                    (
                      <Grid
                        container
                        style={{ marginTop: "36px", justifyContent: "center" }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            fontSize: "1.5rem",
                            color: "#ff4081",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 600,
                            textAlign: "center",
                            padding: "12px 24px",
                            borderRadius: "12px",
                          }}
                        >
                          No Requests Available!!
                        </Typography>
                      </Grid>
                    )
                  }
                </List>
              </Paper>
            ) : (
              ""
            )}
          </div>
          <div>
            {leaveOpen === true ? (

              <Paper
                elevation={3}
                style={{
                  padding: "0 17px",
                  border: "1px solid #d9dadb",
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  overflowY: "auto",
                  width: "400px",
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
              >
                  <div
                 style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap:"5px",
                  marginTop: "10px",
                }}
                >
                <Pagination count={Math.ceil(leaveRequests.count/leaveRequests.page_size)} page={page} onChange={handleLeaveChangePage} variant="outlined" shape="rounded" />

                </div>
                 {isManager&&<div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "5px",
                    marginTop: "10px",
                  }}
                >
                 <div>
                 <h6
                    style={{
                      marginTop: "6px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#007BFF",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      padding: "5px 10px",
                      backgroundColor: "#f0f8ff", // Light background color
                      borderRadius: "5px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      display: "inline-block",
                    }}
                  >
                    Bulk Leave Requests :
                  </h6>
                  <input
                    disabled={type === "approved" || type === "cancelld" || type === "rejected" || type === "revoked"}
                    type="checkbox"
                    className="input"
                    name="selectall"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    id="selectall"
                    onChange={(e: any) =>
                      e.target.checked
                        ? setSelectedLeads(
                          leaveRequests?.results &&
                          leaveRequests?.results?.map(
                            (lead: any, index: number) => {
                              if (index < 100) {
                                return lead.id;
                              }
                            }
                          )
                        )
                        : setSelectedLeads([])
                    }
                  />
                 </div>

                 <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        setRequestFor("leave-request");
                        toggleDrawer(true)(event);
                      }}
                      sx={{
                        backgroundColor: "#1565c0",
                        color: "#fff",
                        fontWeight: 600,
                        padding: "14px 28px",
                        marginLeft: "12px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        height: "50px",
                        fontSize: "16px",
                        transition:
                          "background-color 0.3s ease, transform 0.2s ease",
                        "&:hover": {
                          backgroundColor: "#0d47a1",
                          transform: "translateY(-2px)",
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                        },
                        "&:active": {
                          backgroundColor: "#0b549b",
                          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.15)",
                          transform: "translateY(0)",
                        },
                      }}
                    >
                      Filter
                    </Button>
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        getLeaveRequests(`?status=${type}&paze_size=true`);
                      }}
                      sx={{
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontWeight: 600,
                        padding: "12px 24px",
                        marginLeft: "12px",
                        borderRadius: "8px",
                        boxShadow: "0 5px 8px rgba(0, 0, 0, 0.1)",
                        height: "50px",
                        fontSize: "16px",
                        transition:
                          "background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#0d47a1",
                          color: "#ff7961",
                          transform: "translateY(-2px)",
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                        },
                        "&:active": {
                          backgroundColor: "#0b549b",
                          color: "#ff7961",
                          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.15)",
                          transform: "translateY(0)",
                        },
                      }}
                    >
                      Reset
                    </Button>
                  </div>


                </div>}

                <div>
                  {selectedLeads.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{
                            display: (type === "approved" || type === "cancelld" || type === "rejected" || type === "revoked") ? "none" : "block",
                            backgroundColor: "#4CAF50",
                            color: "#fff",
                            fontWeight: "bold",
                            padding: "0 10px 20px 10px",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            height: "25px",
                            transition:
                              "background-color 0.3s ease, transform 0.2s ease",
                            "&:hover": {
                              backgroundColor: "#45A049",
                            },
                          }}
                          onClick={() => handleBulkRequest("Accept", "leave")}
                        >
                          Accept
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{
                            display: (type === "approved" || type === "cancelld" || type === "rejected" || type === "revoked") ? "none" : "block",
                            backgroundColor: "#F44336",
                            color: "#fff",
                            fontWeight: "bold",
                            height: "25px",
                            padding: "0 10px 20px 10px",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition:
                              "background-color 0.3s ease, transform 0.2s ease",
                            "&:hover": {
                              backgroundColor: "#D32F2F",
                            },
                          }}
                          onClick={() => handleBulkRequest("Reject", "leave")}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ModalClose
                    onClick={() => setLeaveOpen(false)}
                    style={{
                      margin: "-4px -25px",
                      border: "1.5px solid black",
                      borderRadius: "50%",
                    }}
                  />
                  {leaveRequests && leaveRequests.results.length > 0 ?
                    (leaveRequests?.results?.map((leave: any, index: number) => {
                      const initial = leave.employee_name ? leave.employee_name.charAt(0).toUpperCase() : '';
                      return (
                        <ListItem
                          alignItems="flex-start"
                          style={{
                            marginTop: "20px",
                            borderRadius: "38px",
                            boxShadow: "1px 1px 7px lightgrey",
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              style={{ boxShadow: ".5px .5px 5px lightgrey" }}
                            >
                              {initial}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <p
                                style={{
                                  fontSize: "1.2rem",
                                  color: "blue",
                                  fontWeight: "500",
                                  background: "#ebebf7",
                                  borderRadius: "15px",
                                  padding: "2px 8px",
                                  boxShadow: "1px 1px 7px lightgrey",
                                }}
                              >
                                {leave.employee_name}
                              </p>
                            }
                            secondary={
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <span>
                                  <b>Requested on :</b>{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {leave.created_at?.slice(0, 10) || "N/A"}
                                </span>
                                <br />
                                <span>
                                  <b>Leave To Date :</b>{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {leave.from_date || "N/A"}
                                </span>
                                <br />
                                <span>
                                  <b>Leave Form Date :</b>{" "}
                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {leave.to_date || "N/A"}
                                </span>
                                <br />
                                <span>
                                  <b>Duration Time :</b>{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {leave.leave_from_session || "N/A"}
                                </span>
                                <br />
                                <span>
                                  <b>Leave Type :</b>{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {leave.leave_type || "N/A"}
                                </span>

                                <br />
                                <span>
                                  <b>Status :</b>{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {leave.status || "N/A"}
                                </span>
                                <br />
                                <span>
                                  <b>Approved by :</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{leave?.approved_by?.name || "N/A"}
                                </span>
                                <br />
                                <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {leave?.approved_by?.employee_id || "N/A"}

                                </span>

                                <span>
                                  <div style={{}}>
                                    <div>
                                      <b> Reason :</b>{" "}

                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {typeof leave.reason_for_leave ===
                                        "string"
                                        ? leave.reason_for_leave
                                        : JSON.stringify(
                                          leave.reason_for_leave
                                        )}
                                    </div>
                                    {isManager &&
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "left",
                                          alignItems: "center",
                                          gap: "10px",
                                          marginTop: "15px",
                                        }}
                                      >

                                       
                                        <div style={{ marginLeft: "25px" }}>
                                          <Button
                                            variant="contained"
                                            color="success"
                                            sx={{
                                              backgroundColor: "#4CAF50",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              padding: "10px",
                                              borderRadius: "8px",
                                              boxShadow:
                                                "0 4px 6px rgba(0, 0, 0, 0.1)",
                                              height: "25px",
                                              transition:
                                                "background-color 0.3s ease, transform 0.2s ease",
                                              "&:hover": {
                                                backgroundColor: "#45A049",
                                              },
                                            }}
                                            onClick={() =>
                                              handleRequest(leave.id, "Accept")
                                            }
                                            disabled={leave.status === "approved"}
                                          >
                                            Accept
                                          </Button>
                                        </div>
                                        <div style={{ marginLeft: "25px" }}>
                                          <Button
                                            variant="contained"
                                            color="error"
                                            sx={{
                                              backgroundColor: "#F44336",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              height: "25px",
                                              padding: "10px",
                                              borderRadius: "8px",
                                              boxShadow:
                                                "0 4px 6px rgba(0, 0, 0, 0.1)",
                                              transition:
                                                "background-color 0.3s ease, transform 0.2s ease",
                                              "&:hover": {
                                                backgroundColor: "#D32F2F",
                                              },
                                            }}
                                            disabled={leave.status === "approved"}
                                            onClick={() =>
                                              handleRequest(leave.id, "Reject")
                                            }
                                          >
                                            Reject
                                          </Button>
                                        </div>
                                       
                                        <div
                                          style={{
                                            marginLeft: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "10px",
                                            backgroundColor: "#f9f9f9",
                                            borderRadius: "5px",
                                            boxShadow:
                                              "0 2px 4px rgba(0, 0, 0, 0.1)",
                                          }}
                                        >
                                          {isManager&&<input
                                            type="checkbox"
                                            className="input"
                                            name="selectall"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              cursor: "pointer",
                                            }}
                                            id="selectall"
                                            checked={selectedLeads.some(
                                              (lead: any) => lead === leave.id
                                            )}
                                            onChange={(e: any) =>
                                              checkedLead(e, leave.id)
                                            }
                                          />}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </span >

                              </Typography>
                            }
                          />
                        </ListItem>
                      )
                    }))
                    : (
                      <Grid
                        container
                        style={{ marginTop: "36px", justifyContent: "center" }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            fontSize: "1.5rem",
                            color: "#ff4081",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 600,
                            textAlign: "center",
                            padding: "12px 24px",
                            borderRadius: "12px",
                          }}
                        >
                          No Leave Requests Available!!
                        </Typography>
                      </Grid>
                    )}
                </List>
              </Paper>
            ) : (
              ""
            )
            }
          </div >
        </div >
      }
    </main >
  );
};

const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  leave_application: state.EmployeeReducer.leave_application,
  requestCenterLeave: state.EmployeeReducer.requestCenterLeave,
  pendingreularization: state.EmployeeReducer.pendingreularization,
  leavereivew: state.EmployeeReducer.leavereivew,
  leaveRequests: state.EmployeeReducer.leaveRequests,
  departmentNames:state.loginReducer.departmentNames,
  reporting_manager:state.loginReducer.reporting_manager,
});

export default connect(mapStateToProps, {
  getEmployeeList,
  leaveApplication,
  getLeaveApplication,
  getpendingregularization,
  leaveApplicationReview,
  updateRegularization,
  getLeaveRequests,
  createLeaveRequests,
  getReportingManager,
  getEmployeeDepartment,
})(CustomerLeadFilterPage);
