import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import SendIcon from '@mui/icons-material/Send';
import moment from "moment";
import {
    Paper,
    TextField,
    Grid,
    TableContainer,
    withStyles,
    TableFooter,
    TablePagination,
} from "@material-ui/core";
import {
    Checkbox
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import TableRow from "@material-ui/core/TableRow";
import {
    getEmployeeList,
    getEmployeeDepartment,
    getOrganizationsList,
    getEmployeeALlData,
    attendanceSummary,
    getattendancerecord,
} from "../../../actions/loginActions";
import Loader from "../Loader2";
import { generateEmployeeFilter } from "../../../helper/generate";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            overflow: "hidden",
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgb(14, 63, 108)",
            color: theme.palette.common.white,
            fontSize: "14px",
            fontWeight: "bolder",
        },
        body: {
            fontSize: 14,
            fontWeight: "bolder",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getEmployeeList: any;
    employeesList: any;
    getEmployeeDepartment: Function;
    departmentNames: any;
    getOrganizationsList: any;
    getEmployeeALlData: any;
    employee_all_data: any;
    loading: any;
    attendanceSummary: any;
    getattendancerecord:any;
}
const AttendanceSummary: React.FC<Props> = ({
    getEmployeeList,
    employeesList,
    getEmployeeDepartment,
    departmentNames,
    employee_all_data,
    getEmployeeALlData,
    attendanceSummary,
    getattendancerecord,
    loading,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [page, setPage] = useState(0);
    const history = useHistory();
    const [employeeId, setEmployeeId] = useState<any>("");
    const [employeeDept, setEmployeeDept] = useState<any>("");
    const [selectedLeads, setSelectedLeads] = useState<any>([]);
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const MyCurrenttoday = moment().format("YYYY-MM-DD");

    useEffect(() => {
        getEmployeeList("get_all=true");
        getEmployeeALlData("");
        getEmployeeDepartment("");
        getOrganizationsList("");
    }, []);


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = employeesList.links && employeesList.links.next.split("?")[1];

            getEmployeeList(`${url}`);
        } else if (newPage < page) {
            let url =
                employeesList.links && employeesList.links.previous.split("?")[1];

            getEmployeeList(`${url}`);
        }
        setPage(newPage as number);
    };


    const handleResetFunction = () => {
        history.push("/dashboard/payrollmanager/attendance-summary");
    };

    const handlFilterEmployee = () => {
        const body: any = {
            employee_id: employeeId,
            department: employeeDept != 64 ? employeeDept : "",
        };
        const url = generateEmployeeFilter(body).substring(2);
        getEmployeeList(`get_all=true&${url}`);
        setPage(0);
    };

    const checkedLead = (event: any, leadId: number) => {
        if (selectedLeads?.length < 10) {
            !event.target.checked
                ? setSelectedLeads(selectedLeads.filter((lead: any) => lead !== leadId))
                : setSelectedLeads((prev: any) => [...prev, leadId]);
        } else {
            return false;
        }
    };

    const handleAttendanceSummary = async () => {
        const body = {
            employee_ids: selectedLeads,
            start_date: startDate,
            end_date: endDate,
            send_summary_email: true,
        }
        // await attendanceSummary(body);
        await getattendancerecord(`employee_ids=${selectedLeads}&start_date=${startDate}&end_date=${endDate}&send_summary_email=true`);
        getEmployeeList("get_all=true");
        setStartDate("");
        setEndDate("");
        setSelectedLeads([]);
    }

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <h4
                style={{
                    color: "rgb(14, 63, 108)",
                    fontSize: "35px",
                    fontWeight: "bolder",
                    textAlign: "center",
                    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
                    letterSpacing: "0.5px",
                    textTransform: "capitalize",
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
                }}
            >
                Attendance Summary
            </h4>
            <Grid
                container
                spacing={3}
                style={{
                    margin: "1rem auto",
                }}
            >
                <Grid item xs={12} sm={3} md={3}>
                    <Autocomplete
                        onChange={(event, obj) => {
                            if (obj) {
                                setEmployeeId(obj.employee_id);
                            } else {
                                setEmployeeId("");
                            }
                        }}
                        id="checkboxes-tags-demo"
                        options={employee_all_data?.results || []}
                        getOptionLabel={(option: any) => {
                            return option?.employee_id + " " + option.name;
                        }}
                        renderOption={(option: any) => {
                            return (
                                <p>
                                    <b style={{ color: "blue" }}>Employee Name:</b>
                                    <strong>
                                        {option?.name.toString()}
                                        <br />
                                    </strong>
                                    <b style={{ color: "red" }}>Employee Id:</b>
                                    {option?.employee_id?.toUpperCase()}
                                </p>
                            );
                        }}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                                newInputValue &&
                                    getEmployeeALlData(`employee_id=${newInputValue}`);
                            }, 1000);
                        }}
                        renderInput={(params) => (
                            <TextField
                                className="input"
                                {...params}
                                label="Employee ID Or Name"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                                inputProps={{
                                    ...params.inputProps,
                                    onKeyDown: (e) => {
                                        if (e.key === "Enter") {
                                            e.stopPropagation();
                                        }
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <Autocomplete
                        onChange={(event, obj) => {
                            if (obj) {
                                setEmployeeDept(obj.id);
                            } else {
                                setEmployeeDept("");
                            }
                        }}
                        id="department-name"
                        options={departmentNames || []}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                                newInputValue &&
                                    getEmployeeDepartment(`name=${newInputValue}`);
                            }, 1000);
                        }}
                        getOptionLabel={(option: any) => {
                            return option.name;
                        }}
                        renderOption={(option: any) => {
                            return option?.name;
                        }}
                        renderInput={(params) => (
                            <TextField
                                className="input"
                                {...params}
                                label="Department Name"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                                inputProps={{
                                    ...params.inputProps,
                                    onKeyDown: (e) => {
                                        if (e.key === "Enter") {
                                            e.stopPropagation();
                                        }
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <TextField
                        className="input"
                        name="start_date"
                        type="date"
                        label="StartDate"
                        value={startDate}
                        variant="outlined"
                        onChange={(e) => setStartDate(e.target.value)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <TextField
                        name="End_date"
                        type="date"
                        value={endDate}
                        className="input"
                        label="End Date"
                        variant="outlined"
                        inputProps={{
                            max: MyCurrenttoday,
                        }}
                        onChange={(e) => setEndDate(e.target.value as string)}
                        style={{ width: "96%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{
                            backgroundColor: "#1976d2",
                            color: "#ffffff",
                            borderRadius: "8px",
                            padding: "8px 16px",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "none",
                            transition: "background-color 0.3s, box-shadow 0.3s",
                            boxShadow: "none",
                        }}
                        onClick={handlFilterEmployee}
                        startIcon={<SearchIcon />}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        startIcon={<RotateLeftIcon />}
                        style={{
                            backgroundColor: "#9c27b0",
                            color: "#ffffff",
                            borderRadius: "8px",
                            padding: "8px 16px",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "none",
                            transition: "background-color 0.3s, box-shadow 0.3s",
                            boxShadow: "none",
                        }}
                        onClick={() => handleResetFunction()}
                    >
                        Reset
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={!startDate || !endDate || selectedLeads.length === 0}
                        style={{
                            backgroundColor: !startDate || !endDate || selectedLeads.length === 0 ? "rgba(60, 64, 67, .3)" : "#28a745",
                            color: "#ffffff",
                            borderRadius: "8px",
                            padding: "8px 16px",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "none",
                            transition: "background-color 0.3s, box-shadow 0.3s",
                            boxShadow: "none",
                        }}
                        startIcon={<SendIcon />}
                        onClick={handleAttendanceSummary}
                    >
                        Send Attendance Summary
                    </Button>
                </Grid>
            </Grid>
            <div
                style={{
                    padding: "8px",
                    marginTop: "1.5rem",
                    border: "1px solid #ced4da",
                    borderRadius: "8px",
                    backgroundColor: "#e9ecef",
                    display: "inline-block",
                    fontSize: "16px",
                    color: "#495057",
                    fontWeight: "500",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                }}
            >
                <span style={{ fontSize: "18px", fontWeight: "600", color: "#343a40" }}>
                    👉 Please select a Start Date, End Date, and at least one employee to send the Attendance Summary..
                </span>
            </div>
            <TableContainer
                className={classes.tableContainer}
                component={Paper}
            >
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">
                                <input
                                    type="checkbox"
                                    className="input"
                                    name="selectall"
                                    id="selectall"
                                    onChange={(e) =>
                                        e.target.checked
                                            ? setSelectedLeads(
                                                employeesList?.results &&
                                                employeesList?.results.map(
                                                    (data: any, index: number) => {
                                                        if (index < 100) {
                                                            return data?.employee_id;
                                                        }
                                                    }
                                                )
                                            )
                                            : setSelectedLeads([])
                                    }
                                />
                            </StyledTableCell>
                            <StyledTableCell align="center">Employee Name</StyledTableCell>
                            <StyledTableCell align="center">Employee ID</StyledTableCell>
                            <StyledTableCell align="center">
                                Employee Gender
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Employee Designation
                            </StyledTableCell>
                            <StyledTableCell align="center">Employee email</StyledTableCell>
                            <StyledTableCell align="center">Phone Number</StyledTableCell>
                            <StyledTableCell align="center">
                                Employee Department{" "}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Employee Status
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <TableBody>
                                {employeesList &&
                                    employeesList?.results?.length > 0 &&
                                    employeesList?.results?.map((data: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    <input
                                                        type="checkbox"
                                                        name="selectall"
                                                        id="selectall"
                                                        checked={selectedLeads.find(
                                                            (qa: any) => qa === data.employee_id
                                                        )}
                                                        onChange={(e) => checkedLead(e, data.employee_id)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.employee_id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.gender ? data?.gender : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.employee_designation
                                                        ? data?.employee_designation
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.email ? data?.email : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.phonenumber ? data?.phonenumber : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.department ? data?.department : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.status ? data?.status : "NA"}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </>
                    )}
                    <TableFooter>
                        <StyledTableRow style={{ textAlign: "center" }}>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={6}
                                count={employeesList?.count || 0}
                                rowsPerPage={employeesList?.page_size}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

        </main>
    );
};
const mapStateToProps = (state: any) => ({
    loading: state.loginReducer.loading,
    employeesList: state.loginReducer.employeesList,
    departmentNames: state.loginReducer.departmentNames,
    employee_all_data: state.loginReducer.employee_all_data,
});
export default connect(mapStateToProps, {
    getEmployeeList,
    getOrganizationsList,
    getEmployeeDepartment,
    getEmployeeALlData,
    attendanceSummary,
    getattendancerecord,
})(AttendanceSummary);