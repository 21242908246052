import React, { useEffect} from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { MessageRounded } from "@material-ui/icons";
import Button from "@mui/material/Button";
import { withStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { generateLmsModulesFilter } from "../../../helper/generate";
import BookingCommentsModal from "./commentsModal";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableFooter,
  TableCell,
  TableRow,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  postCreateModule,
  updateModule,
  getLmsModules,
  bulkModuleActions,
} from "../../actions/PayrollActions";
import { getLmsCourses } from "../../actions/PayrollActions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      //   marginTop: "1rem",
      padding: "-1rem 2rem 2rem 2rem",
      width: "100%",
      borderRadius: "10px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      maxHeight: "90vh",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    container: {
      position: "sticky",
      marginTop: 30,
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputField: {
      backgroundColor: "rgb(230 242 255)",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#1C4E80",
      color: theme.palette.common.white,
      fontSize: "16px",
    },
    body: {
      fontSize: 14,
      padding: "5px",
      textTransform: "capitalize",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  postCreateModule: Function;
  updateModule: Function;
  getLmsCourses: Function;
  lmsCourses: any;
  getLmsModules: any;
  lmsModules: any;
  bulkModuleActions: Function;
}
const Modules: React.FC<Props> = ({
  postCreateModule,
  updateModule,
  getLmsCourses,
  lmsCourses,
  getLmsModules,
  lmsModules,
  bulkModuleActions,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false);
  const [openCreateModuleDrawer, setOpenCreateModuleDrawer] =
    React.useState(false);
  const [updateMode, setUpdateMode] = React.useState(false);
  const [updateModuleId, setUpdateModuleId] = React.useState(false);
  const [lessonType, setLessonType] = React.useState("");
  const [openAction, setOpenAction] = React.useState(false);
  const [actionType, setActionType] = React.useState("");
  const [selectedEmployess, setSelectedEmployees] = React.useState<any>([]);
  const [selectedModules, setSelectedModules] = React.useState<any>([]);
  const [expandSummary, setExpandSummary] = React.useState<number[]>([]);
  const [expandInstruction, setExpandInstruction] = React.useState<number[]>(
    []
  );
  const [openPdf, setOpenPdf] = React.useState(false);
  const [bulkAction, setBulkAction] = React.useState("");
  const [fileChanged, setFileChanged] = React.useState(false);
  const [courseUpdate, setCourseUpdate] = React.useState<any>({});
  const [disabledButton, setDisableButton] = React.useState(false);
  const [openEmployeeDetails, setOpenEmployeeDetails] = React.useState(false);
  const [clickedEmployeeData, setClickedEmployeeData] = React.useState<any>([]);
  const [filterClickedEmployeeData, setFilterClickedEmployeeData] =
    React.useState<any>([]);
  const [messageModal, setMessageModal] = React.useState<any>(false);
  const [moduleId, setModuleId] = React.useState<any>("");

  const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]{11})(?:\?si=([\w-]{16}))?$/;
  
  const [modules, setModules] = React.useState<any>({
    module_id: "",
    module_title: "",
    course_title: "",
    module_summary: "",
    status: "",
    module_instruction: "",
    reattempt:0,
    courses: [],
    lesson_type: "",
    video_url: "",
    pdf: "",
    ppt: "",
    weightage_type: "",
    passing_weightage: "",
    employee_id:"",
    questionsArray: [
      {
        name: "",
        q_question_summary: "",
        q_question_weightage: "",
        is_mandatory: true,
        q_answer_type: "",
        assignment_answer_question: [
          {
            name: "",
            is_correct: false,
          },
        ],
      },
    ],
  });

  const handleSummaryClick = (rowIndex: number) => {
    setExpandSummary((prev: any) =>
      prev?.includes(rowIndex)
        ? prev?.filter((index: any) => index !== rowIndex)
        : [...prev, rowIndex]
    );
  };

  const handleInstructionClick = (rowIndex: number) => {
    setExpandInstruction((prev: any) =>
      prev?.includes(rowIndex)
        ? prev?.filter((index: any) => index !== rowIndex)
        : [...prev, rowIndex]
    );
  };

  const handleModuleChange = (value: any, key: string) => {
    setModules((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleQuestionChange = (qIndex: number, key: any, value: any) => {
    let updateQuestionArray = [...modules.questionsArray];

    updateQuestionArray[qIndex][key] = value;
    setModules((prev: any) => ({
      ...prev,
      questionsArray: updateQuestionArray,
    }));
  };

  
  const handleMessageClick= (id:any) => {
    setMessageModal(true)
    setModuleId(id)
  }


  
  const handleEmployeeFilter = () => {
    const employee_id = modules.employee_id;
    const filterData = clickedEmployeeData?.filter(
      (employeeClicked: any) => employeeClicked.employee_id === employee_id
    );
    setFilterClickedEmployeeData(filterData);
  };

  const handleEmployeeReset = () => {
    setFilterClickedEmployeeData(clickedEmployeeData);
  };

  const handleEmployeeData = (module: any, key: any) => {
    if (
      key === "invited-users" &&
      module?.no_of_users_invited?.length > 0
    ) {
      setClickedEmployeeData(module?.no_of_users_invited);
      setOpenEmployeeDetails(true);
    } else if (
      key === "attempted-users" &&
      module?.no_of_users_attempted?.length > 0
    ) {
      setClickedEmployeeData(module?.no_of_users_attempted);
      setOpenEmployeeDetails(true);
    } else if (
      key === "not-attempted-users" &&
      (module?.no_of_users_invited?.length - module?.no_of_users_attempted?.length) > 0
    ) {
      const attemptedUsersId = module?.no_of_users_attempted?.map(
        (attempted: any) => attempted.employee_id
      );
      const notAttemptedUsers = module?.no_of_users_invited?.filter(
        (invited: any) => !attemptedUsersId.includes(invited.employee_id)
      );
      setClickedEmployeeData(notAttemptedUsers);
      setOpenEmployeeDetails(true);
    } else if (
      key === "completed-users" &&
      module?.no_of_users_completed?.length > 0
    ) {
      setClickedEmployeeData(module?.no_of_users_completed);
      setOpenEmployeeDetails(true);
    } else if (
      key === "failed-users" &&
      (module?.no_of_users_attempted?.length - module?.no_of_users_completed?.length)  > 0
    ) {
      const passedUsers = module?.no_of_users_completed?.map(
        (passed: any) => passed.employee_id
      );
      const failedUsers = module?.no_of_users_attempted?.filter(
        (attempt: any) => !passedUsers.includes(attempt.employee_id)
      );
      setClickedEmployeeData(failedUsers);
      setOpenEmployeeDetails(true);
    }

  };

  const checkEmployeeId = (event: any, employeeId: number) => {
    if (selectedEmployess?.length < 100) {
      !event.target.checked
        ? setSelectedEmployees(
            selectedEmployess?.filter(
              (employee_id: any) => employee_id !== employeeId
            )
          )
        : setSelectedEmployees((prev: any) => [...prev, employeeId]);
    } else {
      return false;
    }
  };

  const handleOptionChange = (
    qIndex: number,
    oIndex: number,
    field: string,
    value: any
  ) => {
    let updateOption = [...modules.questionsArray];
    let rightOptions = [...updateOption[qIndex].assignment_answer_question];

    if (field === "is_correct" && value) {
      rightOptions.forEach((opt, idx) => {
        if (idx !== oIndex) opt.is_correct = false;
      });
    }

    updateOption[qIndex].assignment_answer_question[oIndex][field] = value;
    rightOptions[oIndex][field] = value;

    setModules((prev: any) => ({
      ...prev,
      questionsArray: updateOption,
    }));
  };

  const isRightOptionSelected = modules.questionsArray.some(
    (question: any) =>
      question.q_answer_type === "optional" &&
      !question.assignment_answer_question.some(
        (option: any) => option.is_correct
      )
  );

  const handleAddQuestion = () => {
    const newQuestion = {
      name: "",
      q_question_summary: "",
      is_mandatory: true,
      q_question_weightage: "",
      q_answer_type: "",
      assignment_answer_question: [
        {
          name: "",
          is_correct: false,
        },
      ],
    };

    setModules((prev: any) => ({
      ...prev,
      questionsArray: [...prev.questionsArray, newQuestion],
    }));
  };

  const handleDeleteQuestion = (index: number) => {
    setModules((prev: any) => ({
      ...prev,
      questionsArray: prev.questionsArray?.filter(
        (question: any, i: number) => i !== index
      ),
    }));
  };

  const handleAddOption = (index: number) => {
    const newOption = {
      name: "",
      is_correct: false,
    };

    setModules((prev: any) => {
      const updatedQuestionsArray = prev.questionsArray?.map(
        (question: any, i: number) => {
          if (i === index) {
            return {
              ...question,
              assignment_answer_question: [
                ...question.assignment_answer_question,
                newOption,
              ],
            };
          }
          return question;
        }
      );

      return {
        ...prev,
        questionsArray: updatedQuestionsArray,
      };
    });
  };

  const handleDeleteOption = (questionIndex: number, optionIndex: number) => {
    setModules((prev: any) => {
      const updatedQuestionsArray = prev.questionsArray?.map(
        (question: any, i: number) => {
          if (i === questionIndex) {
            return {
              ...question,
              assignment_answer_question:
                question.assignment_answer_question?.filter(
                  (option: any, j: number) => j !== optionIndex
                ),
            };
          }
          return question;
        }
      );

      return {
        ...prev,
        questionsArray: updatedQuestionsArray,
      };
    });
  };

  const handleBulkActions = async () => {
    const allModules = lmsModules?.results?.filter((module: any) =>
      selectedModules.includes(module.id)
    );
    const checkPublishedStatus = allModules.some(
      (oneModule: any) => oneModule.status === "published"
    );
    const checkUnpublishedStatus = allModules.some(
      (oneModule: any) => oneModule.status === "draft"
    );
    if (checkPublishedStatus && bulkAction === "published") {
      alert("You can not published already published modules");
      return;
    } else if (checkUnpublishedStatus && bulkAction === "draft") {
      alert("You can not unpublished already unpublished modules");
      return;
    }

    if (bulkAction === "delete") {
      const confirmDelete = window.confirm("Are you sure you want to delete the selected modules?");
      if (!confirmDelete) {
        return;
      }
    }

    const data = {
      action_type: bulkAction === "delete" ? "delete" : "update",
      module_ids: selectedModules,
      status: bulkAction === "delete" ? "" : bulkAction,
    };

    await bulkModuleActions(data);
    getLmsModules();
    setOpenAction(false);
    setSelectedModules([]);
  };

  const handleModulesFilter = async () => {
    const body: any = {
      moduleId: modules.module_id,
      status: modules.status,
      name: modules.module_title,
      courseName: modules.course_title,
      department: modules.department,
    };

    const url = generateLmsModulesFilter(body);
    await getLmsModules(url ? `?${url.slice(1)}` : "");

    setOpenFilterDrawer(false);
  };

  const handleUpdateModule = (moduleData: any) => {
    setOpenCreateModuleDrawer(true);
    setUpdateMode(true);
    setCourseUpdate(moduleData?.course[0]);
    const updatedModule = {
      module_title: moduleData.name || "",
      module_id : moduleData.id,
      module_summary: moduleData.summary || "",
      status: moduleData.status || "",
      module_instruction: moduleData.instruction || "",
      courses: moduleData.course
        ? moduleData.course?.map((course: any) => course.id)
        : [],
      lesson_type: moduleData.lesson_type || "",
      video_url: moduleData.video_url || "",
      pdf: moduleData.doc_file || {},
      ppt: moduleData.doc_file || {},
      weightage_type: moduleData.weightage_type || "",
      passing_weightage: moduleData.minimum_score || "",
      questionsArray: moduleData.assignment_question_module?.map(
        (question: any) => ({
          name: question.name || "",
          q_question_summary: question.q_question_summary || "",
          q_question_weightage: question.q_question_weightage || "",
          is_mandatory: question.is_mandatory || true,
          q_answer_type: question.q_answer_type || "",
          assignment_answer_question: question.assignment_answer_question?.map(
            (answer: any) => ({
              name: answer.name || "",
              is_correct: answer.assignment_user % 2 === 1 ? true : false,
            })
          ),
        })
      ),
    };

    setUpdateModuleId(moduleData.id);
    setModules(updatedModule);
  };

  const checkModuleId = (event: any, moduleId: number) => {
    if (selectedModules?.length < 10) {
      !event.target.checked
        ? setSelectedModules(
            selectedModules?.filter((moduleid: any) => moduleid !== moduleId)
          )
        : setSelectedModules((prev: any) => [...prev, moduleId]);
    } else {
      return false;
    }
  };

  const getBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCharacterLimit = (
    inputText: string,
    updateModule: (text: string, field: string) => void
  ) => {
    if (inputText?.length <= 30) {
      updateModule(inputText, "module_title");
    } else {
      const truncatedText = inputText.slice(0, 30); // Truncate to 50 characters
      updateModule(truncatedText, "module_title");
    }
  };

  useEffect(() => {
    if (disabledButton) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setDisableButton(false);
      }, 15000);
    }
  }, [disabledButton]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setDisableButton(true)
    const clickedButton = e.nativeEvent.submitter?.value;

    if (!youtubeRegex.test(modules.video_url) && modules.lesson_type === "video_url") {
      alert("Please enter a valid YouTube URL before submitting!");
      return;
    }

    let updateQuestionArray = [...modules.questionsArray];

     
    let sumOfQuestionWeightage = updateQuestionArray.reduce((acc:any, question:any)=>{
      return acc + Number(question.q_question_weightage);
    }, 0)

    if(Number(modules.passing_weightage) > Number(sumOfQuestionWeightage)){
      alert("The total weightage of questions should be equal or greater than passing weightage");
      return;
    }

    if (updateQuestionArray?.length === 0) {
      alert("Atleat one question should be in a module");
      return;
    }

    let base64file = "";

    const data: any = {
      name: modules.module_title,
      summary: modules.module_summary,
      status: modules.status,
      instruction: modules.module_instruction,
      weightage_type: modules.weightage_type,
      lesson_type: modules.lesson_type,
      minimum_score: modules.passing_weightage,
      assignment_question_module: modules.questionsArray,
    };

    if(clickedButton === "create-update"){
      if (!updateMode) {
        if (modules.lesson_type === "video_url") {
          data.video_url = modules.video_url;
        } else if (modules.lesson_type === "pdf") {
          base64file = await getBase64(modules.pdf);
          data.doc_file = base64file;
        } else if (modules.lesson_type === "ppt") {
          base64file = await getBase64(modules.ppt);
          data.doc_file = base64file;
        }
        data.courses = modules.courses;
        data.status =  "draft";
        await postCreateModule(data);
        getLmsModules();
        setOpenCreateModuleDrawer(false);
      } else if (updateMode) {
        if (modules.lesson_type === "video_url") {
          data.video_url = modules.video_url;
        } else if (modules.lesson_type === "pdf" && modules.pdf && fileChanged) {
          base64file = await getBase64(modules.pdf);
          data.doc_file = base64file;
        } else if (modules.lesson_type === "ppt" && modules.ppt && fileChanged) {
          base64file = await getBase64(modules.ppt);
          data.doc_file = base64file;
        }
        data.courses = modules.courses;
        data.status = modules.status;
        await updateModule({ updateModuleId, data });
        getLmsModules();
        setOpenCreateModuleDrawer(false);
        setFileChanged(false);
      }
    }else if(clickedButton === "copy-module"){
        data.status =  "draft";
        data.duplicate_id = modules.module_id;
      if (modules.lesson_type === "video_url") {
        data.video_url = modules.video_url;
      } else if (modules.lesson_type === "pdf"  && modules.pdf && fileChanged) {
        base64file = await getBase64(modules.pdf);
        data.doc_file = base64file;
      } else if (modules.lesson_type === "ppt" && modules.ppt && fileChanged) {
        base64file = await getBase64(modules.ppt);
        data.doc_file = base64file;
      }
      await postCreateModule(data);
      getLmsModules();
      setOpenCreateModuleDrawer(false);
    }
  };


  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenFilterDrawer(open);
    };

  const toggleDrawer2 =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenCreateModuleDrawer(open);
    };

  useEffect(() => {
    getLmsCourses();
    getLmsModules("");
  }, []);

  useEffect(() => {
    if (clickedEmployeeData) {
      setFilterClickedEmployeeData(clickedEmployeeData);
    }
  }, [clickedEmployeeData, lmsModules]);

  

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4
            style={{
              fontWeight: "bold",
              textAlign: "left",

              color: "#2d148a",
              fontSize: "2rem",
              fontFamily: "'Arial Black', sans-serif",
              marginTop: "20px",
              display: "inline-block",
            }}
          >
            LMS Modules
          </h4>
        </div>
        <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ModeEditOutlineIcon />}
              onClick={() => {
                if(selectedModules?.length === 1){
                  const selectedId = selectedModules[0];
                  const findStatus = lmsModules.results.find((moduleId:any)=> moduleId.id === selectedId);
                  setBulkAction(findStatus.status);
                  setOpenAction(true)
                }else{
                  setBulkAction("");
                  setOpenAction(true)
                }
              }}
              disabled={selectedModules?.length === 0}
            >
              Action
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                toggleDrawer(true)(event);
              }}
              startIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                toggleDrawer2(true)(event);
                setUpdateMode(false);
                setModules({
                  module_title: "",
                  module_summary: "",
                  status: "",
                  module_instruction: "",
                  courses: [],
                  lesson_type: "",
                  video_link: "",
                  pdf: {},
                  ppt: {},
                  weightage_type: "",
                  passing_weightage: "",
                  questionsArray: [
                    {
                      name: "",
                      q_question_summary: "",
                      q_question_weightage: "",
                      is_mandatory: false,
                      q_answer_type: "",
                      assignment_answer_question: [
                        {
                          name: "",
                          is_correct: false,
                        },
                      ],
                    },
                  ],
                });
              }}
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          </div>
        </div>
      </div>

      {(["right"] as const)?.map((anchor) => (
        <>
          <Drawer
            anchor={anchor}
            open={openFilterDrawer}
            onClose={() => setOpenFilterDrawer(false)}
          >
            <Box sx={{ width: 450, margin: "70px 40px", padding: "0" }}>
              <div>
                <h3
                  style={{
                    marginTop: "10px",
                    color: "#1C4E80",
                    fontWeight: 900,
                    letterSpacing: "0.3px",
                    textAlign: "center",
                  }}
                >
                  Apply Filters
                </h3>
              </div>
              <hr style={{ borderBottom: "3px solid #007BFF" }} />
              <div>
                <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                  Module Id
                </label>
                <div>
                  <TextField
                    className="input"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={modules.module_id}
                    onChange={(e: any) =>
                      handleModuleChange(e.target.value, "module_id")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="module-status" style={{ fontWeight: "bold" }}>
                  Module Status
                </label>
                <TextField
                  className="input"
                  select
                  fullWidth
                  variant="outlined"
                  value={modules.status}
                  onChange={(e: any) =>
                    handleModuleChange(e.target.value, "status")
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="published">Published</MenuItem>
                  <MenuItem value="draft">Unpublished</MenuItem>
                </TextField>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Select Course
                </label>
                <Autocomplete
                  onChange={(event, obj) => {
                    if (obj) {
                      handleModuleChange(obj.name, "course_title");
                    }
                  }}
                  id="employee"
                  options={lmsCourses.results || []}
                  getOptionLabel={(option: any) => {
                    return option?.name;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.name}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      placeholder="Select Course"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="module-name" style={{ fontWeight: "bold" }}>
                  Module Name
                </label>
                <Autocomplete
                  onChange={(event, obj) => {
                    if (obj) {
                      handleModuleChange(obj.name, "module_title");
                    }
                  }}
                  id="employee"
                  options={lmsModules.results || []}
                  getOptionLabel={(option: any) => {
                    return option?.name;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.name}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      placeholder="Select Module"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button variant="contained" onClick={handleModulesFilter}>
                  Apply
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    setModules({
                      module_id: "",
                      module_title: "",
                      course_title: "",
                      module_summary: "",
                      status: "",
                      module_instruction: "",
                      courses: [],
                      lesson_type: "",
                      video_url: "",
                      pdf: {},
                      ppt: {},
                      weightage_type: "",
                      passing_weightage: "",
                      questionsArray: [
                        {
                          name: "",
                          q_question_summary: "",
                          q_question_weightage: "",
                          is_mandatory: true,
                          q_answer_type: "",
                          assignment_answer_question: [
                            {
                              name: "",
                              is_correct: false,
                            },
                          ],
                        },
                      ],
                    })
                  }
                >
                  Reset
                </Button>
              </div>
            </Box>
          </Drawer>
        </>
      ))}
      {(["right"] as const)?.map((anchor) => (
        <>
          <Drawer
            anchor={anchor}
            open={openCreateModuleDrawer}
            onClose={() => setOpenCreateModuleDrawer(false)}
          >
            <form onSubmit={handleSubmit}>
              <Box sx={{ width: 450, margin: "70px 40px", padding: "0" }}>
                <div>
                  <h3
                    style={{
                      marginTop: "10px",
                      color: "#1C4E80",
                      fontWeight: 900,
                      letterSpacing: "0.3px",
                      textAlign: "center",
                    }}
                  >
                    {updateMode ? "Update Module" : "Create Module"}
                  </h3>
                </div>
                <hr style={{ borderBottom: "3px solid #007BFF" }} />
                <div>
                  <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                    Module Title *
                  </label>
                  <div>
                    <TextField
                      className="input"
                      fullWidth
                      size="small"
                      variant="outlined"
                      required
                      value={modules.module_title || ""}
                      onChange={(e: any) =>
                        handleCharacterLimit(e.target.value, handleModuleChange)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "gray",
                      marginTop: "5px",
                    }}
                  >
                    {modules.module_title?.length || 0} / 30 characters
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label
                    htmlFor="course-instruction"
                    style={{ fontWeight: "bold" }}
                  >
                    Module Summary (Optional)
                  </label>
                  <div>
                    <TextareaAutosize
                      className="input"
                      value={modules.module_summary}
                      onChange={(e) =>
                        handleModuleChange(e.target.value, "module_summary")
                      }
                      style={{ width: "100%", borderRadius: "4px" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder=" Type instruction here..."
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label
                    htmlFor="course-instruction"
                    style={{ fontWeight: "bold" }}
                  >
                    Module Instruction (Optional)
                  </label>
                  <div>
                    <TextareaAutosize
                      className="input"
                      value={modules.module_instruction}
                      style={{ width: "100%", borderRadius: "4px" }}
                      onChange={(e) =>
                        handleModuleChange(e.target.value, "module_instruction")
                      }
                      aria-label="minimum height"
                      minRows={3}
                      placeholder=" Type instruction here..."
                    />
                  </div>
                </div>
                {
                  updateMode ? <div>
                  <label
                    htmlFor="reporting-manager"
                    style={{ fontWeight: "bold" }}
                  >
                    Module Status *
                  </label>
                  <div>
                    {modules.status}
                  </div>
                </div> : ""
                }
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                 {/* <div>
                <label htmlFor="module-status" style={{ fontWeight: "bold" }}>
                  Reattempt allowed (Ex: 2)
                </label>
                <TextField
                      className="input"
                      fullWidth
                      size="small"
                      variant="outlined"
                      required
                      value={modules.reattempt}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          /^\d*$/.test(value) &&
                          (value === "" ||
                            (Number(value) >= 0 && Number(value) <= 100))
                        ) {
                          handleModuleChange(value, "reattempt");
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div> */}
                {/* <div>
                  <label
                    htmlFor="reporting-manager"
                    style={{ fontWeight: "bold" }}
                  >
                    Batch
                  </label>
                  <div>
                    <Autocomplete
                      //   onChange={(event, obj) => {
                      //     if (obj) {
                      //       setEmployeeUuid(obj.uuid);
                      //     }
                      //   }}
                      id="employee"
                      options={[
                        {
                          text: "Batch-1",
                          value: "batch1",
                        },
                      ]}
                      //   onInputChange={(event, newInputValue) => {
                      //     clearTimeout(timer.current);
                      //     timer.current = setTimeout(() => {
                      //       newInputValue &&
                      //         getEmployeeList(
                      //           `get_all=true&employee_id=${newInputValue}`
                      //         );
                      //     }, 1000);
                      //   }}
                      getOptionLabel={(option: any) => {
                        return option?.text;
                      }}
                      renderOption={(option: any) => {
                        return <p>{option.text}</p>;
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          className="input"
                          {...params}
                          variant="outlined"
                          placeholder="Select Batch"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div> */}

                {!updateMode ? (
                  <div>
                    <label
                      htmlFor="select-course"
                      style={{ fontWeight: "bold" }}
                    >
                      Select Course *
                    </label>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let packs: any = [];
                          packs.push(newValue?.id)
                          handleModuleChange(packs, "courses");
                        }
                      }}
                      id="employee"
                      options={lmsCourses?.results || ""}
                      getOptionLabel={(option: any) => {
                        return option?.name;
                      }}
                      renderOption={(option: any) => {
                        return <p>{option.name}</p>;
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          className="input"
                          {...params}
                          variant="outlined"
                          placeholder="Select Course"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <label
                      htmlFor="select-course"
                      style={{ fontWeight: "bold" }}
                    >
                      Select Course *
                    </label>
                    <Autocomplete
                      defaultValue={{ id: courseUpdate?.id , name: courseUpdate?.name}}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let packs: any = [];
                          packs.push(newValue.id)
                          handleModuleChange(packs, "courses");
                        }
                      }}
                      id="employee"
                      options={lmsCourses?.results || ""}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option: any) => {
                        return option?.name;
                      }}
                      renderOption={(option: any) => {
                        return <p>{option.name}</p>;
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          className="input"
                          {...params}
                          variant="outlined"
                          placeholder="Select Course"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label
                    htmlFor="department-name"
                    style={{ fontWeight: "bold" }}
                  >
                    Lesson type *
                  </label>
                </div>
                <div>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={modules.lesson_type}
                      onChange={(e) =>
                        handleModuleChange(e.target.value, "lesson_type")
                      }
                    >
                      <FormControlLabel
                        value="video_url"
                        control={<Radio />}
                        label="Video"
                      />
                      <FormControlLabel
                        value="pdf"
                        control={<Radio />}
                        label="PDF"
                      />
                      <FormControlLabel
                        value="ppt"
                        disabled
                        control={<Radio />}
                        label="PPT"
                      />
                    </RadioGroup>
                  </FormControl>

                  {modules.lesson_type === "video_url" && (
                    <div>
                      <label
                        htmlFor="department-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Video Link *
                      </label>
                      <TextField
                        className="input"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                        value={modules.video_url}
                        onChange={(e: any) =>
                          handleModuleChange(e.target.value, "video_url")
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}

                  {modules.lesson_type === "pdf" && (
                    <div>
                      <label
                        htmlFor="department-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Upload Pdf *
                      </label>
                      {!updateMode ? (
                        <div>
                          <input
                            type="file"
                            name="doc_file"
                            id=""
                            accept=".pdf"
                            required
                            onChange={(e: any) =>
                              handleModuleChange(e.target.files[0], "pdf")
                            }
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <input
                              type="file"
                              name="doc_file"
                              id=""
                              accept=".pdf"
                              onChange={(e: any) => {
                                handleModuleChange(e.target.files[0], "pdf");
                                setFileChanged(true);
                              }}
                            />
                          </div>
                          <Button
                            variant="contained"
                            onClick={() => setOpenPdf(true)}
                          >
                            view
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {modules.lesson_type === "ppt" && (
                    <div>
                      <label
                        htmlFor="department-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Upload PPT *
                      </label>
                      <div>
                        <input
                          type="file"
                          name="doc_file"
                          id=""
                          accept=".ppt, .pptx"
                          required
                          onChange={(e: any) => {
                            handleModuleChange(e.target.files[0], "ppt");
                            setFileChanged(true);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label
                    htmlFor="department-name"
                    style={{ fontWeight: "bold" }}
                  >
                    Weightage *
                  </label>
                  <div>
                    <TextField
                      className="input"
                      select
                      fullWidth
                      size="small"
                      required
                      variant="outlined"
                      value={modules.weightage_type}
                      onChange={(e) =>
                        handleModuleChange(e.target.value, "weightage_type")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {/* <MenuItem value="percentage">Percentage</MenuItem> */}
                      <MenuItem value="marks">Marks</MenuItem>
                    </TextField>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label
                    htmlFor="department-name"
                    style={{ fontWeight: "bold" }}
                  >
                    Passing Weightage * (Max:100)
                  </label>
                  <div>
                    <TextField
                      className="input"
                      fullWidth
                      size="small"
                      variant="outlined"
                      required
                      value={modules.passing_weightage}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          /^\d*$/.test(value) &&
                          (value === "" ||
                            (Number(value) >= 0 && Number(value) <= 100))
                        ) {
                          handleModuleChange(value, "passing_weightage");
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>

                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>

                {modules.questionsArray?.map((question: any, index: number) => (
                  <div>
                    <div
                      style={{
                        marginTop: "10px",
                        padding: "10px",
                        border: "1px solid grey",
                        borderRadius: "5px",
                      }}
                    >
                      <div>
                        <label
                          htmlFor="employee"
                          style={{ fontWeight: "bold" }}
                        >
                          Question *
                        </label>
                        <div>
                          <TextField
                            className="input"
                            required
                            value={question.name}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                            fullWidth
                            size="small"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></div>
                      <div>
                        <label
                          htmlFor="reporting-manager"
                          style={{ fontWeight: "bold" }}
                        >
                          Question Summary (Optional)
                        </label>
                        <div>
                          <TextareaAutosize
                            className="input"
                            value={question.q_question_summary}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "q_question_summary",
                                e.target.value
                              )
                            }
                            style={{ width: "100%", borderRadius: "4px" }}
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Type summary here..."
                          />
                        </div>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ fontWeight: "bold" }}>
                          Is this Question Mandatory ?
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={question.is_mandatory}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "is_mandatory",
                                e.target.checked
                              )
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></div> */}
                      <div>
                        <label
                          htmlFor="department-name"
                          style={{ fontWeight: "bold" }}
                        >
                          Question Passing Weightage * (Percentage/Marks)
                        </label>
                        <div>
                          <TextField
                            className="input"
                            fullWidth
                            size="small"
                            variant="outlined"
                            required
                            value={question.q_question_weightage}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d*$/.test(value) &&
                                (value === "" ||
                                  (Number(value) >= 0 && Number(value) <= 100))
                              ) {
                                handleQuestionChange(
                                  index,
                                  "q_question_weightage",
                                  value
                                );
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="department-name"
                          style={{ fontWeight: "bold" }}
                        >
                          Answer type *
                        </label>
                        <div>
                          <TextField
                            className="input"
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            required
                            value={question.q_answer_type}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "q_answer_type",
                                e.target.value
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem value="optional">Optional</MenuItem>
                            <MenuItem value="written" disabled>
                              Written
                            </MenuItem>
                          </TextField>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></div>

                      {/* {question.answer_type === "written" && (
                <>
                  <div>
                    <TextareaAutosize
                      className="input"
                      onChange={(e)=> handleQuestionChange(index, "written_answer", e.target.value)}
                      style={{ width: "100%", borderRadius: "4px" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Type summary here..."
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></div>
                </>
              )} */}

                      {question.q_answer_type === "optional" && (
                        <div>
                          <label
                            htmlFor="department-name"
                            style={{ fontWeight: "bold" }}
                          >
                            Options *
                          </label>
                          {question.assignment_answer_question?.map(
                            (optiontype: any, optionindex: number) => (
                              <div>
                                <TextField
                                  className="input"
                                  size="small"
                                  variant="outlined"
                                  required
                                  value={optiontype.name}
                                  onChange={(e) =>
                                    handleOptionChange(
                                      index,
                                      optionindex,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  label={`Option-${optionindex + 1}`}
                                  fullWidth
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {question.assignment_answer_question?.length <
                                    5 && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      <div>
                                        <ControlPointIcon
                                          onClick={() => handleAddOption(index)}
                                        />
                                      </div>
                                      <div>Add Option</div>
                                    </div>
                                  )}

                                  {question.assignment_answer_question?.length >
                                    1 && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      <div>
                                        <DeleteRoundedIcon
                                          onClick={() =>
                                            handleDeleteOption(
                                              index,
                                              optionindex
                                            )
                                          }
                                        />
                                      </div>
                                      <div>Delete Option</div>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name=""
                                      id=""
                                      checked={optiontype.is_correct}
                                      onChange={(e: any) =>
                                        handleOptionChange(
                                          index,
                                          optionindex,
                                          "is_correct",
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <div>Right Option</div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    textAlign: "center",
                                    border: "none",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                ></div>
                              </div>
                            )
                          )}
                          {isRightOptionSelected ? (
                            <p style={{ fontSize: "12px", color: "blue" }}>
                              <b>NOTE: Select the checkbox for right option</b>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex", marginTop: "2px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div>
                          <ControlPointIcon onClick={handleAddQuestion} />
                        </div>
                        <div>Add Question</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div>
                          <DeleteRoundedIcon
                            onClick={() => handleDeleteQuestion(index)}
                          />
                        </div>
                        <div>Delete Question</div>
                      </div>
                    </div>
                  </div>
                ))}

                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    value="create-update"
                    disabled={
                      disabledButton ||
                      modules.courses?.length === 0 ||
                      modules.lesson_type === "" ||
                      isRightOptionSelected
                    }
                  >
                    {updateMode ? "Update Module" : "Create Module"}
                  </Button>
                  {
                    updateMode && <Button
                    variant="contained"
                    type="submit"
                    value="copy-module"
                    disabled={
                      disabledButton ||
                      modules.courses?.length === 0 ||
                      modules.lesson_type === "" ||
                      isRightOptionSelected
                    }
                  >
                    Copy Module
                  </Button>
                  }
                </div>
              </Box>
            </form>
          </Drawer>
        </>
      ))}

      <hr style={{ borderBottom: "3px solid #007BFF" }} />
      <TableContainer
        className={classes.container}
        style={{ maxHeight: "540px" }}
        component={Paper}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center" style={{ minWidth: 50 }}>
                <input
                  type="checkbox"
                  className="input"
                  name="selectall"
                  id="selectall"
                  style={{ transform: "scale(1.5)" }}
                  onChange={(e) =>
                    e.target.checked
                      ? setSelectedModules(
                          lmsModules?.results?.map(
                            (data: any, index: number) => {
                              return data.id;
                            }
                          )
                        )
                      : setSelectedModules([])
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 120 }}>
                <b>Module Id</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Module Name</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 350 }}>
                <b>Module Summary</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 350 }}>
                <b>Module Instruction</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Module Status</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Module Logs</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 200 }}>
                <b>Course Name/Title</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 250 }}>
                <b>Mapping</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Lesson type</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Questions</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Invited Users</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Attempted</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Not Attempted</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Completed</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Passed</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Failed</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Passing Weightage</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Click to action</b>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {lmsModules?.results?.map((module: any, index: any) => (
              <StyledTableRow>
                <StyledTableCell align="center">
                  <input
                    type="checkbox"
                    name="selectall"
                    id="selectall"
                    checked={selectedModules.find(
                      (qa: any) => qa === module.id
                    )}
                    onChange={(e) => checkModuleId(e, module.id)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{module.id}</StyledTableCell>
                <StyledTableCell align="center">{module.name}</StyledTableCell>
                <StyledTableCell align="center">
                  {module.summary !== "" ? (
                    <Typography style={{ display: "inline-block" }}>
                      {expandSummary.includes(index)
                        ? module.summary
                        : `${module.summary.slice(0, 50)}... `}
                      <Typography
                        onClick={() => handleSummaryClick(index)}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          display: "inline",
                          fontWeight: "bold",
                        }}
                      >
                        {expandSummary.includes(index)
                          ? "see less"
                          : "see more"}
                      </Typography>
                    </Typography>
                  ) : (
                    "No Summary"
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {module.instruction !== "" ? (
                    <Typography style={{ display: "inline-block" }}>
                      {expandInstruction.includes(index)
                        ? module.instruction
                        : `${module.instruction.slice(0, 50)}... `}
                      <Typography
                        onClick={() => handleInstructionClick(index)}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          display: "inline",
                          fontWeight: "bold",
                        }}
                      >
                        {expandInstruction.includes(index)
                          ? "see less"
                          : "see more"}
                      </Typography>
                    </Typography>
                  ) : (
                    "No Instruction"
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {module.status}
                </StyledTableCell>
                <StyledTableCell align="center">
                <MessageRounded
                              onClick={()=> handleMessageClick(module?.id)}
                              />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {module?.course?.length > 0
                    ? module.course?.map((moduleCourse: any) => moduleCourse?.name)?.join(", ")
                    : ""}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {Array.isArray(module?.department)
                    ? module?.department
                        ?.map((department: any) => department.name)
                        ?.join(", ")
                    : ""}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {module.lesson_type}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {module.assignment_question_module?.length}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ cursor:"pointer"}} onClick={()=> handleEmployeeData(module, "invited-users")}>
                  {module?.no_of_users_invited?.length}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ cursor:"pointer"}} onClick={()=> handleEmployeeData(module, "attempted-users")}>
                  {module?.no_of_users_attempted?.length}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ cursor:"pointer"}} onClick={()=> handleEmployeeData(module, "not-attempted-users")}>
                  {module?.no_of_users_invited?.length - module?.no_of_users_attempted?.length}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ cursor:"pointer"}} onClick={()=> handleEmployeeData(module, "completed-users")}>
                  {module?.no_of_users_completed?.length}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ cursor:"pointer"}} onClick={()=> handleEmployeeData(module, "completed-users")}>
                  {module?.no_of_users_completed?.length}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ cursor:"pointer"}} onClick={()=> handleEmployeeData(module, "failed-users")}>
                  {module?.no_of_users_attempted?.length -
                    module?.no_of_users_completed?.length}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {module?.minimum_score} marks
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() => handleUpdateModule(module)}
                  >
                    Edit
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openAction}
        onClose={() => setOpenAction(false)}
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
      >
        <DialogTitle style={{ fontWeight: "600" }}>ACTIONS</DialogTitle>
        <DialogContent dividers>
          <div>
            <TextField
              className="input"
              select
              fullWidth
              size="small"
              variant="outlined"
              onChange={(e: any) => setBulkAction(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="draft">Unpublished</MenuItem>
              <MenuItem value="delete">Delete</MenuItem>
            </TextField>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              disabled={bulkAction === ""}
              onClick={handleBulkActions}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPdf}
        onClose={() => setOpenPdf(false)}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{ borderRadius: "10px" }}>
          <iframe
            src={modules.pdf}
            style={{ width: "800px", height: "600px" }}
            title="PDF Document"
          ></iframe>
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEmployeeDetails}
        onClose={() => setOpenEmployeeDetails(false)}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Paper style={{ width: "70%", padding: "20px" }}>
          <h2>Employees</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div style={{ width: "300px" }}>
              <Autocomplete
                onChange={(event, obj) => {
                  if (obj) {
                    handleModuleChange(obj.employee_id, "employee_id");
                  }
                }}
                id="employee_ids"
                options={clickedEmployeeData || []}
                getOptionLabel={(option: any) => {
                  return option?.employee_id + " " + option.fullname;
                }}
                renderOption={(option: any) => {
                  return (
                    <p>
                      <b style={{ color: "blue" }}>Employee Name:</b>
                      <strong>{option?.fullname}</strong>
                      <br />
                      <b style={{ color: "red" }}>Employee Id:</b>
                      {option?.employee_id?.toUpperCase()}
                    </p>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Employee ID Or Name"
                    variant="outlined"
                    style={{ width: "100%" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Button variant="contained" onClick={handleEmployeeFilter}>
                Filter
              </Button>
            </div>
            <div>
              <Button variant="contained" onClick={handleEmployeeReset}>
                Reset
              </Button>
            </div>
          </div>

          <TableContainer
            className={classes.container}
            style={{ maxHeight: "400px", marginTop: "20px" }}
            component={Paper}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    <input
                      type="checkbox"
                      className="input"
                      name="selectall"
                      id="selectall"
                      style={{ transform: "scale(1.5)" }}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedEmployees(
                              filterClickedEmployeeData?.map(
                                (employee: any, index: number) => {
                                  return employee.employee_id;
                                }
                              )
                            )
                          : setSelectedEmployees([])
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 120 }}>
                    <b>Employee Id</b>
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 120 }}>
                    <b>Employee Name</b>
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 150 }}>
                    <b>Department</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filterClickedEmployeeData?.map(
                  (employee: any, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <input
                          type="checkbox"
                          name="selectall"
                          id="selectall"
                          checked={selectedEmployess.find(
                            (employeeId: any) =>
                              employeeId === employee.employee_id
                          )}
                          onChange={(e) =>
                            checkEmployeeId(e, employee.employee_id)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.employee_id || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.fullname || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.department || "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>
      <BookingCommentsModal moduleId={moduleId} courseId={""} openModal={messageModal} setopenModal={setMessageModal} />
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  lmsCourses: state.PayrollReducer.lmsCourses,
  lmsModules: state.PayrollReducer.lmsModules,
});
export default connect(mapStateToProps, {
  postCreateModule,
  updateModule,
  getLmsCourses,
  getLmsModules,
  bulkModuleActions,
})(Modules);
