//Authentication
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./index.css";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import MainLayout from "../Hoc/MainLayout";
import SnackBar from "../components/SnackBar";
import SecureStorage from "./SecureStorage";
import Login from "../containers/login";
import Dash from "../containers/dash";
import { checkUser } from "../actions/loginActions";
import { connect } from "react-redux";

//Payroll
import PayrollDash from "../containers/Payroll-agent";
import PayrollHome from "../PayrollManager-Panel/components/Home/index";
import PayrollEmployeeForm from "../PayrollManager-Panel/components/EmployeeCreationMaster/EmployeeCreationForm";
import PayrollEmployeeBasic from "../PayrollManager-Panel/components/EmployeeCreationMaster/EmployeeMaster";
import PayrollEmployeeList from "../PayrollManager-Panel/components/EmployeeCreationMaster/EmployeeTable";
import PayrollAttendanceList from "../PayrollManager-Panel/components/Attendance/AttendanceList";
import PayrollAttendanceViewDetails from "../PayrollManager-Panel/components/Attendance/ViewDetails";
import PayrollOrganizationCreation from "../PayrollManager-Panel/components/EmployeeCreationMaster/OrganizationCreation";
import PayrollOtherDetails from "../PayrollManager-Panel/components/EmployeeCreationMaster/OtherDetails";
import PayrollInbox from "../PayrollManager-Panel/components/notificationInbox/index";
import PayrollPayroll from "../PayrollManager-Panel/components/Pyroll/index";
import PayrollHoliday from "../PayrollManager-Panel/components/Holiday/index";
import PayrollPolicyCenter from "../PayrollManager-Panel/components/Policy-Center/index";
import PayrollLeaveApplication from "../PayrollManager-Panel/components/LeaveApplication/LeaveApplication";
import PayrollAttendancehome from "../PayrollManager-Panel/components/Attendancehome/attendancehome";
import PayrollMyAttendance from "../PayrollManager-Panel/components/MyAttendance/MyAttendance";
import PayrollEmployeeSalaryDetails from "../PayrollManager-Panel/components/EmployeeSalary/index";
import PayrollTaxDeclarationProff from "../PayrollManager-Panel/components/TaxDeclarationProffs/TaxDeclaration";
import PayrollIncomeTaxDeclaration from "../PayrollManager-Panel/components/IncomeTaxDeclaration/IncomeTaxDeclarations";
import PayrollKRA from "../PayrollManager-Panel/components/KRA-Table/index";
import PayrollLeaveBalance from "../PayrollManager-Panel/components/Leave/LeaveBalance";
import PayrollBulZipUploader from "../PayrollManager-Panel/components/DocumentZip/index";
import PayrollRequest from "../PayrollManager-Panel/components/Requestcenter/request";
import PayrollPayslip from "../PayrollManager-Panel/components/Payslip/Payslip";
import PayrollBulkUploader from "../PayrollManager-Panel/components/BulkUploader/BulkUploader";
import PayrollCalendar from "../PayrollManager-Panel/components/Calendar/calendar";
import PayrollEmployeeFeedback from "../PayrollManager-Panel/components/Employee-Feeedback/EmployeeFeedback";
import PayrollRegularization from "../PayrollManager-Panel/components/Regularization/index";
import PayrollManageAttendance from "../PayrollManager-Panel/components/ManageAttendance/index";
import PayrollManageEmployee from "../PayrollManager-Panel/components/ManageEmployee/index";
import PyrollPunchInOut from "../PayrollManager-Panel/components/Attendance/PunchInOut";
import PayrollUpdateBulkUpdate from "../PayrollManager-Panel/components/UpdateBulkUpload/index";
import PayrollPasswordReset from "../PayrollManager-Panel/components/EmployeeCreationMaster/PasswordReset";
import PayrollLMSCourses from "../PayrollManager-Panel/components/LMS/courses";
import PayrollLMSModules from "../PayrollManager-Panel/components/LMS/modules";
import PayrollLMSQuestions from "../PayrollManager-Panel/components/LMS/questions";
import PayrollLeaveBulkUploader from "../PayrollManager-Panel/components/LeaveBulkUploader/leaveUploader";
import PayrollManageLocation from "../PayrollManager-Panel/components/EmployeeCreationMaster/ManageEmployeeLocation";
import PayrollReportCard from "../PayrollManager-Panel/components/EmployeeCreationMaster/ManageEmployeeReport";
import PayRollWeekly from "../PayrollManager-Panel/components/Weekly-roster/index";
import PayrollManageDesignation from "../PayrollManager-Panel/components/EmployeeManagment/index";
import PayrollManageRegulizationLimit from "../PayrollManager-Panel/components/RegulizationLimit/index";
import PayrollManagerMultiManagerTagging from "../PayrollManager-Panel/components/Multi-Manager/index";
import EmailTemplate from "../PayrollManager-Panel/components/EmailController/index";
import PayrollManagerDailyAbsence from "../PayrollManager-Panel/components/DailyAbsence/index";
import PayrollManagerAttendanceSummary from "../PayrollManager-Panel/components/AttendanceSummary/index";

//Employee
import EmployeeDash from "../containers/Employee-agent";
import EmployeeHome from "../Employee-Panel/components/Home/index";
import EmployeeInbox from "../Employee-Panel/components/notificationInbox/index";
import EmployeeLeaveBalance from "../Employee-Panel/components/Leave/LeaveBalance";
import EmployeePayslip from "../Employee-Panel/components/Payslip/Payslip";
import EmployeeAttendancehome from "../Employee-Panel/components/Attendancehome/attendancehome";
import EmployeeManageAttendance from "../Employee-Panel/components/ManageAttendance/index";
import EmployeeRequest from "../Employee-Panel/components/Requestcenter/request";
import EmployeePunchInOut from "../Employee-Panel/components/Attendance/PunchInOut";
import EmployeeManageEmployee from "../Employee-Panel/components/ManageEmployee/index";
import EmployeeMyTeamList from "../Employee-Panel/components/EmployeeCreationMaster/EmployeeTable";
import EmployeeCourses from "../Employee-Panel/components/LMS_NEW/index";
import EmployeeModules from "../Employee-Panel/components/LMS_NEW/module";
import EmployeeLMS from "../Employee-Panel/components/LMS/index";
import EmployeeWeeklyRoster from "../Employee-Panel/components/Weekly-roster/index";
import EmployeePolicyCenter from "../Employee-Panel/components/Policy-Center/index";

//Admin
import AdminDash from "../containers/Admin-agent";
import AdminHome from "../Admin-Panel/components/Home/index";

//Hr Manager
import HRMangerDash from "../containers/HrManager-agent";
import HRMangerHome from "../HrManager-Panel/components/Home/index";
import JobApplicationForm from "../HrManager-Panel/components/JobApplication/index";

var jwt = require("jsonwebtoken");
const Routes = ({}) => {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }
  return (
    <BrowserRouter>
      <NotificationContainer />
      <MainLayout>
        <SnackBar />
        <Switch>
          <Route path="/" exact component={Login} />
          <Route
            component={() => {
              const token = SecureStorage.getItem("token");
              const decoded = jwt.decode(token);
              if (decoded?.user_group === "PayrollManager") {
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/payrollManager"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <PayrollDash>
                              <Route
                                exact
                                path="/dashboard/payrollManager"
                                component={PayrollHome}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/organizationcreation"
                                component={PayrollOrganizationCreation}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/other-details"
                                component={PayrollOtherDetails}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/attendancelist"
                                component={PayrollAttendanceList}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/view-attendance"
                                component={PayrollAttendanceViewDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/employee"
                                component={PayrollEmployeeForm}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/employee-master"
                                component={PayrollEmployeeBasic}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollManager/email-sent"
                                component={EmailTemplate}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/employee-list"
                                component={PayrollEmployeeList}
                              />

                              <Route
                                path="/dashboard/payrollmanager/tax-declaration-proffs"
                                component={PayrollTaxDeclarationProff}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/inbox"
                                component={PayrollInbox}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/payroll"
                                component={PayrollPayroll}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/holiday"
                                component={PayrollHoliday}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/salary-details"
                                component={PayrollEmployeeSalaryDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollManager/payslip"
                                component={PayrollPayslip}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/Attendancehome"
                                component={PayrollAttendancehome}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/Attendance-Master"
                                component={PayrollMyAttendance}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/income-tax-declaration"
                                component={PayrollIncomeTaxDeclaration}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/kra"
                                component={PayrollKRA}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/Calendar"
                                component={PayrollCalendar}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/bulk-uploader"
                                component={PayrollBulkUploader}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/bulk-leave-uploader"
                                component={PayrollLeaveBulkUploader}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollManager/request-center"
                                component={PayrollRequest}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/leave"
                                component={PayrollLeaveBalance}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollManager/export_file"
                                component={PayrollBulZipUploader}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollManager/update-BulkUpload"
                                component={PayrollUpdateBulkUpdate}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollManager/employee-Feedback"
                                component={PayrollEmployeeFeedback}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollManager/regularization"
                                component={PayrollRegularization}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollManager/manage-attendance"
                                component={PayrollManageAttendance}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollManager/manage-employee"
                                component={PayrollManageEmployee}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/punch-in-out"
                                component={PyrollPunchInOut}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/employee_location"
                                component={PayrollManageLocation}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/employee_report"
                                component={PayrollReportCard}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/password_reset"
                                component={PayrollPasswordReset}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollManager/weekly-roster"
                                component={PayRollWeekly}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/courses"
                                component={PayrollLMSCourses}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/modules"
                                component={PayrollLMSModules}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/questions"
                                component={PayrollLMSQuestions}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/designation"
                                component={PayrollManageDesignation}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollManager/policy-center"
                                component={PayrollPolicyCenter}
                              />

                              <Route
                                exact
                                path="/dashboard/payrollmanager/regulization_limit"
                                component={PayrollManageRegulizationLimit}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/assign-manager-hierarchy"
                                component={PayrollManagerMultiManagerTagging}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/daily-absence"
                                component={PayrollManagerDailyAbsence}
                              />
                              <Route
                                exact
                                path="/dashboard/payrollmanager/attendance-summary"
                                component={PayrollManagerAttendanceSummary}
                              />
                            </PayrollDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                );
              } else if (decoded?.user_group === "Employee") {
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/employee"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <EmployeeDash>
                              <Route
                                exact
                                path="/dashboard/employee"
                                component={EmployeeHome}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/Attendancehome"
                                component={EmployeeAttendancehome}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/leave"
                                component={EmployeeLeaveBalance}
                              />

                              <Route
                                exact
                                path="/dashboard/employee/weekly-roster"
                                component={EmployeeWeeklyRoster}
                              />

                              <Route
                                exact
                                path="/dashboard/employee/income-tax-declaration"
                                component={PayrollIncomeTaxDeclaration}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/Attendance-Master"
                                component={PayrollMyAttendance}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/inbox"
                                component={EmployeeInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/payslip"
                                component={EmployeePayslip}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/manage-attendance"
                                component={EmployeeManageAttendance}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/request-center"
                                component={EmployeeRequest}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/punch-in-out"
                                component={EmployeePunchInOut}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/manage-employee"
                                component={EmployeeManageEmployee}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/my-team"
                                component={EmployeeMyTeamList}
                              />

                              <Route
                                exact
                                path="/dashboard/employee/LMS"
                                component={EmployeeLMS}
                              />

                              <Route
                                exact
                                path="/dashboard/employee/employee-courses"
                                component={EmployeeCourses}
                              />

                              <Route
                                exact
                                path="/dashboard/employee/employee-courses/:id"
                                component={EmployeeModules}
                              />
                              <Route
                                exact
                                path="/dashboard/employee/employee_policy-center"
                                component={EmployeePolicyCenter}
                              />
                            </EmployeeDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                );
              } else if (decoded?.user_group === "Admin") {
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/admin"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <AdminDash>
                              <Route
                                exact
                                path="/dashboard/admin"
                                component={AdminHome}
                              />
                            </AdminDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                );
              }
              //   else if (decoded?.user_group === "HRManager") {
              //     return (
              //       <Switch>
              //         <Route
              //           path="/dashboard"
              //           exact
              //           component={() => <Dash path={path} query={query} />}
              //         />
              //         <Route
              //           path="/dashboard/hrmanager"
              //           exact={false}
              //           component={() => {
              //             return (
              //               <Switch>
              //                 <HRMangerDash>
              //                   <Route
              //                     exact
              //                     path="/dashboard/hrmanager"
              //                     component={HRMangerHome}
              //                   />
              //                   <Route
              //                     exact
              //                     path="/dashboard/hrmanager/holiday"
              //                     component={PayrollHoliday}
              //                   />
              //                   <Route
              //                     exact
              //                     path="/dashboard/hrmanager/Attendancehome"
              //                     component={PayrollAttendancehome}
              //                   />
              //                   <Route
              //                     exact
              //                     path="/dashboard/hrmanager/leave"
              //                     component={PayrollLeaveBalance}
              //                   />
              //                   <Route
              //                     exact
              //                     path="/dashboard/hrmanager/income-tax-declaration"
              //                     component={PayrollIncomeTaxDeclaration}
              //                   />

              //                   <Route
              //                     exact
              //                     path="/dashboard/hrmanager/inbox"
              //                     component={EmployeeInbox}
              //                   />
              //                   <Route
              //                     exact
              //                     path="/dashboard/hrmanager/JobApplication"
              //                     component={JobApplicationForm}
              //                   />
              //                 </HRMangerDash>
              //               </Switch>
              //             );
              //           }}
              //         />
              //         <Route path="*">
              //           <Redirect to="/" />
              //         </Route>
              //       </Switch>
              //     );
              //   }
              else {
                return <Redirect to="/" />;
              }
            }}
          />
        </Switch>
      </MainLayout>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.loginReducer.userDetails,
});

export default connect(mapStateToProps, { checkUser })(Routes);
