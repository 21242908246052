import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, TextField, Grid } from "@material-ui/core";
import {
    getManageAttendance,
    getEmployeeList,
    postRegularizationRequest,
    getEmployeeById,
    PostBulkRegularization,
    getUpdateBiometric
} from "../../../actions/loginActions";
import Loader from "../Loader2";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import UpdateAttendence from "../../../Admin-Panel/components/Attendance/UpdateAttendence/UpdateAttendence";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateAttendanceFilter } from "../../../helper/generate";
import { useHistory } from "react-router";
import Button from "@mui/material/Button";
import CustomDrawer from "../../../common/CustomDrawer";
import { Add as AddIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    TableFooter,
    TablePagination,
    Select,
    MenuItem,
    Card,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
import { Box } from "@mui/material";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "1rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
            borderRadius: "15px",
        },

        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgb(14, 63, 108)",
            color: theme.palette.common.white,
            fontSize: "14px",
            fontWeight: "bolder",
        },
        body: {
            fontSize: 14,
            fontWeight: "bolder",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getManageAttendance: any;
    manageAttendance: any;
    manageAttendanceLoading: any;
    employeesList: any;
    getEmployeeList: any;
    postRegularizationRequest: Function;
    getEmployeeById: any;
    employee_by_id: any;
    id: string;
    from_date: string;
    time: string;
    selectInDate: string;
    check_in: string;
    selectOutDate: string;
    check_out: string;
    regularization_category: string;
    comments: string;
    PostBulkRegularization: any;
    getUpdateBiometric: any;
    updateBiometric: any;
}
const Forms: React.FC<Props> = ({
    getManageAttendance,
    manageAttendance,
    manageAttendanceLoading,
    employeesList,
    getEmployeeList,
    postRegularizationRequest,
    getEmployeeById,
    employee_by_id,
    PostBulkRegularization,
    getUpdateBiometric,
    updateBiometric
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [page, setPage] = useState(0);
    const history = useHistory();
    const [name, setName] = useState<String>("");
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [employeeUUid, setEmployeeUuid] = useState("");
    const [employeeUUidForSubmit, setEmployeeUUidForSubmit] = useState("");
    const [employeeName, setEmployeeName] = useState("");
    const [employeeID, setEmployeeId] = useState("");
    const [regularizationCategory, setRegularizationCategory] =
        useState("work_from_home");
    const [durationtype, setDurationType] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [overlap, setOverlap] = useState("");
    const [reason, setReason] = useState("");
    const [attendance_from_date, setAttendance_from_date] = useState("");
    const [attendance_start_date, setAttendance_start_date] = useState<any>("");
    const [attendance_to_date, setAttendance_to_date] = useState("");
    const [openManageAttendanceModel, setOpenManageAttendanceModel] =
        useState<boolean>(false);
    const [openAttendence, setOpenAttendence] = useState<boolean>(false);

    const [attendenceData, setAttendenceData] = useState<Props[]>([]);
    const [addAttendence, setAddAttendence] = useState(false);
    const [requestLoader, setRequestLoader] = useState(false);
    const [sortedAttendance, setSortedAttendance] = useState<any>([]);
    const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedYesterday = yesterday.toISOString().split("T")[0];
    const todayDate = moment().format("YYYY-MM-DD");

    const getJune21st = () => {
        const today = new Date();
        const year = today.getFullYear();
        return new Date(year, 5, 22).toISOString().split("T")[0];
    };
    const june21st = getJune21st();

    useEffect(() => {
        getManageAttendance();
        getEmployeeById("");
        getEmployeeList("my_team=true");
    }, []);

    const handleFilter = () => {
        setSortedAttendance([]);
        const body: any = {
            employee_id: employeeUUid,
            start_date: attendance_start_date,
            end_date: attendance_to_date,
        };
        const url = generateAttendanceFilter(body).substring(2);
        getManageAttendance(`?${url}`);
        setPage(0);
    };

    useEffect(() => {
        if (
            employee_by_id &&
            employee_by_id?.results?.length > 0 &&
            employee_by_id?.results[0]?.is_manager === false
        ) {
            setEmployeeUuid(employee_by_id?.results[0]?.employee_id);
            setEmployeeUUidForSubmit(employee_by_id?.results[0]?.uuid)
        }
    }, [employee_by_id]);
    useEffect(() => {
        if (employee_by_id && employee_by_id?.results?.length > 0) {
            setEmployeeName(employee_by_id?.results[0]?.fullname);
            setEmployeeId(employee_by_id?.results[0]?.employee_id);
            setEmployeeUUidForSubmit(employee_by_id?.results[0]?.uuid)
        }
    }, [employee_by_id]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url =
                manageAttendance?.links && manageAttendance?.links?.next?.split("?")[1];
            getManageAttendance(url ? `?${url}` : "");
        } else if (newPage < page) {
            let url =
                manageAttendance?.links &&
                manageAttendance?.links?.previous?.split("?")[1];
            getManageAttendance(url ? `?${url}` : "");
        }
        setPage(newPage as number);
    };
    const handleSendRequest = async () => {
        setRequestLoader(true);
        await PostBulkRegularization(attendenceData);
        setAttendenceData([]);
        setOpenManageAttendanceModel(false);
        setRequestLoader(false);
    };

    const handleManageAttendance = (data: any) => {
        setOpenManageAttendanceModel(true);
        setFromDate(
            data?.date
                ? data?.date
                : ""
        );
        setFromTime(updatedTime(data?.check_in));
        setToTime(updatedTime(data?.check_out));
    };

    useEffect(() => {
        if (disableFilterBtn) {
            setTimeout(() => {
                setDisableFilterBtn(false);
            }, 10000)
        }
    }, [disableFilterBtn])

    const handleSubmit = async (e: any) => {
        setDisableFilterBtn(true);
        const timeFormat = "HH:mm";
        const checkInTime = moment(fromTime, timeFormat);
        const checkOutTime = moment(toTime, timeFormat);
        const duration = moment.duration(checkOutTime.diff(checkInTime));
        const hoursDifference = duration.asHours();
        e.preventDefault();
        const data = {
            regularization_category: regularizationCategory,
            from_date: fromDate + " 00:00:00",
            comments: reason,
            check_in: fromDate + "T" + fromTime,
            check_out: fromDate + "T" + toTime,
            date: fromDate,
            employee: employeeUUidForSubmit
        };

        if (hoursDifference < 2) {
            alert(
                "The difference between Check-in and Check-out must be at least 2 hours."
            );
        } else {
            await postRegularizationRequest(data);
            setOpenManageAttendanceModel(false);
            setRegularizationCategory("");
            setDurationType("");
            setFromDate("");
            setToDate("");
            setReason("");
        }
    };
    const handleRemoveAttendence = (data: any) => {
        const filterAttendenceData = attendenceData?.filter((v) => v?.id !== data);
        setAttendenceData(filterAttendenceData);
    };

    const updatedTime = (timeString: any) => {
        const dateTimeObj = new Date(timeString);
        const extraTime = 5.5 * 60 * 60 * 1000;

        let newDateTimeObj = new Date(dateTimeObj.getTime() + extraTime);
        let newTime = newDateTimeObj.toISOString().slice(11, 19);

        return newTime;
    };

    const [isManager, setIsManager] = useState<any>("");
    useEffect(() => {
        if (employee_by_id?.results && employee_by_id?.results?.length > 0) {
            setIsManager(employee_by_id?.results[0]?.is_manager === true);
        }
    }, [employee_by_id]);

    const handleReason = (e: any) => {
        const value = e.target.value;
        if (value.length === 0 || !/^[. ]/.test(value[0])) {
            setReason(value);
        }
    };

    const handlRefreshAttendance = async () => {
        getUpdateBiometric(`?employee_id=${employeeID}`)
        await alert("Your Attendance is Updated Successfully")
    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "rgb(28, 78, 128)",
                    padding: "0 35px",
                    borderRadius: "15px",
                    height: "50px",
                }}
            >
                <h4
                    style={{
                        fontWeight: "bold",
                        color: "#fff",
                        textAlign: "center",
                        margin: "auto",
                    }}
                >
                    Manage Attendance
                </h4>
            </div>
            {employee_by_id?.results &&
                employee_by_id?.results?.length > 0 &&
                employee_by_id?.results[0]?.is_manager === false && (
                    <div>
                        <Grid container spacing={3} style={{ marginTop: "15px" }}>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                    className="input"
                                    name="start_date"
                                    id="noTyping"
                                    type="date"
                                    label="Start Date"
                                    value={attendance_start_date}
                                    variant="outlined"
                                    inputProps={{ max: todayDate }}
                                    onChange={(e) =>
                                        String(e.target.value).length <= 10
                                            ? setAttendance_start_date(e.target.value as string)
                                            : ""
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                    className="input"
                                    name="end_date"
                                    id="noTyping"
                                    type="date"
                                    label="End Date"
                                    value={attendance_to_date}
                                    variant="outlined"
                                    inputProps={{ max: todayDate }}
                                    onChange={(e) =>
                                        String(e.target.value).length <= 10
                                            ? setAttendance_to_date(e.target.value as string)
                                            : ""
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleFilter}
                                    disabled={
                                        attendance_to_date === "" || attendance_start_date === ""
                                    }
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}

            <div>
                <div>
                    {isManager && (
                        <Grid
                            container
                            spacing={3}
                            style={{ marginTop: "15px", marginBottom: "10px" }}
                        >
                            <Grid item xs={12} sm={4} md={4}>
                                <Autocomplete
                                    onChange={(event, obj) => {
                                        if (obj) {
                                            setEmployeeUuid(obj.employee_id);
                                            setEmployeeUUidForSubmit(obj.uuid)
                                        } else {
                                            setEmployeeUuid("");
                                        }
                                    }}
                                    id="checkboxes-tags-demo"
                                    options={employeesList?.results || []}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        timer.current = setTimeout(() => {
                                            getEmployeeList(`my_team=true&name= ${newInputValue}`);
                                        }, 1000);
                                    }}
                                    getOptionLabel={(option: any) => {
                                        return option?.employee_id + " " + option.name;
                                    }}
                                    renderOption={(option: any) => {
                                        return (
                                            <p>
                                                <b style={{ color: "blue" }}>Employee Name:</b>
                                                <strong>
                                                    {option?.name.toString()}
                                                    <br />
                                                </strong>
                                                <b style={{ color: "red" }}>Employee Id:</b>
                                                {option?.employee_id?.toUpperCase()}
                                            </p>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="input"
                                            {...params}
                                            label="Search By Employee Code Or Name"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === "Enter") {
                                                        e.stopPropagation();
                                                    }
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} md={2}>
                                <TextField
                                    className="input"
                                    name="start_date"
                                    id="noTyping"
                                    type="date"
                                    label="Start Date"
                                    inputProps={{
                                        max: moment().format("DD-MM-YYYY"),
                                        min: moment().format("DD-MM-YYYY"),
                                    }}
                                    value={attendance_start_date}
                                    variant="outlined"
                                    onChange={(e) =>
                                        String(e.target.value).length <= 10
                                            ? setAttendance_start_date(e.target.value as string)
                                            : ""
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={2}>
                                <TextField
                                    className="input"
                                    name="end_date"
                                    id="noTyping"
                                    type="date"
                                    label="End Date"
                                    inputProps={{
                                        max: moment().format("DD-MM-YYYY"),
                                        min: moment().format("DD-MM-YYYY"),
                                    }}
                                    value={attendance_to_date}
                                    variant="outlined"
                                    onChange={(e) =>
                                        String(e.target.value).length <= 10
                                            ? setAttendance_to_date(e.target.value as string)
                                            : ""
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleFilter}
                                    style={{
                                        backgroundColor: "#1976d2",
                                        color: "#ffffff",
                                        borderRadius: "8px",
                                        padding: "8px 16px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "none",
                                        transition: "background-color 0.3s, box-shadow 0.3s",
                                        boxShadow: "none",
                                    }}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.backgroundColor = "#1565c0";
                                        e.currentTarget.style.boxShadow =
                                            "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.backgroundColor = "#1976d2";
                                        e.currentTarget.style.boxShadow = "none";
                                    }}
                                    startIcon={<SearchIcon />}
                                >
                                    Filter
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={3} md={2}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    startIcon={<RotateLeftIcon />}
                                    style={{
                                        backgroundColor: "#9c27b0",
                                        color: "#ffffff",
                                        borderRadius: "8px",
                                        padding: "8px 16px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "none",
                                        transition: "background-color 0.3s, box-shadow 0.3s",
                                        boxShadow: "none",
                                    }}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.backgroundColor = "#7b1fa2";
                                        e.currentTarget.style.boxShadow =
                                            "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.backgroundColor = "#9c27b0";
                                        e.currentTarget.style.boxShadow = "none";
                                    }}
                                    onClick={() =>
                                        history.push("/dashboard/employee/manage-attendance")
                                    }
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                padding: "12px",
                                marginTop: "12px",
                                border: "1px solid #ccc",
                                borderRadius: "6px",
                                backgroundColor: "#e3f2fd",
                                display: "inline-block",
                                fontSize: "15px",
                                color: "#333",
                            }}
                        >
                            <span style={{ color: "#c62828" }}>
                                📅 You can regularize your attendance using this{" "}
                                <strong>Regularize Attendance </strong>
                                Button
                            </span>
                            <br />
                        </div>
                        <Grid item>

                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#795548",
                                    color: "#fff",
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    fontFamily: "Arial, sans-serif",
                                    fontWeight: "bold",
                                    boxShadow: "0 4px 8px rgba(0, 128, 128, 0.2)",
                                    transition: "background-color 0.3s ease, transform 0.3s ease",
                                }}
                                onMouseEnter={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.backgroundColor = "#bcaaa4";
                                    target.style.transform = "scale(1.05)";
                                }}
                                onMouseLeave={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.backgroundColor = "#795548";
                                    target.style.transform = "scale(1)";
                                }}
                                onClick={() => handlRefreshAttendance()}
                            >
                                Refresh Attendance
                            </Button>
                        </Grid>
                        <Grid item>

                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#008080",
                                    color: "#fff",
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    fontFamily: "Arial, sans-serif",
                                    fontWeight: "bold",
                                    boxShadow: "0 4px 8px rgba(0, 128, 128, 0.2)",
                                    transition: "background-color 0.3s ease, transform 0.3s ease",
                                }}
                                onMouseEnter={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.backgroundColor = "#006666";
                                    target.style.transform = "scale(1.05)";
                                }}
                                onMouseLeave={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.backgroundColor = "#008080";
                                    target.style.transform = "scale(1)";
                                }}
                                onClick={() => setOpenAttendence(true)}
                            >
                                Regularize Attendance
                            </Button>
                        </Grid>
                    </div>
                </div>

                <TableContainer
                    className={classes.tableContainer}
                >
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Employee Name</StyledTableCell>
                                <StyledTableCell align="center">Code</StyledTableCell>
                                <StyledTableCell align="center">Date</StyledTableCell>
                                <StyledTableCell align="center">Clock-In</StyledTableCell>
                                <StyledTableCell align="center">Clock-Out</StyledTableCell>
                                <StyledTableCell align="center">Is Regularize</StyledTableCell>
                                <StyledTableCell align="center">
                                    Total Working Hours
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Punch In Location
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Punch Out Location
                                </StyledTableCell>
                                <StyledTableCell align="center">Work Location</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        {manageAttendanceLoading ? (
                            <Loader />
                        ) : (
                            <TableBody>
                                {manageAttendance &&
                                    manageAttendance?.results &&
                                    manageAttendance?.results?.length > 0 &&
                                    manageAttendance?.results?.map((data: any) => (
                                        <StyledTableRow key={data?.emploayee_code}>
                                            <StyledTableCell align="center">
                                                {data?.emploayee_code || "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.emploayee_name || "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.date
                                                    ? moment(data?.date).format("DD-MM-YYYY")
                                                    : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.check_in ? updatedTime(data?.check_in) : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.check_out ? updatedTime(data?.check_out) : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.is_regularize ? data?.is_regularize : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.total_hour !== null ? data.total_hour : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.punch_in_location !== null
                                                    ? data.punch_in_location
                                                    : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.punch_out_location !== null
                                                    ? data.punch_out_location
                                                    : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.work_location !== null
                                                    ? data.work_location
                                                    : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data?.status === 'P' ? "Present" :data.status==="L"?"Leave": data?.status === 'A' ? "Absent" : data.status === 'H' ? "Holiday" : data.status === 'W' ? "Week-off" : data.status==='HF'?"Half Day":data.status==='LA'?"Leave Absent":"NA"}
                                                {data?.is_week_off === true ? " (Week-off)":""}
                                                {(data.status !== "P" && data.status !== "L") && (
                                                    <AddCircleIcon
                                                        onClick={() => handleManageAttendance(data)}
                                                        style={{ marginLeft: "8px", color: "#007BFF" }}
                                                    />
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        )}

                        <TableFooter>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={5}
                                count={manageAttendance.count || 0}
                                rowsPerPage={manageAttendance.page_size}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </TableFooter>
                    </Table>
                </TableContainer>

                <CustomDrawer
                    drawerTitle={"REGULARIZATION REQUEST"}
                    open={openManageAttendanceModel}
                    toggleDrawer={() => setOpenManageAttendanceModel(false)}
                >
                    <Box>
                        <Card className="p-3 m-0">
                            <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                                justify="space-between"
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <p style={{ fontWeight: "bold" }}>Location</p>
                                    <Select
                                        className="input"
                                        name="action"
                                        variant="outlined"
                                        value={regularizationCategory}
                                        style={{ width: "100%" }}
                                        onChange={(e) =>
                                            setRegularizationCategory(e.target.value as string)
                                        }
                                    >
                                        <MenuItem value={"work_from_home"}>Work From Home</MenuItem>
                                        <MenuItem value={"work_from_office"}>
                                            Work From Office
                                        </MenuItem>
                                        <MenuItem value={"on_duty"}>On Duty</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <p style={{ fontWeight: "bold" }}> Date</p>
                                    <input
                                        className="input"
                                        name="fromDate"
                                        type="date"
                                        value={fromDate || ""}
                                        min={june21st}
                                        max={formattedYesterday}
                                        onChange={(e) => setFromDate(e.target.value)}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                            height: "40px",
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{ fontWeight: "bold" }}>Punch-In Time </p>

                                    <input
                                        className="input"
                                        name="fromTime"
                                        type="time"
                                        value={fromTime || ""}
                                        onChange={(e) => setFromTime(e.target.value)}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                            height: "40px",
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{ fontWeight: "bold" }}>Punch-Out Time </p>
                                    <input
                                        className="input"
                                        name="toTime"
                                        type="time"
                                        value={toTime || ""}
                                        onChange={(e) => setToTime(e.target.value)}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                            height: "40px",
                                        }}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <label
                                        style={{
                                            display: "block",
                                            fontWeight: "bold",
                                            marginBottom: "8px",
                                            fontSize: "16px",
                                            color: "#333",
                                        }}
                                    >
                                        Add Reason
                                        <textarea
                                            className="tex"
                                            value={reason}
                                            onChange={handleReason}
                                            style={{
                                                width: "100%",
                                                height: "100px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "vertical",
                                                outline: "none",
                                                transition: "border-color 0.3s",
                                            }}
                                            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
                                            onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                                        />
                                    </label>
                                </Grid>
                                <button
                                    style={{
                                        backgroundColor:
                                            fromDate === "" ||
                                                fromTime === "" ||
                                                toTime === "" ||
                                                reason === ""
                                                || disableFilterBtn
                                                ? "gray"
                                                : "rgb(14, 63, 108)",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "4px",
                                        padding: "12px 24px",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s, transform 0.3s",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        textTransform: "uppercase",
                                        letterSpacing: "1px",
                                    }}
                                    className="but"
                                    onClick={handleSubmit}
                                    disabled={
                                        fromDate === "" ||
                                        fromTime === "" ||
                                        toTime === "" ||
                                        reason === ""
                                        || disableFilterBtn
                                    }
                                    onMouseEnter={(e) => {
                                        const target = e.target as HTMLButtonElement;
                                        target.style.backgroundColor = "rgb(19, 90, 150)";
                                        target.style.transform = "scale(1.05)";
                                    }}
                                    onMouseLeave={(e) => {
                                        const target = e.target as HTMLButtonElement;
                                        target.style.backgroundColor = "rgb(14, 63, 108)";
                                        target.style.transform = "scale(1)";
                                    }}
                                >
                                    Apply
                                </button>
                            </Grid>
                        </Card>
                    </Box>
                </CustomDrawer>
            </div>

            <CustomDrawer
                open={openAttendence}
                toggleDrawer={() => setOpenAttendence(false)}
                drawerTitle={"Bulk Regularize"}
            >
                {/* Conditionally render the existing attendance data */}
                <span
                    style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        background: "linear-gradient(90deg, #ff7e5f, #feb47b)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        transition: "transform 0.3s ease",
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                    }}
                >

                    <span
                        style={{
                            color: "#607d8b",
                            fontWeight: "bold",
                            fontSize: "16px",
                            fontFamily: "'Roboto', sans-serif",
                            padding: "4px 8px",
                            borderBottom: "2px solid #607d8b",
                        }}
                    >
                        Employee Details: {employeeName} ({employeeID})
                    </span>
                </span>

                {attendenceData?.length > 0 && (
                    <div className="px-3">
                        {attendenceData.map((data, index) => (
                            <Card
                                key={index}
                                className="d-flex p-2 my-2 shadow border border-1 border-success align-items-center justify-content-between"
                            >
                                <div className="d-flex align-items-start  justify-content-center">
                                    <span className="p-0 m-0 text-secondary">
                                        <AccountBalanceWalletOutlinedIcon />
                                    </span>
                                    <div className="mx-4 p-0">
                                        <p className="p-0 m-0 ">
                                            <span
                                                className="text-dark  fs-2"
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {data.from_date} -
                                            </span>

                                            <span
                                                className="text-secondary "
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {"5:00 AM"}
                                            </span>
                                        </p>
                                        <p className="p-0 m-0 ">
                                            <span
                                                className="text-secondary "
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {data.check_in} -
                                            </span>
                                            <span
                                                className="text-secondary "
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {" " + data.check_out}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <IconButton onClick={() => handleRemoveAttendence(data?.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </Card>
                        ))}
                    </div>
                )}

                {/* Conditionally render the form to add new attendance */}
                {(addAttendence || attendenceData?.length === 0) && (
                    <UpdateAttendence
                        formData={(data: any) =>
                            setAttendenceData((prev) => [...prev, data])
                        }
                        handletoggle={() => setOpenAttendence(false)}
                        handleAddAttendence={() => setAddAttendence(false)}
                    />
                )}

                {/* Render additional UI elements if there is existing attendance data */}
                {attendenceData?.length > 0 && (
                    <>
                        {!addAttendence && (
                            <Box
                                onClick={() => setAddAttendence(true)}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "2px dotted #007bff",
                                    borderRadius: "8px",
                                    width: "90%",
                                    marginX: "auto",
                                    height: "100px",
                                    cursor: "pointer",
                                    position: "relative",
                                    marginTop: "1rem",
                                }}
                            >
                                <IconButton onClick={() => setAddAttendence(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        )}

                        <div className="col-5 my-4">
                            <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                type="submit"
                                disabled={addAttendence}
                                endIcon={requestLoader && <CircularProgress size="small" />}
                                onClick={() => handleSendRequest()}

                            // Assuming onSubmit function is handled elsewhere
                            >
                                Send Request
                            </Button>
                        </div>
                    </>
                )}
            </CustomDrawer>
        </main>
    );
};
const mapStateToProps = (state: any) => ({
    manageAttendance: state.loginReducer.manageAttendance,
    manageAttendanceLoading: state.loginReducer.manageAttendanceLoading,
    employeesList: state.loginReducer.employeesList,
    employee_by_id: state.loginReducer.employee_by_id,
    updateBiometric: state.loginReducer.updateBiometric,
});
export default connect(mapStateToProps, {
    getManageAttendance,
    getEmployeeList,
    postRegularizationRequest,
    getEmployeeById,
    PostBulkRegularization,
    getUpdateBiometric
})(Forms);
