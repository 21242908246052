import React from "react";
import "./index.sass";


interface Props {
  children: any;
}

const AdminDashboardRight: React.FC<Props> = ({ children }) => {

  return (
    <>
      {children && children}
    </>
  );
};


export default AdminDashboardRight;
