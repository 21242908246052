import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button } from "@mui/material";
import { sendEmployeeEmail } from "../../../actions/loginActions";
import { useDispatch } from "react-redux";
import axios, { Axios } from "axios";
import securestorage from "../../../config/SecureStorage";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "80%",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  csvUpload: {
    marginBottom: "20px",
  },
  uploadButton: {
    backgroundColor: "#4CAF50", // Green background for the button
    color: "#fff", // White text
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#45a049", // Darker green on hover
    },
  },
  previewContainer: {
    marginTop: "20px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  fileInput: {
    display: "none", // Hide the default file input
  },
}));

const EmailTemplate = () => {
  const classes = useStyles();
  const [editorData, setEditorData] = useState("");
  const [csvFileName, setCsvFileName] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadAttachment, setUploadAttachment] = useState(null);
  const [key, setkey] = useState(false);
  const dispatch = useDispatch();

  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    setUploadFile(file);
    if (file) {
      setCsvFileName(file.name);
    }
  };

  const submitFileEmployee = async () => {
    setIsButtonDisabled(true); // Disable the button while the request is being processed
    const formData = new FormData();
    formData.append("email_body", editorData);
    formData.append("file", uploadFile);
    formData.append("subject", emailSubject);
    formData.append("attachment", uploadAttachment);
  
    // Show confirmation dialog
    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm("Are you sure you want to send the email?");
    if (!isConfirmed) {
      setIsButtonDisabled(false); // Re-enable button if canceled
      return; // Exit the function if the user clicks "Cancel"
    }
  
    try {
      await sendEmployeeEmail(formData)(dispatch);
      setIsButtonDisabled(false);
      resetForm();
    } catch (error) {
      setIsButtonDisabled(false);
      console.error("Error sending email:", error);
      alert("Error sending email. Please try again.");
    }
  };
  

  const resetForm = () => {
    setEditorData(""); // Clear the CKEditor data
    setCsvFileName(""); // Clear the CSV file name
    setUploadFile(null); // Reset the file upload
    setEmailSubject("")
    setkey(!key)
  };

  class ApiUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }

    upload() {
      return this.loader.file.then(async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await axios.post(
            "https://apihrms.redcliffelabs.com/api/upload-to-s3/",
            formData,
            {
              headers: {
                Authorization: `Token ${securestorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          const imageUrl = response.data.url; // Adjust this based on the actual response format
          return {
            default: imageUrl,
          };
        } catch (error) {
          console.error("Image upload failed:", error);
          throw error;
        }
      });
    }

    abort() {
      // Optional: Implement if needed
    }
  }

  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new ApiUploadAdapter(loader);
    };
  }

  const handleAttachmentUpload = (event) => {
    const file = event.target.files[0];
    setUploadAttachment(file);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <h4
        style={{
          fontSize: "24px",
          fontWeight: "600",
          backgroundColor: "rgb(28, 78, 128)",
          color: "#fff",
          textAlign: "center",
          fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          padding: "10px",
          borderRadius: "15px",
          letterSpacing: "0.5px",
          textTransform: "capitalize",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        Create Email Template
      </h4>
      <div
        style={{
          backgroundColor: "#ffff",
          padding: "2rem",
          marginTop: "2rem",
          boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* CSV File Upload Section */}
        <div></div>
        <div className={classes.csvUpload}>
          <h2>Upload CSV File</h2>
          <label htmlFor="csv-file" className={classes.uploadButton}>
            Choose CSV File
          </label>
          <input
            id="csv-file"
            type="file"
            accept=".csv"
            className={classes.fileInput}
            onChange={handleCsvUpload}
          />
          {csvFileName && (
            <p style={{ marginTop: "8px", color: "#4caf50" ,fontSize:'1rem'  }}>
              CSV File Uploaded: {csvFileName}
            </p>
          )}
          <Button
            variant="contained"
            className="mx-2"
            href="https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/None/64876a6b-3024-472a-af8f-a9d767406c33.csv"
          >
            Download Sample
          </Button>
        </div>

        <div className="p-0  my-2 col-12">
          <h4>Subject</h4>
          <textarea
            className="col-12 p-2 border border-gray-300 rounded-md resize-none"
            rows="4"
            placeholder="Enter subject here"
            onChange={(e)=>setEmailSubject(e.target.value)}
          />
        </div>

        {/* Email Editor Section */}
        <div className="editor-container">
          <h4>Editor</h4>
           <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorData(data);
            }}
            config={{
              extraPlugins: [CustomUploadAdapterPlugin], // Add custom upload adapter plugin
              toolbar: [
                "bold",
                "italic",
                "underline",
                "|",
                "link",
                "bulletedList",
                "numberedList",
                "|",
                "insertTable",
                "imageUpload",
                "undo",
                "redo",
              ],
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "imageStyle:full",
                  "imageStyle:side",
                ],
              },
            }}
          /> 
        </div>
        <div className="my-2">
          <div style={{
            display:'flex',
            alignItems:'center',
            justifyContent:'start',
            gap:4
          }}>
          <div >
        
          <label htmlFor="attachment-file" className={classes.uploadButton}>
            Choose File
          </label>
          <input
            id="attachment-file"
            type="file"
            key={key}
            className={classes.fileInput}
            onChange={handleAttachmentUpload}
          />

            </div>
            <p className="m-0 p-0" style={{
              fontWeight:'500',
              marginLeft:'1rem'
            }}>Upload  Attachment</p>
          </div>
          {uploadAttachment && (
            <p style={{ marginTop: "8px", color: "#4caf50" ,fontSize:'1rem' }}>
              Attachment Uploaded: {uploadAttachment.name}
            </p>
          )}
        </div>

        <div className="my-4 d-flex gap-2" style={{ gap: 5 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={submitFileEmployee}
            disabled={isButtonDisabled || !uploadFile || !editorData}
          >
            Send Email
          </Button>
          <Button variant="outlined" color="secondary" onClick={resetForm}>
            Reset
          </Button>
        </div>
      </div>
    </main>
  );
};

export default EmailTemplate;
