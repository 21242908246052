import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { TextField, Grid } from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import {
  createEmployee,
  getEmployeeList,
  editEmployeeById,
  getEmployeeDepartment,
  getOrganizationsList,
  getEmployeeSubDept,
  getEmployeeStateName,
  getShiftList,
  getDesignationtList,
  getLocationList,
  getReportingManager
} from "../../../actions/loginActions";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import "./index.sass";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    input: {
      padding: "1px",
    },
  })
);

interface Props {
  createEmployee: any;
  getEmployeeList: any;
  employeesList: any;
  editEmployeeById: any;
  getEmployeeDepartment: Function;
  departmentNames: any;
  getOrganizationsList: any;
  organizationsList: any;
  getEmployeeSubDept: any;
  subDeptName: any;
  getEmployeeStateName: any;
  employeeStateName: any;
  getShiftList: any;
  shiftList: any;
  getDesignationtList: any;
  designationList: any;
  locationList: any;
  getLocationList: any;
  getReportingManager: any;
  reporting_manager: any;
}
const CreatePartnerPage: React.FC<Props> = ({
  createEmployee,
  getEmployeeList,
  employeesList,
  editEmployeeById,
  getEmployeeDepartment,
  departmentNames,
  getOrganizationsList,
  organizationsList,
  getEmployeeSubDept,
  subDeptName,
  getEmployeeStateName,
  employeeStateName,
  getShiftList,
  shiftList,
  getDesignationtList,
  designationList,
  locationList,
  getLocationList,
  getReportingManager,
  reporting_manager,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const history = useHistory();
  const [employeeNumber, setEmployeeNumber] = useState<any>("");
  const [employeeId, setEmployeeId] = useState<any>("");
  const [employeeName, setEmployeeName] = useState<any>("");
  const [employeePhoneNumber, setEmployeePhoneNumber] = useState<any>("");
  const [employeeEmergencyContactNumber, setEmployeeEmergencyContactNumber] =
    useState<any>("");
  const [employeeEmail, setEmployeeEmail] = useState<any>("");
  const [employeeGender, setEmployeeGender] = useState<any>("");
  const [employeeDesignation, setEmployeeDesignation] = useState<any>("");
  const [addressOfEmployee, setAddressOfEmployee] = useState<any>("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [ifscCode, setIfscCode] = React.useState("");
  const [esiNumber, setEsiNumber] = React.useState("");
  const [pfNumber, setPfNumber] = React.useState("");
  const [aadhar, setAadhar] = useState<any>("");
  const [panNumber, setPanNumber] = useState<any>("");
  const [employeeType, setEmployeeType] = useState<any>("");
  const [employeeStatus, setEmployeeStatus] = useState<any>("");
  const [joinedOn, setJoinedOn] = useState<any>("");
  const [currEmployeeType, setCurrEmployeeType] = useState<any>("");
  const [userGroup, setUserGroup] = useState<any>("");
  const [employeeTitle, setEmployeeTitle] = useState<any>("");
  const [employeeLocation, setEmployeeLocation] = useState<any>("");
  const [locationName, setLocationName] = useState<any>("");
  const [employeeAttendanceMode, setEmployeeAttendanceMode] = useState<any>("");
  const [bankName, setBankName] = useState<any>("");
  const [bandName, setbandName] = useState<any>("");
  const [domain, setDomainName] = useState<any>("");
  const [employeeCurrentState, setEmployeeCurrentState] = useState<any>("");
  const [uanNumber, setUanNumber] = useState<any>("");
  const [employeeDept, setEmployeeDept] = useState<any>("");
  const [employeeFatherName, setEmployeeFatherName] = useState<any>("");
  const [employeeDob, setEmployeeDob] = useState<any>("");
  const [employeeManagerName, setEmployeeManagerName] = useState<any>("");
  const [managerEmpNumber, setManagerEmpNumber] = useState<any>("");
  const [managerEmailId, setManagerEmailId] = useState<any>("");
  const [departmentHead, setDepartmentHead] = useState<any>("");
  const [company, setCompany] = useState<any>("");
  const [subDepartment, setSubDepartment] = useState<any>("");
  const [employeeUUID, setEmployeeUUID] = useState<any>("");
  const [createBtn, setCreateBtn] = useState<boolean>(false);
  const [shiftListData, setshiftListData] = useState<any>("");
  const [checkedWay, setCheckedWay] = useState<any>("");
  const [checkeedMrr, setCheckedMrr] = useState<any>("");
  const [WeekOffDays, setWeekOffDays] = useState<any>("");
  const [confirmedDate, setConfirmedDate] = useState<any>("");
  const [probationDate, setProbationDate] = useState<any>("");
  const [inactiveDate, setInactiveDate] = useState<any>("");



  const handleCreateEmployee = async () => {
    const body = {
      username: employeeEmail,
      employee_id: employeeId,
      employee_designation: employeeDesignation,
      manager: employeeManagerName,
      manager_emp_number: managerEmpNumber,
      manager_email_id: managerEmailId,
      fullname: employeeName,
      name: employeeName,
      gender: employeeGender,
      emergency_contact_number: employeeEmergencyContactNumber,
      phonenumber: employeePhoneNumber,
      email: employeeEmail,
      employee_mode: employeeType,
      bank_account_number: accountNumber,
      ifsc_code: ifscCode,
      esi_number: esiNumber,
      pf_number: pfNumber,
      pan_number: panNumber,
      aadhar_number: aadhar,
      location: employeeLocation,
      employee_title: employeeTitle,
      attendance_mode: employeeAttendanceMode,
      band: bandName,
      domain: domain,
      employee_current_state: employeeCurrentState,
      uan_number: uanNumber,
      status: employeeStatus.toLowerCase(),
      bank_name: bankName,
      department: employeeDept,
      date_of_joining:joinedOn,
      department_head: departmentHead,
      father_name: employeeFatherName,
      date_of_birth: employeeDob,
      organization: company,
      sub_department: subDepartment,
      shift: shiftListData,
      is_manager: checkedWay === "yes" ? true : false,
      usergroup: userGroup,
      is_mrr: checkeedMrr,
      weekoff_days: WeekOffDays,
      probation_date: probationDate,
      confirmed_date: confirmedDate,
      weekly_off_days: WeekOffDays,
      inactive_date: inactiveDate
    };
    if (probationDate === "") {
      delete body.probation_date;
    }

    if (confirmedDate === "") {
      delete body.confirmed_date;
    }

    if (WeekOffDays === "") {
      delete body.weekly_off_days;
    }

    if (WeekOffDays === "") {
      delete body.weekoff_days;
    }


    await createEmployee(body);
    history.push("/dashboard/payrollManager/employee-master");
  };
  useEffect(() => {
    getEmployeeList(`get_all=true`);
    getOrganizationsList();
    getEmployeeSubDept("");
    getEmployeeStateName("");
    getEmployeeDepartment();
    getShiftList();
    getDesignationtList();
    getLocationList();
    getReportingManager("is_manager=true");
  }, []);
  const isDisabled =
    employeeName === "" &&
    employeeId === "" &&
    employeeDept === "" &&
    employeeEmail === "" &&
    employeePhoneNumber === "" &&
    employeeId === "" &&
    employeeDesignation === "" &&
    employeeLocation === "" &&
    employeeDob === "" &&
    employeeManagerName === "" &&
    employeeAttendanceMode === "" &&
    domain === "" &&
    bankName === "" &&
    panNumber === "" &&
    company === "" &&
    userGroup === "";

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      
       <h4
        style={{
          fontSize: "24px",
          fontWeight: "600",
          backgroundColor: "rgb(28, 78, 128)",
          color: "#fff",
          textAlign: "center",
          fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          padding: "10px",
          borderRadius: "15px",
          letterSpacing: "0.5px",
          textTransform: "capitalize",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        Employee Master
      </h4>

      <Grid
        container
        spacing={3}
        direction="row"
        style={{ alignItems: "left" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="employee_id"
            label="Employee Id"
            value={employeeId}
            onChange={(e) => {
              setEmployeeId(e.target.value as String);
            }}
            inputProps={{ maxlength: 10 }}
            variant="outlined"
            type="text"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="employee_name"
            type="text"
            label="Employee Name "
            value={employeeName}
            required
            variant="outlined"
            onChange={(e) => setEmployeeName(e.target.value as string)}
            inputProps={{ maxLength: 100 }}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="ticket_status"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setEmployeeStatus(obj?.value);
              }
            }}
            options={[
              {
                text: "Confirmed",
                value: "confirmed",
              },
              {
                text: "Probation",
                value: "probation",
              },
              {
                text: "In Active",
                value: "inactive",
              },
            ]}
            blurOnSelect
            aria-required
            freeSolo
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return employeeStatus.includes(option.value);
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Employee Status "
                variant="outlined"
                value={employeeStatus}
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        {employeeStatus === "probation" &&
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              className="input"
              name="probation_date"
              label="Probation Date"
              type="date"
              value={(probationDate && probationDate.toString()) || ""}
              onChange={(e) => setProbationDate(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "100%", margin: "0" }}
              inputProps={{
                max: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                  ? `0${new Date().getMonth() + 1}`
                  : new Date().getMonth() + 1
                  }-${new Date().getDate() < 10
                    ? `0${new Date().getDate()}`
                    : new Date().getDate()
                  }`,
              }}
            />
          </Grid>}
        {employeeStatus === "inactive" &&
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              className="input"
              name="inactive_dare"
              label="Inactive Date"
              type="date"
              value={(inactiveDate && inactiveDate.toString()) || ""}
              onChange={(e) => setInactiveDate(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "100%", margin: "0" }}
              inputProps={{
                max: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                  ? `0${new Date().getMonth() + 1}`
                  : new Date().getMonth() + 1
                  }-${new Date().getDate() < 10
                    ? `0${new Date().getDate()}`
                    : new Date().getDate()
                  }`,
              }}
            />
          </Grid>}
        {employeeStatus === "confirmed" &&
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              className="input"
              name="confirmed_date"
              label="Confirmed Date"
              type="date"
              value={(confirmedDate && confirmedDate.toString()) || ""}
              onChange={(e) => setConfirmedDate(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "100%", margin: "0" }}
              inputProps={{
                max: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                  ? `0${new Date().getMonth() + 1}`
                  : new Date().getMonth() + 1
                  }-${new Date().getDate() < 10
                    ? `0${new Date().getDate()}`
                    : new Date().getDate()
                  }`,
              }}
            />
          </Grid>}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="collection_date"
            label="joined on"
            type="date"
            value={(joinedOn && joinedOn.toString()) || ""}
            onChange={(e) => setJoinedOn(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            inputProps={{
              max: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
                }-${new Date().getDate() < 10
                  ? `0${new Date().getDate()}`
                  : new Date().getDate()
                }`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="employee_type"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCurrEmployeeType(obj?.value);
              }
            }}
            options={[
              {
                text: "Consultant ",
                value: "consultant",
              },
              {
                text: "Contarctor",
                value: "contarctor",
              },
              {
                text: "Employee",
                value: "Employee",
              },
              {
                text: "Trainee",
                value: "trainee",
              },
            ]}
            blurOnSelect
            aria-required
            freeSolo
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return currEmployeeType.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Employee Type "
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="gender"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setEmployeeTitle(obj?.value);
              }
            }}
            options={[
              {
                text: "MR",
                value: "mr",
              },
              {
                text: "MRS",
                value: "mrs",
              },
              {
                text: "Dr",
                value: "dr",
              },
              {
                text: "MS",
                value: "ms",
              },
              {
                text: "MISS",
                value: "miss",
              },
              {
                text: "Dr",
                value: "dr",
              },
              {
                text: "Professor",
                value: "prof",
              },
              {
                text: "Advocate",
                value: "adv",
              },
            ]}
            blurOnSelect
            aria-required
            freeSolo
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return employeeTitle.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Employee Title "
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setEmployeeDesignation(obj.id);
              }
            }}
            id="department-name"
            options={designationList || []}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getDesignationtList(`name=${newInputValue}`);
              }, 1000);
            }}
            getOptionLabel={(option: any) => {
              return option.designation;
            }}
            renderOption={(option: any) => {
              return option?.designation;
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Designation "
                variant="outlined"
                required
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setEmployeeLocation(obj.id);
              }
            }}
            id="sub-department-name"
            options={locationList || []}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getLocationList(`name=${newInputValue}`);
              }, 1000);
            }}
            getOptionLabel={(option: any) => {
              return option.location;
            }}
            renderOption={(option: any) => {
              return option.location;
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Employee Location"
                required
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setEmployeeCurrentState(obj.id);
              }
            }}
            id="sub-department-name"
            options={employeeStateName || []}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getEmployeeStateName(`name=${newInputValue}`);
              }, 1000);
            }}
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            renderOption={(option: any) => {
              return option?.name;
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="State Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="email"
            type="email"
            value={employeeEmail}
            className="input"
            label="Email Id"
            variant="outlined"
            onChange={(e) =>
              setEmployeeEmail(
                e.target.value
                  .trimStart()
                  .replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "")
              )
            }
            inputProps={{ maxLength: 50 }}
            style={{ width: "100%" }}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            required
            className="input"
            name="employee_phone_number"
            type="text"
            label="Contact Number"
            value={employeePhoneNumber}
            onChange={(e) => {
              setEmployeePhoneNumber(e.target.value as String);
            }}
            inputProps={{ maxlength: 10 }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="email"
            type="text"
            value={employeeFatherName}
            className="input"
            label="Father Name"
            variant="outlined"
            onChange={(e) => setEmployeeFatherName(e.target.value as string)}
            inputProps={{ maxLength: 50 }}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="gender"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setEmployeeGender(obj?.value);
              }
            }}
            options={[
              {
                text: "Male",
                value: "male",
              },
              {
                text: "Female",
                value: "female",
              },
              {
                text: "Other",
                value: "other",
              },
            ]}
            blurOnSelect
            aria-required
            freeSolo
            getOptionLabel={(option: any) => option.text}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Employee Gender "
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="employee dob"
            label="Employee Dob"
            type="date"
            value={(employeeDob && employeeDob.toString()) || ""}
            onChange={(e) => setEmployeeDob(e.target.value)}
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            inputProps={{
              max: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
                }-${new Date().getDate() < 10
                  ? `0${new Date().getDate()}`
                  : new Date().getDate()
                }`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setEmployeeManagerName(obj?.uuid);
                setManagerEmailId(obj?.email);
                setManagerEmpNumber(obj?.phonenumber);
              }
            }}
            id="checkboxes-tags-demo"
            options={reporting_manager?.results || []}
            getOptionLabel={(option: any) => {
              return option?.employee_id + " " + option.name;
            }}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                newInputValue && getReportingManager(`is_manager=true`);
              }, 1000);
            }}
            renderOption={(option: any) => {
              return (
                <p>
                  <b style={{ color: "blue" }}>Employee Name:</b>
                  <strong>
                    {option?.name.toString()}
                    <br />
                  </strong>
                  <b style={{ color: "red" }}>Employee Id:</b>
                  {option?.employee_id?.toUpperCase()}
                </p>
              );
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Manager Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="manager_emp_number"
            type="text"
            label=" Manager Employee ID"
            value={managerEmpNumber}
            onChange={(e) => {
              setManagerEmpNumber(e.target.value as String);
            }}
            inputProps={{ maxlength: 10 }}
            variant="outlined"
            fullWidth
            style={{ margin: "0", width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="manager_email_id"
            type="text"
            label="Manager Email id"
            value={managerEmailId}
            onChange={(e) => setManagerEmailId(e.target.value as string)}
            variant="outlined"
            style={{ margin: "0", width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setEmployeeDept(obj.id);
                setDepartmentHead(obj.department_head_name);
              }
            }}
            id="department-name"
            options={departmentNames || []}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getEmployeeDepartment(`name=${newInputValue}`);
              }, 1000);
            }}
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            renderOption={(option: any) => {
              return option?.name;
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Department Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="dept_head"
            type="text"
            label="Department Head "
            value={departmentHead}
            onChange={(e) => setDepartmentHead(e.target.value as string)}
            variant="outlined"
            style={{ margin: "0", width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setSubDepartment(obj.id);
              }
            }}
            id="sub-department-name"
            options={subDeptName || []}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getEmployeeSubDept(`name=${newInputValue}`);
              }, 1000);
            }}
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            renderOption={(option: any) => {
              return option?.name;
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Sub Department"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="employee_emergency_number"
            type="text"
            label="Emergency Contact Number"
            value={employeeEmergencyContactNumber}
            onChange={(e) => {
              setEmployeeEmergencyContactNumber(e.target.value as String);
            }}
            inputProps={{ maxlength: 10 }}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="uan_number"
            type="text"
            value={uanNumber}
            className="input"
            label="UAN Number"
            onChange={(e) => {
              setUanNumber(e.target.value as String);
            }}
            inputProps={{ maxlength: 15 }}
            variant="outlined"
            style={{ margin: "0", width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="gender"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setEmployeeAttendanceMode(obj?.value);
              }
            }}
            options={[
              {
                text: "Geo-fencing",
                value: "geo-fencing",
              },
              {
                text: "Flexible",
                value: "flexible",
              },
              {
                text: "Biometric",
                value: "biometric",
              },
              {
                text: "Manual",
                value: "manual",
              },
            ]}
            blurOnSelect
            aria-required
            freeSolo
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return employeeAttendanceMode.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Attendance Mode"
                variant="outlined"
                required
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="gender"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setDomainName(obj?.value);
              }
            }}
            options={[
              {
                text: "Corporate",
                value: "corporate",
              },
              {
                text: "Diagnostics",
                value: "diagnostics",
              },
              {
                text: "Genomics",
                value: "genomics",
              },
              {
                text: "Rediology",
                value: "rediology",
              },
              {
                text: "Redo",
                value: "redo",
              },
              {
                text: "Research",
                value: "research",
              },
              {
                text: "Strategic Alliance",
                value: "strategic_alliance",
              },
            ]}
            blurOnSelect
            aria-required
            freeSolo
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return domain.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Domain Name"
                required
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="employee_bank"
            type="text"
            label="Bank Name  "
            value={bankName}
            onChange={(e) => setBankName(e.target.value as string)}
            variant="outlined"
            style={{ margin: "0", width: "100%" }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="accountNumber"
            type="text"
            value={accountNumber}
            className="input"
            label="Bank Account Number"
            onChange={(e) => {
              setAccountNumber(e.target.value);
            }}
            inputProps={{ maxlength: 20 }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="IFSC"
            type="text"
            value={ifscCode}
            className="input"
            label="IFSC Code"
            onChange={(e) => {
              setIfscCode(e.target.value);
            }}
            inputProps={{ maxlength: 15 }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="pan_number"
            type="text"
            label="Pan Number"
            value={panNumber}
            onChange={(e) => {
              setPanNumber(e.target.value as String);
            }}
            inputProps={{ maxlength: 15 }}
            variant="outlined"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            className="input"
            name="employee_bond"
            type="text"
            label="Band Name  "
            value={bandName}
            onChange={(e) => setbandName(e.target.value as string)}
            variant="outlined"
            style={{ margin: "0", width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setCompany(obj.id);
              }
            }}
            id="department-name"
            options={organizationsList || []}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getOrganizationsList(`name=${newInputValue}`);
              }, 1000);
            }}
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            renderOption={(option: any) => {
              return option?.name;
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Company Name"
                variant="outlined"
                required
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="esi_number"
            type="text"
            value={esiNumber}
            className="input"
            label="ESI Number"
            onChange={(e) => {
              setEsiNumber(e.target.value);
            }}
            inputProps={{ maxlength: 15 }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setshiftListData(obj.id);
              }
            }}
            id="shift-list"
            options={shiftList || []}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getShiftList(`name=${newInputValue}`);
              }, 1000);
            }}
            getOptionLabel={(option: any) => {
              return (
                option.start_time +
                "To" +
                option.end_time
              );
            }}
            renderOption={(option: any) => {
              return (
                <p>
                  <b style={{ color: "blue" }}> Shift :</b>
                  <strong>
                    {option?.start_time} To {option.end_time}
                  </strong>
                  <br />
                  <b style={{ color: "red" }}>Total Hours:</b>
                  {option?.total_hour}
                </p>
              );
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Shift Time"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="employee_type"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setUserGroup(obj?.value);
              }
            }}
            options={[
              {
                text: "AdminManager ",
                value: "AdminManager",
              },
              {
                text: "PayrollManager",
                value: "PayrollManager",
              },
              {
                text: "HRManager",
                value: "HRManager",
              },
              {
                text: "Employee",
                value: "Employee",
              },
            ]}
            blurOnSelect
            aria-required
            freeSolo
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return userGroup.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                required
                className="input"
                {...params}
                label="User Group "
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="ticket_status"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.value);
              }
              setWeekOffDays(sourceIds);
            }}
            options={[
              {
                text: "Monday",
                value: "Monday",
              },
              {
                text: "Tuesday",
                value: "Tuesday",
              },
              {
                text: "Wednesday",
                value: "Wednesday",
              },
              {
                text: "Thursday",
                value: "Thursday",
              },

              {
                text: "Friday",
                value: "Friday",
              },
              {
                text: "Saturday",
                value: "Saturday",
              },
              {
                text: "Sunday",
                value: "Sunday",
              },
            ]}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return WeekOffDays.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Week Off "
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid item xs={12} sm={6} md={3}>
            <div
              className="checkList"
              style={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{
                    fontSize: "large",
                    fontWeight: "bolder",
                    textAlign: "left",
                    marginLeft: "10px",
                  }}
                >
                  Manager:
                </FormLabel>
                <div style={{ display: "flex", paddingLeft: "20px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="yes"
                        checked={checkedWay === "yes"}
                        onChange={(e) => {
                          !e.target.checked
                            ? setCheckedWay("")
                            : setCheckedWay(e.target.value);
                        }}
                      />
                    }
                    label="Yes"
                    style={{ marginRight: "20px" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="no"
                        checked={checkedWay === "no"}
                        onChange={(e) => {
                          !e.target.checked
                            ? setCheckedWay("")
                            : setCheckedWay(e.target.value);
                        }}
                      />
                    }
                    label="No"
                  />
                </div>
              </FormControl>
              <FormControl component="fieldset" style={{ marginLeft: "65px" }}>
                <FormLabel
                  component="legend"
                  style={{
                    fontSize: "large",
                    fontWeight: "bolder",
                    textAlign: "left",
                    marginLeft: "10px",
                  }}
                >
                  MRR:
                </FormLabel>
                <div style={{ display: "flex", paddingLeft: "20px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="true"
                        checked={checkeedMrr === "true"}
                        onChange={(e) => {
                          !e.target.checked
                            ? setCheckedMrr("")
                            : setCheckedMrr(e.target.value);
                        }}
                      />
                    }
                    label="Yes"
                    style={{ marginRight: "20px" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="false"
                        checked={checkeedMrr === "false"}
                        onChange={(e) => {
                          !e.target.checked
                            ? setCheckedMrr("")
                            : setCheckedMrr(e.target.value);
                        }}
                      />
                    }
                    label="No"
                  />
                </div>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} direction="row" style={{ marginTop: "10px" }}>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            style={{
              backgroundColor: isDisabled
                ? "rgb(150, 150, 150)"
                : "rgb(9, 69, 72)",
              color: isDisabled ? "rgba(255, 255, 255, 0.7)" : "#fff",
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.6 : 1,
              transition:
                "background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease",
            }}
            onClick={handleCreateEmployee}
            fullWidth
            startIcon={<AddCircleIcon />}
            disabled={isDisabled}
          >
            Create Employee
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#0e3f6c" }}
            fullWidth
            onClick={() =>
              history.push("/dashboard/payrollManager/employee-master")
            }
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  departmentNames: state.loginReducer.departmentNames,
  organizationsList: state.loginReducer.organizationsList,
  subDeptName: state.loginReducer.subDeptName,
  employeeStateName: state.loginReducer.employeeStateName,
  shiftList: state.loginReducer.shiftList,
  designationList: state.loginReducer.designationList,
  locationList: state.loginReducer.locationList,
  reporting_manager: state.loginReducer.reporting_manager,
});
export default connect(mapStateToProps, {
  createEmployee,
  getEmployeeList,
  editEmployeeById,
  getEmployeeDepartment,
  getOrganizationsList,
  getEmployeeSubDept,
  getEmployeeStateName,
  getShiftList,
  getDesignationtList,
  getLocationList,
  getReportingManager
})(CreatePartnerPage);
