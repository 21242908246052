import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, TextField, Grid } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import CreateIcon from "@mui/icons-material/Create";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import { withStyles } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Tabs, Tab } from "@mui/material";
import {
    getDesignationtList,
    updateDesignation,
    getEmployeeDepartment,
    updateDepartment,
    getEmployeeALlData
} from "../../../actions/loginActions"
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateDesignationFilter } from "../../../helper/generate";
import Loader from "../Loader2";
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TablePagination,
    TableFooter,
    TableCell,
    TableRow,
} from "@material-ui/core";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    borderRadius: "15px",
    border: "3px solid green",
    boxShadow: 24,
    p: 2,
};

const style1 = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    border: "2px solid #2d148a",
    bgcolor: "#fafafa",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};
const ButtonDesign = styled(Button)({
    background: "#2d148a",
    color: "#fff",
    fontWeight: "bold",
    padding: "10px 20px",
    borderRadius: "25px",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
    transition: "all 0.3s ease",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    "&:hover": {
        transform: "scale(1.03)",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
        background: "radial-gradient(circle, rgba(131,58,180,1) 29%, rgba(112,49,187,1) 62%, rgba(83,28,193,1) 97%)",
    },
    "& .MuiButton-startIcon": {
        transition: "transform 0.3s ease",
    },
    "&:hover .MuiButton-startIcon": {
        transform: "rotate(15deg)",
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            padding: "-1rem 2rem 2rem 2rem",
            width: "100%",
            borderRadius: "10px",
        },
        paper1: {
            backgroundColor: theme.palette.background.paper,
            border: "3px solid green",
            borderRadius: "5px",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: "1200px",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        container: {
            position: "sticky",
            marginTop: 30,
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        centerIt: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        closeicon: {
            backgroundColor: "#d1ff33",
            width: '1.5em',
            height: '1.5em',
            borderRadius: "50%",
            border: "1px solid #c6ff00",
            cursor: "pointer",
            color: "#1a237e"
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#1C4E80",
            color: theme.palette.common.white,
            fontSize: "16px",
        },
        body: {
            fontSize: 14,
            padding: "5px",
            textTransform: "capitalize",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
interface Props {
    loading: boolean;
    getDesignationtList: any;
    designationList: any;
    updateDesignation: any;
    getEmployeeDepartment: any;
    departmentNames: any;
    updateDepartment: any;
    getEmployeeALlData: any;
    employee_all_data: any;
}
const Forms: React.FC<Props> = ({
    loading,
    getDesignationtList,
    designationList,
    updateDesignation,
    getEmployeeDepartment,
    departmentNames,
    updateDepartment,
    getEmployeeALlData,
    employee_all_data,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [filterName, setFilterName] = useState("");
    const [dataId, setDataId] = useState<any>("");
    const [open1, setOpen1] = React.useState(false);
    const [editButton, setEditButton] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [open2, setOpen2] = React.useState(false);
    const [departmentName, setDepartmentName] = useState<string>("");
    const [deaprtmentId, setDepartmentId] = useState<any>();
    const handleClose = () => setOpen1(false);
    const handleOpen = () => setOpen1(true);
    const handleOpenCreate = () => setOpen(true);
    const [employeeId, setEmployeeId] = useState<any>("");
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const handleOpenDepartment = (data: any) => {
        setOpen2(true);
        setEditButton(true);
        setDepartmentName(data?.name)
        setDepartmentId(data?.id);

    };
    const handleOpenDepartmentCreate = () => setOpen2(true);
    const handleClose1 = () => {
        setOpen(false);
        setEditButton(false);
        setName("");
        setDataId("");
        setOpen2(false);
        setDepartmentId("");
        setDepartmentName("");

    };
    const handleOpen1 = (data: any) => {
        setOpen(true);
        setEditButton(true);
        setName(data?.designation)
        setDataId(data?.id);
    };

    useEffect(() => {
        getDesignationtList(`get_all=true`);
        getEmployeeDepartment();
        getEmployeeALlData()
    }, []);

    const handleSubmitCentre = async (e: any) => {
        e.preventDefault();
        const formData = {
            name: name,
            head: employeeId
        };
        const formDataEdit = {
            name: name,
            id: dataId,
            head: employeeId
        };

        if (editButton) {
            await updateDesignation(formDataEdit);
        } else {
            await updateDesignation(formData);
        }
        getDesignationtList(`get_all=true`)
        setEditButton(false);
        setOpen(false);
        setName("");
        setDataId("");

    };

    const handleDepartmentChange = async (e: any) => {
        e.preventDefault();

        if (employeeId === "") {
            alert("Please select a department head");
            return;
        }
        const formDataEdit = {
            name: departmentName,
            id: deaprtmentId,
            head: employeeId
        };
        if (deaprtmentId === "") {
            delete (formDataEdit.id);
        }
        await updateDepartment(formDataEdit);
        getEmployeeDepartment();
        setEditButton(false);
        setOpen2(false);
        setName("");
        setDataId("");

    };

    const filterBookings = () => {
        const body: any = {
            name: filterName,
        };
        const url = generateDesignationFilter(body).substring(2);
        if (value === 0) {
            getDesignationtList(`${url}&get_all=true`);
        } else {
            getEmployeeDepartment(`${url}`);
        }
        setOpen1(false);
        setFilterName("");
        setPage(0);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url =
                designationList?.links && designationList?.links?.next?.split("?")[1];
            getDesignationtList(url ? `${url}` : "");
        } else if (newPage < page) {
            let url =
                designationList?.links && designationList?.links?.previous?.split("?")[1];
            getDesignationtList(url ? `${url}` : "");
        }
        setPage(newPage as number);
    };

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid
                container
                spacing={1}
                direction="row"
                style={{ alignItems: "left" }}

            >
                <Grid item xs={12} sm={3} md={8}>
                    <AppBar
                        position="static"
                        elevation={0}
                        style={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            borderRadius: "12px",
                            overflow: "hidden",
                            width: "75%",
                            marginLeft: "0px",
                        }}
                    >
                        <Tabs
                            variant="fullWidth"
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            style={{
                                justifyContent: "space-around",
                                width: "100%",
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                borderRadius: "12px",
                                margin: "10px 0",
                            }}
                            TabIndicatorProps={{
                                style: {
                                    height: "4px",
                                    borderRadius: "4px",
                                    backgroundColor: "#007bff",
                                    transition: "all 0.3s ease",
                                },
                            }}
                        >
                            <Tab
                                style={{
                                    backgroundColor: "#ffffff",
                                    color: "#495057",
                                    fontWeight: "bold",
                                    transition: "all 0.3s ease",
                                    padding: "16px 24px",
                                    margin: "0 8px",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                }}
                                label="Designation"
                                {...a11yProps(0)}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "#e9ecef",
                                        color: "#007bff",
                                    },
                                    "&.Mui-selected": {
                                        backgroundColor: "#007bff",
                                        color: "#ffffff",
                                    },
                                }}
                            />
                            <Tab
                                style={{
                                    backgroundColor: "#ffffff",
                                    color: "#495057",
                                    fontWeight: "bold",
                                    transition: "all 0.3s ease",
                                    padding: "16px 24px",
                                    margin: "0 8px",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                }}
                                label="Department"
                                {...a11yProps(1)}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "#e9ecef",
                                        color: "#007bff",
                                    },
                                    "&.Mui-selected": {
                                        backgroundColor: "#007bff",
                                        color: "#ffffff",
                                    },
                                }}
                            />
                        </Tabs>
                    </AppBar>
                </Grid>
                {value === 0 ? (<>
                    <Grid item xs={12} sm={3} md={4}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "8px",
                                borderRadius: "10px",
                                padding: "10px",
                                alignItems: "right",
                            }}

                        >
                            <ButtonDesign
                                variant="contained"
                                startIcon={<CreateIcon />}
                                onClick={handleOpenCreate}
                                style={{ width: "228px", backgroundColor: "#784118" }}
                            >
                                Create
                            </ButtonDesign>
                            <ButtonDesign
                                variant="contained"
                                startIcon={<FilterAltIcon />}
                                onClick={handleOpen}
                                style={{ width: "228px", backgroundColor: "#914db1" }}
                            >
                                Filter
                            </ButtonDesign>
                        </div>
                    </Grid>
                </>) : (<>
                    <Grid item xs={12} sm={3} md={4}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "8px",
                                borderRadius: "10px",
                                padding: "10px",
                                alignItems: "right",
                            }}

                        >
                            <ButtonDesign
                                variant="contained"
                                startIcon={<CreateIcon />}
                                onClick={handleOpenDepartmentCreate}
                                style={{ width: "228px" }}
                            >
                                Create
                            </ButtonDesign>
                            <ButtonDesign
                                variant="contained"
                                startIcon={<FilterAltIcon />}
                                onClick={handleOpen}
                                style={{ width: "228px" }}
                            >
                                Filter
                            </ButtonDesign>
                        </div>
                    </Grid>
                </>)}

            </Grid>
            <hr style={{ borderBottom: "3px solid #007BFF" }} />
            {value === 0 ? (<>
                <TableContainer
                    className={classes.container}
                    style={{ maxHeight: "540px" }}
                    component={Paper}
                >
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">
                                    <b>Sr. No.</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <b>Name</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <b>Edit</b>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <Loader />
                            ) : (
                                designationList &&
                                designationList.results &&
                                designationList.count > 0 &&
                                designationList.results.map((data: any, index: any) => (
                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data.designation || "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleOpen1(data)}
                                            >
                                                <Edit />
                                            </Button>
                                        </StyledTableCell>

                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={3}
                                count={designationList.count || 0}
                                rowsPerPage={designationList.page_size}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </TableFooter>
                    </Table>
                </TableContainer>
            </>) : (<>
                <TableContainer
                    className={classes.container}
                    style={{ maxHeight: "540px" }}
                    component={Paper}
                >
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">
                                    <b>Sr. No.</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <b>Department Name</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <b>Department Head Name</b>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <b>Edit</b>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <Loader />
                            ) : (
                                departmentNames &&
                                // departmentNames.results &&
                                // departmentNames.count > 0 &&
                                departmentNames.map((data: any, index: any) => (
                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data.name || "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data.department_head_name || "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleOpenDepartment(data)}
                                            >
                                                <Edit />
                                            </Button>
                                        </StyledTableCell>

                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                        {/* <TableFooter>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            colSpan={3}
                            count={designationList.count || 0}
                            rowsPerPage={designationList.page_size}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </TableFooter> */}
                    </Table>
                </TableContainer>

            </>)}

            <Modal
                open={open1}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style1}>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <h3 style={{ color: "#0e3f6c" }}> Filter Designation </h3>
                        <CloseIcon
                            onClick={handleClose}
                            className={classes.closeicon}
                        />
                    </div>


                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        style={{ alignItems: "left" }}
                    >

                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                className="input"
                                name="name"
                                type="text"
                                label="Name"
                                value={filterName}
                                variant="outlined"
                                onChange={(e) => setFilterName(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Button
                                className="buttonPrimary"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={filterBookings}
                            >
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Button
                                className="buttonSecondary"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() =>
                                    history.push("/dashboard/payrollmanager/designation")
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
            <Modal
                open={open}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3 style={{ color: "#0e3f6c" }}>  Create New Designation </h3>
                    <form onSubmit={handleSubmitCentre}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    name="Name"
                                    className="input"
                                    type="text"
                                    value={name}
                                    required={!editButton}
                                    label="Name"
                                    variant="outlined"
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ width: "100%" }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={loading}
                                    type="submit"
                                >
                                    {editButton ? "Update" : "Create"}
                                </Button>

                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3 style={{ color: "#0e3f6c" }}>

                        {editButton ? " Edit New Department" : "Create New Department"}
                    </h3>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                name="Name"
                                className="input"
                                type="text"
                                value={departmentName}
                                required={!editButton}
                                label="Department Name"
                                variant="outlined"
                                onChange={(e) => setDepartmentName(e.target.value)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                onChange={(event, obj) => {
                                    if (obj) {
                                        setEmployeeId(obj.employee_id);
                                    } else {
                                        setEmployeeId("");
                                    }
                                }}
                                id="checkboxes-tags-demo"
                                options={employee_all_data?.results || []}
                                getOptionLabel={(option: any) => {
                                    return option?.employee_id + " " + option.name;
                                }}
                                renderOption={(option: any) => {
                                    return (
                                        <p>
                                            <b style={{ color: "blue" }}>Employee Name:</b>
                                            <strong>
                                                {option?.name.toString()}
                                                <br />
                                            </strong>
                                            <b style={{ color: "red" }}>Employee Id:</b>
                                            {option?.employee_id?.toUpperCase()}
                                        </p>
                                    );
                                }}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        newInputValue &&
                                            getEmployeeALlData(`employee_id=${newInputValue}`);
                                    }, 1000);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="input"
                                        {...params}
                                        label="Department Head Name"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                        inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (e) => {
                                                if (e.key === "Enter") {
                                                    e.stopPropagation();
                                                }
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                onClick={handleDepartmentChange}
                            >
                                {editButton ? "Update" : "Create"}
                            </Button>

                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </main>
    );
};
const mapStateToProps = (state: any) =>
({
    designationList: state.loginReducer.designationList,
    departmentNames: state.loginReducer.departmentNames,
    employee_all_data: state.loginReducer.employee_all_data,
});
export default connect(mapStateToProps, {
    getDesignationtList,
    updateDepartment,
    updateDesignation,
    getEmployeeDepartment,
    getEmployeeALlData
})(Forms);