const isSelected = (field) => {
  if (
    field === "all" ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};

const isSelectedNum = (field) => {
  if (
    field === 0 ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};


export const generateLeaveFilter = (body) => {
  const { employee_id, date } = body;
  const name_filter = isSelected(employee_id)
    ? `&employee_id=${employee_id}`
    : "";
  const date_filter = isSelected(date) ? `&leave_date=${date}` : "";
  const url = `?${name_filter}${date_filter}`;
  return url;
};
export const generateEmployeeFilter = (body) => {
  const { employee_id, department, manager,employee_status ,start_date,end_date,employee,active_employee,location,employee_designation} = body;
  const name_filter = isSelected(employee_id)
    ? `&employee_id=${employee_id}`
    : "";
  const name_employee_status = isSelected(employee_status)
    ? `&employee_status=${employee_status}`
    : "";
  const name_Is_active = isSelected(active_employee)
    ? `&active_employee=${active_employee}`
    : "";
  const employee_department = isSelected(department) ? `&department=${department}` : "";
  const employee_manager = isSelected(manager) ? `&manager=${manager}` : "";
  const employee_start_date = isSelected(start_date) ? `&start_date=${start_date}` : "";
  const employee_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const employee_Data = isSelected(employee) ? `&employee=${employee}` : "";
  const employee_location = isSelected(location) ? `&location=${location}` : "";
  const employee_employee_designation = isSelected(employee_designation) ? `&employee_designation=${employee_designation}` : "";
  const url = `?${name_filter}${employee_department}${employee_manager}${name_employee_status}${employee_start_date}${employee_end_date}${employee_Data}${name_Is_active}${employee_location}${employee_employee_designation}`;
  return url;
};

export const generateUrlRegularze = (body) => {
  const {
    employeeUUid,
    reportingManager,
    employeeDept,
    fromDate,
    toDate,
  } = body;

  const employee = isSelected(employeeUUid)
    ? `&employee=${employeeUUid}`
    : "";
  const ReportingManager = isSelected(reportingManager)
    ? `&manager=${reportingManager}`
    : "";
  const EmployeeDepartment = isSelected(employeeDept)
    ? `&department=${employeeDept}`
    : "";
  const FromDate = isSelected(fromDate)
    ? `&from_date=${fromDate}`
    : "";
  const ToDate = isSelected(toDate)
    ? `&to_date=${toDate}`
    : "";

  const url = `${employee}${ReportingManager}${EmployeeDepartment}${FromDate}${ToDate}`
  return url;
}
export const generateAttendanceFilter = (body) => {
  const { employee_id, start_date, end_date, department,employee_type } = body;

  const employee_name = isSelected(employee_id)
    ? `&employee_id=${employee_id}`
    : "";
  const employee_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const employee_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";

  const employee_department = isSelected(department)
    ? `&department=${department}`
    : "";

  const name_filter = isSelected(employee_id)
    ? `&employee_id=${employee_id}&start_date=${start_date}&end_date=${end_date}`
    : "";
  const name_employee_type = isSelected(employee_type)
    ? `&employee_type=${employee_type}`
    : "";
  const url = `?${employee_name}${employee_start_date}${employee_end_date}${employee_department}${name_employee_type}`;
  return url;
};

export const generateLmsFilter = (body) => {
  const { name} = body;
  const nameFilter = isSelected(name) ? `&name=${name}` : "";
  const url = `?${nameFilter}`;
  return url;
}

export const generateDesignationFilter = (body) => {
  const { name} = body;
  const nameFilter = isSelected(name) ? `&name=${name}` : "";
  const url = `?${nameFilter}`;
  return url;
}

export const generateLmsModulesFilter = (body) => {
   const { moduleId , name,  courseName,  status , days, department} = body;
   const moduleIdFilter = isSelected(moduleId) ? `&module_id=${moduleId}` : "";
   const nameFilter = isSelected(name) ? `&module_name=${name}` : "";
   const courseNameFilter = isSelected(courseName) ? `&course_name=${courseName}` : "";
   const statusFiler = isSelected(status) ? `&module_status=${status}` : "";
   const daysFilter = isSelected(days) ? `&days=${days}` : "";
   const departmentFilter = isSelected(department) ? `&department=${department}` : "";
   const url = `${moduleIdFilter}${nameFilter}${statusFiler}${daysFilter}${departmentFilter}${courseNameFilter}`;
   return url;
   
}

export const generateLmsCourseFilter = (body) => {
  const { courseId, courseName , startDate, endDate, courseStatus} = body;
  const courseIdFilter = isSelected(courseId) ? `&id=${courseId}` : "";
  const courseFilter = isSelected(courseName) ? `&name=${courseName}` : "";
  const startFilter = isSelected(startDate) ? `&start_date=${startDate}` : "";
  const endFilter = isSelected(endDate) ? `&end_date=${endDate}` : "";
  const statusFilter = isSelected(courseStatus) ? `&course_status=${courseStatus}` : "";
  const url = `${courseIdFilter}${courseFilter}${startFilter}${endFilter}${statusFilter}`;
  return url;
}

export const generateUserFilter = (body) => {
  const { employee_id, start_date, end_date } = body;
  const employeeId = isSelected(employee_id) ? `&employee_id=${employee_id}` : "";
  const start_joining_date = isSelected(start_date) ? `&start_date=${start_date}` : "";
  const end_joining_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const url = `${employeeId}${start_joining_date}${end_joining_date}`;
  return url;
}
