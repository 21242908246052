import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  makeStyles,
} from "@material-ui/core";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LockImage from "./lock (1).png";
import { Theme, createStyles } from "@material-ui/core/styles";
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import { AppBar, Tabs, Tab } from "@mui/material";
import CardOverflow from '@mui/joy/CardOverflow';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import Chip from "@mui/material/Chip";
import { getCreateModule } from "../../../actions/loginActions";
import AttemptModule from "./AttemptModule";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getLmsCourses } from "../../actions/EmployeeActions";
import Certificate from "./certificate";
import createModule from "../LMS/createModule";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #b435a3 ",
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      opacity: "0.8",
      width: "1400px",
      height: "auto%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
  })
);

function a11yProps(index: any) {
  return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
  };
}


interface Props {
  getCreateModule: Function,
    createModuleData:any;
    getLmsCourses:Function,
    lmsCourses:any,

    
}

const LmsModules: React.FC<Props> = ({
  getCreateModule,
    createModuleData,
    getLmsCourses,
    lmsCourses,

}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [openModule, setOpenModule] = useState(false);
  const [myModule, setMyModule] = useState<any>();
  const [value, setValue] = React.useState(0);
  const [lockedModules, setLockedModules] = useState<any>([]);
  const [unLockedModules, setUnlockedModules] = useState<any>([]);
  const { id } = useParams<any>(); 

  useEffect(()=>{
     if(location?.state !== undefined){
      setValue(location.state.selectedTab);
     }
  },[location.state])

  useEffect(()=>{
    getCreateModule(`?course_id=${id}&module_status=published`);
    getLmsCourses(`?id=${id}`);
  },[]); 

  useEffect(() => {
    if (createModuleData && createModuleData.count > 0 && value === 0) {
      const pendingModules =
        createModuleData &&
        createModuleData?.results?.filter(
          (module: any) => module.completion_status !== "Completed"
        );
      
      setLockedModules(pendingModules);
    }else if(createModuleData && createModuleData.count > 0 && value === 1){
      const completedModules =  createModuleData &&
      createModuleData?.results?.filter(
        (module: any) => module.completion_status === "Completed"
      );

      setUnlockedModules(completedModules)
    }
  }, [createModuleData, value]);



  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
};


 
  
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="left"
        alignItems="center"
        style={{ alignItems: "center" }}
      >
        <Grid item xs={4} sm={4} md={4}>
          <Button
            variant="soft"
            color="primary"
            onClick={()=> history.push("/dashboard/employee/employee-courses")}
            size="sm"
            startDecorator={<ArrowBackIcon />}
            >
           Back to Courses
          </Button>
        </Grid>
      <Grid item xs={4} sm={4} md={4}>
      <h2
        style={{
          fontWeight: "bold",
          color: "rgb(28, 78, 128)",
          textAlign: "center",
        }}
      >
        My Modules
      </h2>
      </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ alignItems: "center" }}
      >
       <Grid item xs={12} sm={12} md={12}>
       <AppBar
            position="static"
            elevation={0}
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
              borderRadius: "12px",
              overflow: "hidden",
              width: "75%",
              margin: "auto",
            }}
          >
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              style={{
                justifyContent: "space-around",
                width: "100%",
                backgroundColor: "#ffffff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
                margin: "10px 0",
              }}
              TabIndicatorProps={{
                style: {
                  height: "4px",
                  borderRadius: "4px",
                  backgroundColor: "#007bff",
                  transition: "all 0.3s ease",
                },
              }}
            >
              <Tab
                style={{
                  backgroundColor: "#ffffff",
                  color: "#495057",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                  padding: "16px 24px",
                  margin: "0 8px",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                label="Pending"
                {...a11yProps(0)}
                sx={{
                  "&:hover": {
                    backgroundColor: "#e9ecef",
                    color: "#007bff",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#007bff",
                    color: "#ffffff",
                  },
                }}
              />
              <Tab
                style={{
                  backgroundColor: "#ffffff",
                  color: "#495057",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                  padding: "16px 24px",
                  margin: "0 8px",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                label="Completed"
                {...a11yProps(1)}
                sx={{
                  "&:hover": {
                    backgroundColor: "#e9ecef",
                    color: "#007bff",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#007bff",
                    color: "#ffffff",
                  },
                }}
              />
            </Tabs>
          </AppBar>
       </Grid>
       {
        value === 0 ? <Grid>
         {lockedModules && lockedModules?.length > 0 ? lockedModules?.map((moduleData:any, index:number)=>(
            <div style={{ position: "relative", display: "inline-block", margin:"15px" }}>
            {
                index > 0 && <img
                src={LockImage}
                style={{
                  position: "absolute",
                  top: "30%",
                  left: "35%",
                  zIndex: 10,
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  padding: "5px",
                }}
                width={100}
                height={100}
                alt=""
              />
            }
            <Card
              variant="outlined"
              sx={{
                width: 320,
                filter: index > 0 ? "blur(1.5px)" : "",
              }}
            >
              <CardOverflow>
              <AspectRatio ratio="2">
                <img
                  src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
                  srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
                  loading="lazy"
                  alt=""
                />
                <Chip
                  label={moduleData.completion_status}
                  size="small"
                  variant="outlined"
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: moduleData?.completion_status === "Pending" ? "#ff9a00" : moduleData?.completion_status === "InProgress" ? "rgb(64 145 64)" : "#2196F3",
                    color: "#fff",
                    zIndex: 5,
                  }}
                />
              </AspectRatio>
            </CardOverflow>
              <CardContent>
                <Typography level="title-md">
                  Module-{index + 1} {moduleData.name}
                </Typography>
              </CardContent>
              <Button
                variant="solid"
                size="md"
                aria-label="Explore Bahamas Islands"
                color="primary"
                sx={{
                  ml: "auto",
                  alignSelf: "center",
                  fontWeight: 600,
                  color:"#fff",
                  width: "100%",
                  border: moduleData.score_by_user !== "Fail" ? "1px solid black" : "",
                }}
                onClick={()=>{
                    setOpenModule(true)
                    setMyModule(moduleData)
                }}
                disabled={index > 0}

              >
                 { moduleData.completion_status === "Pending" ? "Start Module" : moduleData?.completion_status === "InProgress" ? "Resume" : "Completed"}
              </Button>
              <CardOverflow
                variant="soft"
                sx={{ bgcolor: "background.level1" }}
              >
                <Divider inset="context" />
                <CardContent
                  orientation="horizontal"
                  style={{ margin: "auto" }}
                >
                  <Typography
                    level="body-xs"
                    textColor="text.secondary"
                    sx={{ fontWeight: "md" }}
                  >
                   Passing Score: {moduleData.minimum_score} marks
                  </Typography>
                  <Divider orientation="vertical" />
                  <Typography
                    level="body-xs"
                    textColor="text.secondary"
                    sx={{ fontWeight: "md" }}
                  >
                    Total Question: {moduleData.assignment_question_module.length} 
                  </Typography>
                </CardContent>
              </CardOverflow>
            </Card>
          </div>
        )) : <div style={{ display:"flex", justifyContent:"center", alignItems:"center", marginTop:"50px", fontSize:"20px", fontWeight:"bold"}}> No Modules Found</div>
       }
        </Grid> : <Grid>
        {unLockedModules && unLockedModules?.length > 0 ? unLockedModules?.map((moduleData:any, index:number)=>(
            <div style={{ position: "relative", display: "inline-block", margin:"15px" }}>
            <Card
              variant="outlined"
              sx={{
                width: 320
              }}
            >
              <CardOverflow>
              <AspectRatio ratio="2">
                <img
                  src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
                  srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
                  loading="lazy"
                  alt=""
                />
                <Chip
                  label={"Completed"}
                  size="small"
                  variant="outlined"
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: moduleData?.score_by_user === "Fail" ? "#ff9a00" : "rgb(64 145 64)",
                    color: "#fff",
                    zIndex: 5,
                  }}
                />
              </AspectRatio>
            </CardOverflow>
              <CardContent>
                <Typography level="title-md">
                  Module-{index + 1} {moduleData.name}
                </Typography>
              </CardContent>
              <Button
                variant="solid"
                size="md"
                aria-label="Explore Bahamas Islands"
                color="primary"
                sx={{
                  ml: "auto",
                  alignSelf: "center",
                  fontWeight: 600,
                  color:"#fff",
                  width: "100%",
                  border:  "1px solid black",
                }}
              >
               { "Completed" }
              </Button>
              <CardOverflow
                variant="soft"
                sx={{ bgcolor: "background.level1" }}
              >
                <Divider inset="context" />
                <CardContent
                  orientation="horizontal"
                  style={{ margin: "auto" }}
                >
                  <Typography
                    level="body-xs"
                    textColor="text.secondary"
                    sx={{ fontWeight: "md" }}
                  >
                   Passing Score: {moduleData.minimum_score} marks
                  </Typography>
                  <Divider orientation="vertical" />
                  <Typography
                    level="body-xs"
                    textColor="text.secondary"
                    sx={{ fontWeight: "md" }}
                  >
                    Total Question: {moduleData.assignment_question_module.length} 
                  </Typography>
                </CardContent>
              </CardOverflow>
            </Card>
          </div>
        )) : <div style={{ display:"flex", justifyContent:"center", alignItems:"center", marginTop:"50px", fontSize:"20px", fontWeight:"bold"}}> No Modules Found</div>
       }
        </Grid>
       }
    
      </Grid>
      {
        openModule && <AttemptModule
        open={openModule}
        setOpen={setOpenModule} 
        myModule={myModule}  
        id={id}     
        />
      }

    </main>
  );
};

const mapStateToProps = (state: any) => ({
  createModuleData: state.loginReducer.createModuleData,
  lmsCourses: state.EmployeeReducer.lmsCourses,
});

export default connect(mapStateToProps, {
    getCreateModule,
    getLmsCourses,
})(LmsModules);
