import React, { useEffect, useState } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getModuleLogs, getCoursesLogs } from "../../actions/PayrollActions";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import Stack from "@mui/material/Stack";
import "./index.sass";
import module from "../../../Employee-Panel/components/LMS_NEW/module";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
}));

type ModalProps = {
  openModal: boolean;
  setopenModal: Function;
  courseId:any;
  moduleId:any;
  getModuleLogs:any;
  moduleLogs:any;
  getCoursesLogs:any;
  courseLogs:any;
};

const CommentsModal: React.FC<ModalProps> = ({
  openModal,
  setopenModal,
  courseId,
  moduleId,
  getModuleLogs,
  moduleLogs,
  getCoursesLogs,
  courseLogs,
}) => {
  const classes = useStyles();
  const [logs, setLogs] = useState<any>([]); 
  const [maxHeight, setMaxHeight] = React.useState<any>(300);

  const handleClose = () => {
    setopenModal(false);
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(()=>{
   if(moduleId !== ""){
     getModuleLogs(`?id=${moduleId}`)
   }else if(courseId !== ""){
    getCoursesLogs(`?id=${courseId}`)
   }
  },[moduleId , courseId])

  useEffect(()=>{
    if(moduleId !== "" && moduleLogs && moduleLogs.length > 0){
     setLogs(moduleLogs);
    }else if(courseId !== "" && courseLogs && courseLogs.length > 0){
     setLogs(courseLogs);
    }
  },[logs, moduleId, courseId, moduleLogs, courseLogs]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }


  return (
    <div style={{margin: "10px"}}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper} style={{margin: "10px"}}>
            <h6
              style={{
                marginBottom: "10px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
              }}
              id="transition-modal-title"
            >
              Comment History
              {Number(maxHeight) === Number(300) ? (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  {windowSize.innerWidth && windowSize.innerWidth <= 425 ? (
                    ""
                  ) : (
                    <ZoomOutMapIcon
                      fontSize="large"
                      onClick={() =>
                        Number(maxHeight) === Number(600)
                          ? setMaxHeight(Number(600))
                          : setMaxHeight(Number(600))
                      }
                    />
                  )}
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomInMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(300)
                        ? setMaxHeight(Number(300))
                        : setMaxHeight(Number(300))
                    }
                  />
                </Stack>
              )}
              {/* style={{ height: `${maxHeight}px` }} */}
            </h6>
            <div
              id="transition-modal-description"
              className="modal-form-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: `${maxHeight}px`,
              }}
            >
              <div
                className="comment-history"
                style={{ height: `${maxHeight}px` }}
              >
                {
                  logs && logs.map((comment:any)=>(
                    <p>
                       Created at: {comment?.created_at.substring(0, 10)}, 
                       Updated at: {comment?.updated_at.substring(0, 10)},
                       {comment?.comment}
                    </p>
                  ))
                }   
              </div>
             
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
   moduleLogs:state.PayrollReducer.moduleLogs,
   courseLogs:state.PayrollReducer.courseLogs,
});

export default connect(mapStateToProps, {
  getModuleLogs, getCoursesLogs
})(CommentsModal);
