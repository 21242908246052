import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Theme, createStyles } from "@material-ui/core/styles";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import Certificate from "./certificate";
import LockImage from "./lock (1).png";
import Chip from "@mui/material/Chip";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import { Grid,  TextField, InputAdornment } from "@mui/material";
import { getLmsCourses } from "../../actions/EmployeeActions";
import { AppBar, Tabs, Tab } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #b435a3 ",
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      opacity: "0.8",
      width: "1400px",
      height: "auto%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
  })
);

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  getLmsCourses: Function;
  lmsCourses: any;
  employeeData: any;
}

const LmsCourses: React.FC<Props> = ({
  getLmsCourses,
  lmsCourses,
  employeeData,
}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [openCertificate, setOpenCertificate] = useState<any>(false);
  const [lockedCourses, setLockedCourses] =  useState<any>([]);
  const [unLockedCourses, setUnLockedCourses] =  useState<any>([]);
  const [courseName, setCourseName] = useState<any>("");
  
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };


  function calculateDaysBetweenDates(date1: string, date2: string): string {
    if (!date1 || !date2) {
      return "N/A"; // Handle missing dates
    }

    const startDate = moment(date1, "YYYY-MM-DD", true); 
    const endDate = moment(date2, "YYYY-MM-DD", true);

    if (!startDate.isValid() || !endDate.isValid()) {
      return "N/A"; 
    }

    const daysDifference = endDate.diff(startDate, "days");
    return daysDifference >= 0 ? daysDifference.toString() : "Expired";
  }


  useEffect(() => {
    const todayDate = moment().format("YYYY-MM-DD");
    getLmsCourses(`?start_date=${todayDate}`);
  }, []);

  useEffect(()=>{
    if(lmsCourses && lmsCourses.count > 0 && value === 0){
       const filteredCourses = lmsCourses && lmsCourses?.results.filter((course:any)=> course.completion_status !== "Completed");
       setLockedCourses(filteredCourses)
    }else if(lmsCourses && lmsCourses.count > 0 && value === 1){
       const filterCompletedCourses = lmsCourses && lmsCourses?.results.filter((course:any)=> course.completion_status === "Completed");
       setUnLockedCourses(filterCompletedCourses);
    }
 },[lmsCourses, value])

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        style={{
          fontWeight: "bold",
          color: "rgb(28, 78, 128)",
          textAlign: "center",
        }}
      >
        My Courses
      </h2>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          variant="outlined"
          placeholder="Search..."
          onChange={(e: any) => getLmsCourses(`?name=${e.target.value}`)}
          sx={{
            width: "400px",
            marginBottom: "22px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "50px",
              "& fieldset": {
                borderColor: "rgb(28, 78, 128)",
              },
              "&:hover fieldset": {
                borderColor: "darkblue",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div> */}
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ alignItems: "center" }}
      >
       <Grid item xs={12} sm={12} md={12}>
          <AppBar
            position="static"
            elevation={0}
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
              borderRadius: "12px",
              overflow: "hidden",
              width: "75%",
              margin: "auto",
            }}
          >
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              style={{
                justifyContent: "space-around",
                width: "100%",
                backgroundColor: "#ffffff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
                margin: "10px 0",
              }}
              TabIndicatorProps={{
                style: {
                  height: "4px",
                  borderRadius: "4px",
                  backgroundColor: "#007bff",
                  transition: "all 0.3s ease",
                },
              }}
            >
              <Tab
                style={{
                  backgroundColor: "#ffffff",
                  color: "#495057",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                  padding: "16px 24px",
                  margin: "0 8px",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                label="Pending"
                {...a11yProps(0)}
                sx={{
                  "&:hover": {
                    backgroundColor: "#e9ecef",
                    color: "#007bff",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#007bff",
                    color: "#ffffff",
                  },
                }}
              />
              <Tab
                style={{
                  backgroundColor: "#ffffff",
                  color: "#495057",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                  padding: "16px 24px",
                  margin: "0 8px",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                label="Completed"
                {...a11yProps(1)}
                sx={{
                  "&:hover": {
                    backgroundColor: "#e9ecef",
                    color: "#007bff",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#007bff",
                    color: "#ffffff",
                  },
                }}
              />
            </Tabs>
          </AppBar>
        </Grid>
        {
          value === 0 ? <Grid item xs={12} sm={12} md={12} style={{ display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
                {lockedCourses &&
          lockedCourses?.map((course: any, index: number) => (
            <div
              style={{
                position: "relative",
                display: "inline-block",
                margin: "15px",
              }}
            >
              
               { index > 0 && <img
                  src={LockImage}
                  style={{
                    position: "absolute",
                    top: "30%",
                    left: "35%",
                    zIndex: 10,
                    borderRadius: "50%",
                    backgroundColor: "#fff",
                    padding: "5px",
                  }}
                  width={100}
                  height={100}
                  alt=""
                /> }
             
              <Card
                variant="outlined"
                sx={{
                  width: 320,
                  filter: index > 0 ? "blur(1.5px)" : "",
                }}
              >
                <CardOverflow>
                  <AspectRatio ratio="2">
                    <img
                      src={course.thumbnail || "default-thumbnail.png"}
                      loading="lazy"
                      width="200"
                      height="100"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt={course.name || "Course Thumbnail"}
                    />
                    <Chip
                      label={
                        course?.completion_status === "Pending"
                          ? "Pending"
                          : course?.completion_status === "InProgress" ? "In-progress" : "Completed"
                      }
                      size="small"
                      variant="outlined"
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: course.completion_status === "Pending" ? "#ff9a00" : course.completion_status === "InProgress" ? "rgb(64 145 64)" : "#2196F3",
                        color: "#fff",
                        zIndex: 5,
                      }}
                    />
                  </AspectRatio>
                </CardOverflow>
                <CardContent>
                  <Typography level="title-md">
                    {`Course : ${course.name || "Untitled Course"}`}
                  </Typography>
                  <Typography level="title-md">
                    {`Batch : ${
                      course.batch
                        .map((course_data: any) => course_data.name)
                        .join(", ") || "Untitled Course"
                    }`}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Chip
                      label={`Course Id: ${course?.id}`}
                      color="primary"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />

                    <Chip
                      label={`No. of Modules: ${
                        Array.isArray(course?.modules)
                          ? course?.modules?.filter(
                              (moduleStatus: any) =>
                                moduleStatus.status === "published"
                            )?.length
                          : 0
                      }`}
                      color="success"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Chip
                      label={`Expire in (Days): ${
                        course.start_date && course.end_date
                          ? calculateDaysBetweenDates(
                              course.start_date,
                              course.end_date
                            )
                          : "N/A"
                      }`}
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                    <Chip
                      label={`No of Questions: ${course?.no_of_question}`}
                      color="primary"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                  </div>
                </CardContent>
                <Button
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Explore Bahamas Islands"
                  sx={{
                    ml: "auto",
                    alignSelf: "center",
                    fontWeight: 600,
                    width: "100%",
                  }}
                  onClick={() =>
                    history.push(
                      `/dashboard/employee/employee-courses/${course.id}`
                    )
                  }
                  disabled={index > 0}
                >
                  { course?.completion_status === "Pending" ? "Start Course"  : course?.completion_status === "InProgress" ? "Resume" : "Completed"}
                </Button>
                <CardOverflow
                  variant="soft"
                  sx={{ bgcolor: "background.level1" }}
                >
                  <Divider inset="context" />
                  <CardContent
                    orientation="horizontal"
                    style={{ margin: "auto" }}
                  >
                    <Typography
                      level="body-xs"
                      textColor="text.secondary"
                      sx={{ fontWeight: "md" }}
                    >
                      Assigned: {course.start_date}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography
                      level="body-xs"
                      textColor="text.secondary"
                      sx={{ fontWeight: "md" }}
                    >
                      Expire: {course.end_date}
                    </Typography>
                  </CardContent>
                </CardOverflow>
              </Card>
            </div>
          ))}
          </Grid> : <Grid item xs={12} sm={12} md={12} style={{ display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
          {unLockedCourses &&
          unLockedCourses?.map((course: any, index: number) => (
            <div style={{  margin:"15px" }}> 
              <Card
                variant="outlined"
                sx={{
                  width: 320,
                }}
              >
                <CardOverflow>
                  <AspectRatio ratio="2">
                    <img
                      src={course.thumbnail || "default-thumbnail.png"}
                      loading="lazy"
                      width="200"
                      height="100"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt={course.name || "Course Thumbnail"}
                    />
                    <Chip
                      label={
                        course?.completion_status === "Pending"
                          ? "Pending"
                          : course?.completion_status === "InProgress" ? "In-progress" : "Completed"
                      }
                      size="small"
                      variant="outlined"
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: course.completion_status === "Pending" ? "#ff9a00" : course.completion_status === "InProgress" ? "rgb(64 145 64)" : "#2196F3",
                        color: "#fff",
                        zIndex: 5,
                      }}
                    />
                  </AspectRatio>
                </CardOverflow>
                <CardContent>
                  <Typography level="title-md">
                    {`Course : ${course.name || "Untitled Course"}`}
                  </Typography>
                  <Typography level="title-md">
                    {`Batch : ${
                      course.batch
                        .map((course_data: any) => course_data.name)
                        .join(", ") || "Untitled Course"
                    }`}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Chip
                      label={`Course Id: ${course?.id}`}
                      color="primary"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />

                    <Chip
                      label={`No. of Modules: ${
                        Array.isArray(course?.modules)
                          ? course?.modules?.filter(
                              (moduleStatus: any) =>
                                moduleStatus.status === "published"
                            )?.length
                          : 0
                      }`}
                      color="success"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Chip
                       label={`Expire in (Days): ${
                        course.start_date && course.end_date
                          ? calculateDaysBetweenDates(
                              course.start_date,
                              course.end_date
                            )
                          : "N/A"
                      }`}
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                    <Chip
                      label={`No of Questions: ${course?.no_of_question}`}
                      color="primary"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                  </div>
                </CardContent>
                <div style={{ display:"flex", justifyContent:"space-between", alignItems:"center", gap:"2px"}}>
                <Button
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Explore Bahamas Islands"
                  sx={{
                    ml: "auto",
                    alignSelf: "center",
                    fontWeight: 600,
                    width: "50%",
                  }}
                  onClick={() =>
                    history.push(
                      `/dashboard/employee/employee-courses/${course.id}`,
                      { selectedTab: 1}
                    )
                  }
                >
                  See Modules
                </Button>
                <Button
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Explore Bahamas Islands"
                  sx={{
                    ml: "auto",
                    alignSelf: "center",
                    fontWeight: 600,
                    width: "50%",
                  }}
                  onClick={() => {
                    setOpenCertificate(true)
                    setCourseName(course.name)
                  }}
                  disabled={course?.with_certificate === false}
                >
                Certificate
                </Button>
                </div>
                <CardOverflow
                  variant="soft"
                  sx={{ bgcolor: "background.level1" }}
                >
                  <Divider inset="context" />
                  <CardContent
                    orientation="horizontal"
                    style={{ margin: "auto" }}
                  >
                    <Typography
                      level="body-xs"
                      textColor="text.secondary"
                      sx={{ fontWeight: "md" }}
                    >
                      Assigned: {course.start_date}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography
                      level="body-xs"
                      textColor="text.secondary"
                      sx={{ fontWeight: "md" }}
                    >
                      Expire: {course.end_date}
                    </Typography>
                  </CardContent>
                </CardOverflow>
              </Card>
            </div>
          ))}
          </Grid>
        }
        </Grid>
        {
        openCertificate && <Certificate courseName={courseName} open={openCertificate} setOpen={setOpenCertificate}  />
      }
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  lmsCourses: state.EmployeeReducer.lmsCourses,
  employeeData: state.EmployeeReducer.employeeData,
});

export default connect(mapStateToProps, {
  getLmsCourses,
})(LmsCourses);
